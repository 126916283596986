import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { interval, map, scan, startWith, Subscription, takeWhile } from 'rxjs';

@Component({
	selector: 'app-circular-countdown-timer',
	standalone: true,
	templateUrl: './circular-countdown-timer.component.html',
	styleUrls: ['./circular-countdown-timer.component.scss'],
	imports: [IonicModule, CommonModule],
})
export class CircularCountdownTimerComponent implements OnInit, OnDestroy, OnChanges {
	@Input() totalSeconds: number = 0;
	@Input() remainingTime: number = 0;
	@Input() showTimeOnly: boolean = false;
	@Input() shape: 'pie' | 'stroke' = 'stroke';
	@Input() backgroundColor: string = '#cccccc';
	@Input() foregroundColor: string = '#000000';
	@Input() textColor: string = '#000000';
	@Input() fontSize: number = 16;
	@Input() strokeWidth: number = 10;
	@Input() diameter: number = 200;
	@Input() timeDisplayPosition: 'top' | 'right' | 'bottom' | 'left' | 'center' = 'bottom';
	@Input() minParticleSize: number = 4; // Minimum size of the particle
	@Input() maxParticleSize: number = 10; // Maximum size of the particle
	@Input() particleColor: string = '#fff'; // Default color of the particle
	@Input() particleSpread: number = 10; // Default spread for random particle placement
	@Input() dynamicColorChange: boolean = false; // Default is false
	@Input() particleAnimationType: 'continuously' | 'intermittently' = 'continuously'; // New input property

	@Output() countdownCompleted = new EventEmitter<void>();

	radius: number = 0;
	circumference: number = 0;
	currentAngle: number = 360;
	progressValue: number = 0;
	formattedTime: string = '';
	timerStarted: boolean = false;
	showBackgroundCircle: boolean = true;
	hasShownMinutes: boolean = false;
	hasShownHoursOrMinutes: boolean = false;
	dotPosition: { x: number; y: number } = { x: 0, y: 0 };
	particles: { x: number; y: number; opacity: number; radiusX: number; radiusY: number; size: number }[] = [];

	private timerSubscription: Subscription | undefined;
	private particleGenerationInterval: Subscription | undefined; // New interval for particle generation

	public constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		this.updateDimensions();
		this.resetTimer();
		this.startTimer();
		this.startParticleGeneration(); // Start generating particles continuously
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['diameter']) {
			this.updateDimensions();
		}
		// if (changes['totalSeconds']) {
		// 	this.resetTimer(); // Reset state when totalSeconds changes
		// }
		if (changes['totalSeconds'] || changes['remainingTime']) {
			this.resetTimer();
		}
	}

	ngOnDestroy() {
		this.clearTimer();
		this.clearParticleGeneration(); // Clear particle generation on destroy
	}

	startParticleGeneration() {
		// Create an interval to generate particles based on animation type
		const generationInterval = this.particleAnimationType === 'continuously' ? 100 : 1000; // Continuous or intermittent

		this.particleGenerationInterval = interval(generationInterval).subscribe(() => {
			if (this.timerStarted && this.remainingTime > 0) {
				// Only generate particles if the timer is running and there's remaining time
				this.generateParticles();
				this.cdr.detectChanges();
			}
		});
	}

	clearParticleGeneration() {
		if (this.particleGenerationInterval) {
			this.particleGenerationInterval.unsubscribe();
			this.particleGenerationInterval = undefined;
		}
	}

	generateParticles() {
		const centerX = this.diameter / 2; // Center of the circle
		const centerY = this.diameter / 2; // Center of the circle
		const radius = this.radius; // Radius of the circle

		const angleInRadians = (this.currentAngle - 90) * (Math.PI / 180); // Convert angle to radians
		const dotX = centerX + radius * Math.cos(angleInRadians); // X coordinate of the dot
		const dotY = centerY + radius * Math.sin(angleInRadians); // Y coordinate of the dot

		if (this.shape === 'stroke') {
			for (let i = 0; i < 5; i++) {
				const particleSize = Math.random() * (this.maxParticleSize - this.minParticleSize) + this.minParticleSize;

				const particle = {
					x: dotX + (Math.random() - 0.5) * this.particleSpread,
					y: dotY + (Math.random() - 0.5) * this.particleSpread,
					opacity: 1,
					radiusX: Math.random() * 20 - 10,
					radiusY: Math.random() * 20 - 10,
					size: particleSize,
				};

				this.particles.push(particle);
			}
		}
	}

	// animateParticles() {
	// 	this.particles.forEach((particle, index) => {
	// 		particle.opacity -= 0.02; // Decrease opacity over time, adjust as needed

	// 		// Adjust position and opacity based on distance from center
	// 		const distanceFromCenter = Math.sqrt(Math.pow(particle.x - this.diameter / 2, 2) + Math.pow(particle.y - this.diameter / 2, 2));
	// 		particle.opacity = Math.max(0, particle.opacity - distanceFromCenter / 100); // Gradually fade out based on distance

	// 		if (particle.opacity <= 0) {
	// 			this.particles.splice(index, 1);
	// 		}
	// 	});
	// }

	// animateParticles() {
	// 	this.particles.forEach((particle, index) => {
	// 		// Decrease opacity over time
	// 		particle.opacity -= 0.02; // Adjust this value to make fade-out slower or faster

	// 		// Remove the particle once it becomes fully transparent
	// 		if (particle.opacity <= 0) {
	// 			this.particles.splice(index, 1);
	// 		}
	// 	});
	// }

	animateParticles() {
		this.particles.forEach((particle, index) => {
			// Adjust position and opacity based on distance from center
			const distanceFromCenter = Math.sqrt(Math.pow(particle.x - this.diameter / 2, 2) + Math.pow(particle.y - this.diameter / 2, 2));
			particle.opacity = Math.max(0, particle.opacity - distanceFromCenter / 100); // Gradually fade out based on distance

			// Remove the particle once it becomes fully transparent
			if (particle.opacity <= 0) {
				this.particles.splice(index, 1);
			}
		});
	}

	startTimer() {
		if (this.timerStarted || this.remainingTime <= 0) {
			return; // If the timer is already running or has finished, do nothing
		}

		if (this.remainingTime === this.totalSeconds) {
			this.showBackgroundCircle = false; // Hide the background circle when the countdown starts
		}

		this.clearTimer(); // Clear existing subscription

		this.timerStarted = true; // Set the timer state to started

		this.updateTime(); // Immediately update the time before starting the interval

		this.timerSubscription = interval(1000)
			.pipe(
				startWith(this.remainingTime),
				scan((time) => time - 1),
				takeWhile((time) => time >= 0),
			)
			.subscribe((time) => {
				this.remainingTime = time;
				this.updateTime();
				if (time === 0) {
					this.countdownCompleted.emit();
					this.clearParticles();
				}
			});
	}

	pauseTimer() {
		this.clearTimer(); // Stop the timer when paused
	}

	resumeTimer() {
		this.startTimer(); // Simply call startTimer to resume
	}

	resetTimer() {
		this.updateTime(); // Update time display
		this.timerStarted = false; // Ensure timer is not started
		this.showBackgroundCircle = true; // Show background circle again when resetting
		this.clearTimer(); // Clear interval if running
		this.clearParticles(); // Clear particles when resetting
	}

	clearParticles() {
		this.particles = []; // Clear the particles array
	}

	resetState() {
		// Delay to show the background circle before starting again
		setTimeout(() => {
			// this.remainingTime = this.totalSeconds; // Reset remaining time to total seconds
			this.updateTime(); // Ensure time is updated correctly
			this.timerStarted = false; // Ensure timer is not started
			this.clearTimer(); // Clear interval if running
			this.showBackgroundCircle = true; // Show background circle after countdown finishes
		}, 1000); // 1 second delay
	}

	clearTimer() {
		if (this.timerSubscription) {
			this.timerSubscription.unsubscribe();
			this.timerSubscription = undefined; // Reset the subscription to avoid memory leaks
		}
	}

	updateTime() {
		if (this.remainingTime < 0) {
			this.remainingTime = 0; // Ensure remainingTime doesn't go below 0
		}
		const progress = this.remainingTime / this.totalSeconds;
		this.currentAngle = 360 * progress; // Update pie shape angle
		this.progressValue = this.circumference * (1 - progress); // Update stroke dash offset
		this.formattedTime = this.formatTime(this.remainingTime); // Format remaining time

		// Update the dot position
		this.dotPosition = this.polarToCartesian(this.diameter / 2, this.diameter / 2, this.radius, this.currentAngle);

		// Hide the background circle for 'pie' shape when the timer starts
		if (this.shape === 'pie' && this.remainingTime < this.totalSeconds) {
			this.showBackgroundCircle = false; // Hide background circle when using pie
		} else if (this.remainingTime === this.totalSeconds) {
			this.showBackgroundCircle = true; // Show background circle when reset
		}

		// Change color based on remaining time
		if (this.dynamicColorChange) {
			let color;
			if (progress > 0.5) {
				// Green to Orange (First half: 100% - 50%)
				const factor = (progress - 0.5) * 2; // Scale progress from 0.5 to 1 for the first half
				color = this.interpolateColor('#00FF00', '#FFA500', 1 - factor); // Green (#00FF00) to Orange (#FFA500)
			} else {
				// Orange to Red (Second half: 50% - 0%)
				const factor = progress * 2; // Scale progress from 0 to 0.5 for the second half
				color = this.interpolateColor('#FFA500', '#FF0000', 1 - factor); // Orange (#FFA500) to Red (#FF0000)
			}
			this.foregroundColor = color;
			this.particleColor = color;
		}
	}

	updateDimensions() {
		this.radius = this.diameter / 2 - this.strokeWidth / 2; // Subtract half the stroke width to ensure the stroke stays within the SVG boundaries
		this.circumference = 2 * Math.PI * this.radius;
	}

	formatTime(seconds: number): string {
		const hours = Math.floor(seconds / 3600); // Calculate hours
		const minutes = Math.floor((seconds % 3600) / 60); // Calculate minutes
		const secs = seconds % 60; // Calculate remaining seconds

		// Check if we should show hours and minutes
		if (this.totalSeconds >= 3600) {
			this.hasShownHoursOrMinutes = true;
		} else if (this.totalSeconds >= 60) {
			this.hasShownMinutes = true;
		}

		// If hours exist, show hours, minutes, and seconds
		if (hours > 0 || this.hasShownHoursOrMinutes) {
			return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
		}

		// If minutes exist, show minutes and seconds
		if (this.hasShownMinutes) {
			return `${this.pad(minutes)}:${this.pad(secs)}`;
		}

		// If only seconds, show seconds
		return `${this.pad(secs)}`;
	}

	pad(num: number): string {
		return num < 10 ? `0${num}` : `${num}`;
	}

	describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number): string {
		const start = this.polarToCartesian(x, y, radius, endAngle);
		const end = this.polarToCartesian(x, y, radius, startAngle);
		const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
		return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y} L ${x} ${y} Z`;
	}

	polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number): any {
		const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
		return {
			x: centerX + radius * Math.cos(angleInRadians),
			y: centerY + radius * Math.sin(angleInRadians),
		};
	}

	calculateDotPosition(): { x: number; y: number } {
		const centerX = this.diameter / 2;
		const centerY = this.diameter / 2;
		return this.polarToCartesian(centerX, centerY, this.radius, this.currentAngle);
	}

	onDotMove() {
		const centerX = this.diameter / 2; // Center of the circle
		const centerY = this.diameter / 2; // Center of the circle
		const radius = this.radius; // Radius of the circle

		// Calculate the x and y coordinates of the dot based on the current angle
		const angleInRadians = (this.currentAngle - 90) * (Math.PI / 180); // Convert angle to radians
		const dotX = centerX + radius * Math.cos(angleInRadians); // X coordinate of the dot
		const dotY = centerY + radius * Math.sin(angleInRadians); // Y coordinate of the dot

		// Generate a random particle size between minParticleSize and maxParticleSize
		const particleSize = Math.random() * (this.maxParticleSize - this.minParticleSize) + this.minParticleSize;

		// Create a particle near the current position of the dot with a random offset
		const particle = {
			x: dotX + (Math.random() - 0.5) * this.particleSpread, // Random offset for x based on particleSpread
			y: dotY + (Math.random() - 0.5) * this.particleSpread, // Random offset for y based on particleSpread
			opacity: 1, // Start fully opaque
			radiusX: Math.random() * this.particleSpread - this.particleSpread / 2, // Random x translation within spread
			radiusY: Math.random() * this.particleSpread - this.particleSpread / 2, // Random y translation within spread
			size: particleSize, // Use random size for each particle
		};
		this.particles.push(particle);
	}

	interpolateColor(color1: string, color2: string, factor: number): string {
		const hexToRgb = (hex: string) => {
			const bigint = parseInt(hex.slice(1), 16);
			return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
		};

		const rgbToHex = (r: number, g: number, b: number) => '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();

		const [r1, g1, b1] = hexToRgb(color1);
		const [r2, g2, b2] = hexToRgb(color2);

		const r = Math.round(r1 + (r2 - r1) * factor);
		const g = Math.round(g1 + (g2 - g1) * factor);
		const b = Math.round(b1 + (b2 - b1) * factor);

		return rgbToHex(r, g, b);
	}
}
