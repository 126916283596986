import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IMainResponse } from 'src/app/core/models/api.model';
import { IFriendItem, IFriendRequest, IPendingRequest } from 'src/app/core/models/friend.model';
import { BaseChatApiService } from '../base/base-chat-api.service';
import { BaseProfileApiService } from '../base/base-profile-api.service';
import { FriendModel } from 'src/app/core/models/friend-api.model';

@Injectable({
	providedIn: 'root',
})
export class FriendService {
	private readonly controller: string = 'friend';
	public constructor(private _baseProfileApiService: BaseProfileApiService, private _baseChatApiService: BaseChatApiService) {}

	// Profile Api
	public getFriendList(): Observable<IMainResponse<Array<IFriendItem>>> {
		return this._baseProfileApiService.get<IMainResponse<Array<IFriendItem>>>(`${this.controller}/list`);
	}
	public friendRequests(): Observable<IMainResponse<Array<IFriendRequest>>> {
		return this._baseProfileApiService.get<IMainResponse<Array<IFriendRequest>>>(`${this.controller}/requests`);
	}
	public sendFriendRequest(targetUserId: string): Observable<any> {
		return this._baseProfileApiService.post<any>(`${this.controller}/requests`, { targetUserId: targetUserId });
	}
	public answerFriendRequest(requestId: string, status: string): Observable<any> {
		return this._baseProfileApiService.put<any>(`${this.controller}/requests/${requestId}`, {
			status: status,
		});
	}
	public deleteFriend(targetUserId: string, _options = {}): Observable<any> {
		return this._baseProfileApiService.delete(`${this.controller}/${targetUserId}`);
	}
	public sentRequests(): Observable<IMainResponse<Array<IPendingRequest>>> {
		return this._baseProfileApiService.get<IMainResponse<Array<IPendingRequest>>>(`${this.controller}/my_sent_requests`);
	}
	public cancelSentRequests(requestId: string): Observable<any> {
		return this._baseProfileApiService.delete(`${this.controller}/my_sent_requests/${requestId}`);
	}

	// Chat Api
	public getChatFriendList(): Observable<any> {
		return this._baseChatApiService.get<any>(`${this.controller}`);
	}
	public playerIsBlocked(userId: string): Observable<any> {
		return this._baseChatApiService.get<any>(`${this.controller}/${userId}/is_blocked`);
	}
	public playerBlockedMe(userId: string): Observable<any> {
		return this._baseChatApiService.get<any>(`${this.controller}/${userId}/blocked_me`);
	}
	public playerIsFriend(userId: string): Observable<any> {
		return this._baseChatApiService.get<any>(`${this.controller}/${userId}/is_friend`);
	}
}

export interface ISendMessageBody {
	targetUserId: string;
	message: string;
}
