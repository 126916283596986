import { AppComponent } from './app.component';
import { provideLottieOptions } from 'ngx-lottie';
import { NgModule, isDevMode } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { PlatformModule } from '@angular/cdk/platform';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ApiInterceptor } from './core/interceptors/api.interceptor';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { customPageTransitionAnimation } from './core/animations/custom.animation';
import { AbstractSecurityStorage, AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { BattleInvitationComponent } from './modules/shared/battle-invitation/battle-invitation.component';
import { CustomOidcStorageService } from './core/services/app/custom-oidc-storage/custom-oidc-storage.service';
import { FullScreenLoadingComponent } from './modules/shared/full-screen-loading/full-screen-loading.component';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		PlatformModule,
		IonicModule.forRoot({
			animated: true,
			navAnimation: customPageTransitionAnimation,
			// scrollEvents: true,
			scrollAssist: true,
			innerHTMLTemplatesEnabled: true,
		}),
		AppRoutingModule,
		AuthModule.forRoot({
			config: {
				authority: environment.OIDC_ISSUER, // Your OIDC provider URL
				redirectUrl: environment.OIDC_REDIRECT_URI,
				clientId: environment.OIDC_CLIENT_ID, // Your client ID from OIDC provider
				postLogoutRedirectUri: environment.POST_LOGOUT_REDIRECT_URL,
				scope: 'openid profile email offline_access', // Include 'offline_access' for refresh token
				responseType: 'code',
				logLevel: LogLevel.Debug,
				silentRenew: false,
				useRefreshToken: true,
				renewTimeBeforeTokenExpiresInSeconds: 60, // Time to renew token before it expires (adjust based on needs)
				ignoreNonceAfterRefresh: true, // this is required if the id_token is not returned
				// allowUnsafeReuseRefreshToken: true, // this is required if the refresh token is not rotated
				triggerRefreshWhenIdTokenExpired: false, // required when refreshing the browser if id_token is not updated after the first authentication
				postLoginRoute: '/home',
			},
		}),
		IonicStorageModule.forRoot({
			name: '__appdb',
		}),
		BrowserAnimationsModule,
		FullScreenLoadingComponent,
		BattleInvitationComponent,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
	providers: [
		{ provide: AbstractSecurityStorage, useClass: CustomOidcStorageService },
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideMessaging(() => getMessaging()),
		provideLottieOptions({
			player: () => import('lottie-web'),
		}),
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
