export function splitFloatNumber(number: number): number {
	let fixedNumber = number.toFixed(10);
	let parts = fixedNumber.split('.');
	// let integerPart = parseInt(parts[0]);
	let decimalPart = parseFloat('0.' + parts[1]);
	return decimalPart;
}

/**
 * millisecondsToSeconds
 */
export function millisecondsToSeconds(milliseconds: number): number {
	return milliseconds / 1000;
}

/**
 * objectToQueryParam
 */
export function objectToQueryParam(obj: any): string {
	let resualt: string = '?';

	if (obj) {
		Object.keys(obj).forEach((property) => {
			if (obj[property] !== '' && obj[property] !== undefined && obj[property] !== null) {
				resualt += property + '=' + obj[property] + '&';
			}
		});
		return resualt.slice(0, -1);
	}

	return '';
}

export function logError(err: unknown, context: string): void {
	if (err instanceof Error) {
		console.error(`${context}:`, err.message);
	} else {
		console.error(`${context} with unknown error:`, err);
	}
}

// Helper method to get token expiration date
// private getTokenExpirationDate(token: string): Date | null {
// 	const decoded = this.decodeToken(token);
// 	if (decoded && decoded.exp) {
// 		const expirationDate = new Date(0);
// 		expirationDate.setUTCSeconds(decoded.exp);
// 		return expirationDate;
// 	}
// 	return null;
// }

// Helper method to decode JWT
// private decodeToken(token: string): any {
// 	try {
// 		return JSON.parse(atob(token.split('.')[1]));
// 	} catch (error) {
// 		return null;
// 	}
// }
