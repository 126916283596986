import * as SignalR from '@microsoft/signalr';
import { EventEmitter, Injectable } from '@angular/core';
import { BattleStartedBattleDto, LobbiesStartSearchingDto } from 'src/app/core/models/engine-signal.model';
import { TournamentSignalDto, TournamentModel, TournamentSubscriptionsUpdatedModel } from 'src/app/core/models/tournament.model';
import { AuthService } from '../../../api/auth/auth.service';
import { LayoutService } from '../../../app/layout-service/layout.service';
import { environment } from 'src/environments/environment';
import { EngineSignalService } from '../engine-signal.service';
import { GameService } from '../../../api/game-service/game.service';
import { CreatedHubConnectionModel } from 'src/app/core/models/engine-generic-api.model';

@Injectable({
	providedIn: 'root',
})
export class TournamentSignalService {
	// Task TournamentSubscriptionWasStarted(TournamentDto tournament);
	// Task TournamentSubscriptionsUpdated(Guid id, int subscriptions);
	// Task TournamentIsStartingAlert(Guid tournamentId);
	// Task TournamentWasStarted(TournamentDto tournament);
	// Task TournamentWasCanceled(TournamentDto tournament);
	// Task TournamentBattleWasStarted(TournamentDto tournament, BattleDto battle);
	// Task TournamentBattleWasEnded(TournamentDto tournament, BattleDto battle);
	// Task TournamentWasEnded(TournamentDto tournament);
	// Task YouWonTournament(TournamentDto tournament);
	// Task YouLostTournament(TournamentDto tournament);

	private hubConnection: SignalR.HubConnection | null = null;

	public tournamentSubscriptionWasStarted: EventEmitter<TournamentModel> = new EventEmitter<TournamentModel>();
	public tournamentSubscriptionsUpdated: EventEmitter<TournamentSubscriptionsUpdatedModel> = new EventEmitter<TournamentSubscriptionsUpdatedModel>();
	public tournamentIsStartingAlert: EventEmitter<string> = new EventEmitter<string>();
	public tournamentWasStarted: EventEmitter<TournamentModel> = new EventEmitter<TournamentModel>();
	public tournamentWasCanceled: EventEmitter<TournamentModel> = new EventEmitter<TournamentModel>();
	public tournamentBattleWasStarted: EventEmitter<TournamentSignalDto> = new EventEmitter<TournamentSignalDto>();
	public tournamentBattleWasEnded: EventEmitter<TournamentSignalDto> = new EventEmitter<TournamentSignalDto>();
	public tournamentWasEnded: EventEmitter<TournamentModel> = new EventEmitter<TournamentModel>();
	public youWonTournament: EventEmitter<TournamentModel> = new EventEmitter<TournamentModel>();
	public youLostTournament: EventEmitter<TournamentModel> = new EventEmitter<TournamentModel>();

	public constructor(private _engineSignalService: EngineSignalService) {
		this._engineSignalService.connectionEstablished.subscribe((createdHubConnection: CreatedHubConnectionModel) => {
			if (createdHubConnection.isReady) {
				this.hubConnection = createdHubConnection.connection;
				console.log('🔵 Connection established in TournamentSignalService.');
				this.setUpListeners();
			}
		});
	}

	private setUpListeners(): void {
		console.log('🔧 Setting up listeners for Tournament Signal Service.');
		this.TournamentSubscriptionWasStarted();
		this.TournamentSubscriptionsUpdated();
		this.TournamentIsStartingAlert();
		this.TournamentWasStarted();
		this.TournamentWasCanceled();
		this.TournamentBattleWasStarted();
		this.TournamentBattleWasEnded();
		this.TournamentWasEnded();
		this.YouWonTournament();
		this.YouLostTournament();
	}

	private TournamentSubscriptionWasStarted(): void {
		this.hubConnection?.on('TournamentSubscriptionWasStarted', (tournament: TournamentModel) => {
			this.tournamentSubscriptionWasStarted.emit(tournament);
			console.log('⚡ TournamentSubscriptionWasStarted!', tournament);
		});
	}

	private TournamentSubscriptionsUpdated(): void {
		this.hubConnection?.on('TournamentSubscriptionsUpdated', (id: string, subscriptions: number) => {
			this.tournamentSubscriptionsUpdated.emit({ id, subscriptions });
			console.log('⚡ TournamentSubscriptionsUpdated!', id, subscriptions);
		});
	}

	private TournamentIsStartingAlert(): void {
		this.hubConnection?.on('TournamentIsStartingAlert', (tournamentId: string) => {
			this.tournamentIsStartingAlert.emit(tournamentId);
			console.log('⚡ TournamentIsStartingAlert!', tournamentId);
		});
	}

	private TournamentWasStarted(): void {
		this.hubConnection?.on('TournamentWasStarted', (tournament: TournamentModel) => {
			this.tournamentWasStarted.emit(tournament);
			console.log('⚡ TournamentWasStarted!', tournament);
		});
	}

	private TournamentWasCanceled(): void {
		this.hubConnection?.on('TournamentWasCanceled', (tournament: TournamentModel) => {
			this.tournamentWasCanceled.emit(tournament);
			console.log('⚡ TournamentWasCanceled!', tournament);
		});
	}

	private TournamentBattleWasStarted(): void {
		this.hubConnection?.on('TournamentBattleWasStarted', (tournament: TournamentModel, battle: BattleStartedBattleDto) => {
			this.tournamentBattleWasStarted.emit({ tournament, battle });
			console.log('⚡ TournamentBattleWasStarted!', tournament, battle);
		});
	}

	private TournamentBattleWasEnded(): void {
		this.hubConnection?.on('TournamentBattleWasEnded', (tournament: TournamentModel, battle: BattleStartedBattleDto) => {
			this.tournamentBattleWasEnded.emit({ tournament, battle });
			console.log('⚡ TournamentBattleWasEnded!', tournament, battle);
		});
	}

	private TournamentWasEnded(): void {
		this.hubConnection?.on('TournamentWasEnded', (tournament: TournamentModel) => {
			this.tournamentWasEnded.emit(tournament);
			console.log('⚡ TournamentWasEnded!', tournament);
		});
	}

	private YouWonTournament(): void {
		this.hubConnection?.on('YouWonTournament', (tournament: TournamentModel) => {
			this.youWonTournament.emit(tournament);
			console.log('⚡ YouWonTournament!', tournament);
		});
	}

	private YouLostTournament(): void {
		this.hubConnection?.on('YouLostTournament', (tournament: TournamentModel) => {
			this.youLostTournament.emit(tournament);
			console.log('⚡ YouLostTournament!', tournament);
		});
	}
}
