import * as SignalR from '@microsoft/signalr';
import { EventEmitter, Injectable } from '@angular/core';
import { EngineSignalService } from '../engine-signal.service';
import { AuthService } from '../../../api/auth/auth.service';
import { GameService } from '../../../api/game-service/game.service';
import { LayoutService } from '../../../app/layout-service/layout.service';
import { SelectedChoiceEnum } from 'src/app/core/enums/game.enum';
import {
	LobbiesStartSearchingDto,
	BattleInvitationReceivedResponseDto,
	FriendBattleInvitationWasAcceptedDto,
	LobbyWasCreatedDto,
	BattleStartedResponseDto,
	BattlePredictionChoiceWasChangedModel,
	BattleStartedBattleDto,
	BattleParticipantUserModel,
	BattleInvitationReceivedDto,
} from 'src/app/core/models/engine-signal.model';
import { CreatedHubConnectionModel } from 'src/app/core/models/engine-generic-api.model';

@Injectable({
	providedIn: 'root',
})
export class DuelSignalService {
	// Task FindingOpponentsWasStarted(FindingLobbyRequestDto findingRequest);
	// Task FindingOpponentsWasCanceled(Guid cancelerUserId, FindingLobbyRequestDto findingRequest);
	// Task FindingOpponentsWasExpired(FindingLobbyRequestDto findingRequest);
	// Task BattleInvitationSent(InvitationBattleRequestDto invitationBattleRequest, UserInfoDto sender);
	// Task BattleInvitationReceived(InvitationBattleRequestDto invitationBattleRequest, UserInfoDto sender);
	// Task FriendBattleInvitationWasExpired(InvitationBattleRequestDto invitationBattleRequest);
	// Task FriendBattleInvitationWasDenied(InvitationBattleRequestDto invitationBattleRequest, FriendInvitationDenyReasonsEnum reason);
	// Task FriendBattleInvitationWasAccepted(InvitationBattleRequestDto invitationBattleRequest);
	// Task LobbyWasCreated(LobbyDto lobby);
	// Task PlayerIsReadyInLobby(Guid userId, LobbyDto lobby);
	// Task LobbyWasExpired(LobbyDto lobby);
	// Task LobbyWasCanceled(Guid CancelerUserId, LobbyDto lobby);
	// Task CreatingAndPreparingBattle(LobbyDto lobby);
	// Task PreparingBattleWasCanceled(LobbyDto lobby, string reason);
	// Task BattleStarted(LobbyDto lobby, BattleDto battle);
	// Task BattlePredictionChoiceWasChanged(Guid userId, Guid battleId, BattleParticipantSelectedChoiceEnum selectedChoice);
	// Task BattleRenewed(BattleDto battle);
	// Task BattleEnded(BattleDto battle);
	// Task BattleCanceled(BattleDto battle, Guid cancelerUserId);

	private hubConnection: SignalR.HubConnection | null = null;

	public findingOpponentsWasStarted: EventEmitter<LobbiesStartSearchingDto> = new EventEmitter<LobbiesStartSearchingDto>();
	public findingOpponentsWasCanceled: EventEmitter<{ cancelerUserId: string; lobby: LobbiesStartSearchingDto }> = new EventEmitter<{
		cancelerUserId: string;
		lobby: LobbiesStartSearchingDto;
	}>();
	public findingOpponentsWasExpired: EventEmitter<LobbiesStartSearchingDto> = new EventEmitter<LobbiesStartSearchingDto>();
	public battleInvitationSent: EventEmitter<BattleInvitationReceivedResponseDto> = new EventEmitter<BattleInvitationReceivedResponseDto>();
	public battleInvitationReceived: EventEmitter<BattleInvitationReceivedResponseDto> = new EventEmitter<BattleInvitationReceivedResponseDto>();
	public friendBattleInvitationWasExpired: EventEmitter<string> = new EventEmitter<string>();
	public friendBattleInvitationWasDenied: EventEmitter<string> = new EventEmitter<string>();
	public friendBattleInvitationWasAccepted: EventEmitter<FriendBattleInvitationWasAcceptedDto> = new EventEmitter<FriendBattleInvitationWasAcceptedDto>();
	public lobbyWasCreated: EventEmitter<LobbyWasCreatedDto> = new EventEmitter<LobbyWasCreatedDto>();
	public playerIsReadyInLobby: EventEmitter<string> = new EventEmitter<string>();
	public lobbyWasExpired: EventEmitter<LobbyWasCreatedDto> = new EventEmitter<LobbyWasCreatedDto>();
	public lobbyWasCanceled: EventEmitter<{ cancelerUserId: string; lobby: LobbyWasCreatedDto }> = new EventEmitter<{
		cancelerUserId: string;
		lobby: LobbyWasCreatedDto;
	}>();
	public creatingAndPreparingBattle: EventEmitter<string> = new EventEmitter<string>();
	public preparingBattleWasCanceled: EventEmitter<string> = new EventEmitter<string>();
	public battleStarted: EventEmitter<BattleStartedResponseDto> = new EventEmitter<BattleStartedResponseDto>();
	public battlePredictionChoiceWasChanged: EventEmitter<BattlePredictionChoiceWasChangedModel> =
		new EventEmitter<BattlePredictionChoiceWasChangedModel>();
	public battleRenewed: EventEmitter<string> = new EventEmitter<string>();
	public battleEnded: EventEmitter<BattleStartedBattleDto> = new EventEmitter<BattleStartedBattleDto>();
	public battleCanceled: EventEmitter<string> = new EventEmitter<string>();

	public constructor(
		private _authService: AuthService,
		private _gameService: GameService,
		private _layoutService: LayoutService,
		private _engineSignalService: EngineSignalService,
	) {
		this._engineSignalService.connectionEstablished.subscribe((createdHubConnection: CreatedHubConnectionModel) => {
			if (createdHubConnection.isReady) {
				this.hubConnection = createdHubConnection.connection;
				console.log('🔵 Connection established in DuelSignalService.');
				this.setUpListeners();
			}
		});
	}

	private setUpListeners(): void {
		console.log('🔧 Setting up listeners for Duel Signal Service.');
		this.FindingOpponentsWasStarted();
		this.FindingOpponentsWasCanceled();
		this.FindingOpponentsWasExpired();
		this.BattleInvitationSent();
		this.BattleInvitationReceived();
		this.FriendBattleInvitationWasExpired();
		this.FriendBattleInvitationWasDenied();
		this.FriendBattleInvitationWasAccepted();
		this.LobbyWasCreated();
		this.PlayerIsReadyInLobby();
		this.LobbyWasExpired();
		this.LobbyWasCanceled();
		this.CreatingAndPreparingBattle();
		this.PreparingBattleWasCanceled();
		this.BattleStarted();
		this.BattlePredictionChoiceWasChanged();
		this.BattleRenewed();
		this.BattleEnded();
		this.BattleCanceled();
	}

	private FindingOpponentsWasStarted(): void {
		this.hubConnection?.on('FindingOpponentsWasStarted', (lobby: LobbiesStartSearchingDto) => {
			this.findingOpponentsWasStarted.emit(lobby);
			console.log('⚡ FindingOpponentsWasStarted!', lobby);
		});
	}

	private FindingOpponentsWasCanceled(): void {
		this.hubConnection?.on('FindingOpponentsWasCanceled', (cancelerUserId: string, lobby: LobbiesStartSearchingDto) => {
			this.findingOpponentsWasCanceled.emit({ cancelerUserId, lobby });
			console.log('⚡ FindingOpponentsWasCanceled!', cancelerUserId, lobby);
		});
	}

	private FindingOpponentsWasExpired(): void {
		this.hubConnection?.on('FindingOpponentsWasExpired', (lobby: LobbiesStartSearchingDto) => {
			this.findingOpponentsWasExpired.emit(lobby);
			console.log('⚡ FindingOpponentsWasExpired!', lobby);
		});
	}

	private BattleInvitationSent(): void {
		this.hubConnection?.on('BattleInvitationSent', (invitation: any, sender: BattleParticipantUserModel) => {
			this.battleInvitationSent.emit({ invitation, sender });
			console.log('⚡ BattleInvitationSent!', invitation, sender);
		});
	}

	private BattleInvitationReceived(): void {
		// TODO: 👇 type...
		this.hubConnection?.on('BattleInvitationReceived', (invitation: any, sender: BattleParticipantUserModel) => {
			this.battleInvitationReceived.emit({ invitation, sender });
			console.log('⚡ BattleInvitationReceived!', invitation, sender);
		});
	}

	private FriendBattleInvitationWasExpired(): void {
		this.hubConnection?.on('FriendBattleInvitationWasExpired', (lobby: any) => {
			this.friendBattleInvitationWasExpired.emit(lobby);
			console.log('⚡ FriendBattleInvitationWasExpired!', lobby);
		});
	}

	private FriendBattleInvitationWasDenied(): void {
		this.hubConnection?.on('FriendBattleInvitationWasDenied', (lobby: any) => {
			this.friendBattleInvitationWasDenied.emit(lobby);
			console.log('⚡ FriendBattleInvitationWasDenied!', lobby);
		});
	}

	private FriendBattleInvitationWasAccepted(): void {
		this.hubConnection?.on('FriendBattleInvitationWasAccepted', (invitation: BattleInvitationReceivedDto, lobby: LobbyWasCreatedDto) => {
			this.friendBattleInvitationWasAccepted.emit({ invitation, lobby });
			console.log('⚡ FriendBattleInvitationWasAccepted!', invitation, lobby);
		});
	}

	private LobbyWasCreated(): void {
		this.hubConnection?.on('LobbyWasCreated', (lobby: LobbyWasCreatedDto) => {
			this.lobbyWasCreated.emit(lobby);
			console.log('⚡ LobbyWasCreated!', lobby);
		});
	}

	private PlayerIsReadyInLobby(): void {
		this.hubConnection?.on('PlayerIsReadyInLobby', (lobby: any) => {
			this.playerIsReadyInLobby.emit(lobby);
			console.log('⚡ PlayerIsReadyInLobby!', lobby);
		});
	}

	private LobbyWasExpired(): void {
		this.hubConnection?.on('LobbyWasExpired', (lobby: LobbyWasCreatedDto) => {
			this.lobbyWasExpired.emit(lobby);
			console.log('⚡ LobbyWasExpired!', lobby);
		});
	}

	private LobbyWasCanceled(): void {
		this.hubConnection?.on('LobbyWasCanceled', (cancelerUserId: string, lobby: LobbyWasCreatedDto) => {
			this.lobbyWasCanceled.emit({ cancelerUserId, lobby });
			console.log('⚡ LobbyWasCanceled!', cancelerUserId, lobby);
		});
	}

	private CreatingAndPreparingBattle(): void {
		this.hubConnection?.on('CreatingAndPreparingBattle', (lobby: any) => {
			this.creatingAndPreparingBattle.emit(lobby);
			console.log('⚡ CreatingAndPreparingBattle!', lobby);
		});
	}

	private PreparingBattleWasCanceled(): void {
		this.hubConnection?.on('PreparingBattleWasCanceled', (lobby: any) => {
			this.preparingBattleWasCanceled.emit(lobby);
			console.log('⚡ PreparingBattleWasCanceled!', lobby);
		});
	}

	private BattleStarted(): void {
		this.hubConnection?.on('BattleStarted', (lobby: LobbyWasCreatedDto, battle: BattleStartedBattleDto) => {
			this.battleStarted.emit({ lobby, battle });
			console.log('⚡ BattleStarted!', lobby, battle);
		});
	}

	private BattlePredictionChoiceWasChanged(): void {
		this.hubConnection?.on('BattlePredictionChoiceWasChanged', (userId: string, battleId: string, selectedChoice: SelectedChoiceEnum) => {
			this.battlePredictionChoiceWasChanged.emit({ userId, battleId, selectedChoice });
			console.log('⚡ BattlePredictionChoiceWasChanged!', userId, battleId, selectedChoice);
		});
	}

	private BattleRenewed(): void {
		this.hubConnection?.on('BattleRenewed', (lobby: any) => {
			this.battleRenewed.emit(lobby);
			console.log('⚡ BattleRenewed!', lobby);
		});
	}

	private BattleEnded(): void {
		this.hubConnection?.on('BattleEnded', (battle: BattleStartedBattleDto) => {
			this.battleEnded.emit(battle);
			console.log('⚡ BattleEnded!', battle);
		});
	}

	private BattleCanceled(): void {
		this.hubConnection?.on('BattleCanceled', (lobby: any) => {
			this.battleCanceled.emit(lobby);
			console.log('⚡ BattleCanceled!', lobby);
		});
	}
}
