import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FriendService } from '../../api/friend-service/friend.service';
import { NotificationApiFilter } from 'src/app/core/enums/notification.enum';
import { NotificationService } from '../../api/notification-service/notification.service';
import { ProfileSignalService } from '../../signal/profile-signal-service/profile-signal.service';

@Injectable({
	providedIn: 'root',
})
export class AppNotificationService {
	public unreadNotificationsCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public getNotifications: Subject<boolean> = new Subject<boolean>();

	public friendRequestsCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public getFriendRequestsCount: Subject<boolean> = new Subject<boolean>();

	public constructor(
		private _friendService: FriendService,
		private _notificationService: NotificationService,
		private _profileSignalService: ProfileSignalService,
	) {
		this.getNotifications.subscribe((value) => {
			this.getUnreadNotifications();
		});
		this.getFriendRequestsCount.subscribe((value) => {
			this.getFriendRequests();
		});
		this._profileSignalService.friendRequestAdded.subscribe((userId) => {
			this.getFriendRequests();
		});
		this._profileSignalService.newNotificationReceived.subscribe((userId) => {
			this.getUnreadNotifications();
		});
	}

	public init(): void {
		this.getUnreadNotifications();
		this.getFriendRequests();

		console.log('✅ AppNotificationService Initialized.');
	}

	private getUnreadNotifications(): void {
		this._notificationService.getAllNotifications(1, '', NotificationApiFilter.All).subscribe({
			next: (response) => {
				this.unreadNotificationsCount.next(response.value.unseenCount);
			},
			error: (error) => {},
		});
	}

	private getFriendRequests(): void {
		this._friendService.friendRequests().subscribe({
			next: (response) => {
				this.friendRequestsCount.next(response.value.length);
			},
			error: (error) => {},
		});
	}
}
