export interface IBlockedUser {
	id: string;
	userName: string;
	email: string;
	avatarUrl: string;
	isOnline: boolean;
	profile: IBlockedUserProfile;
}
export interface IRegisterFCM {
	agent: string;
	token: string;
}

export interface IBlockedUserProfile {
	userId: string;
	user: IBlockedUserProfileUser;
	friendsCount: number;
	winStreakCount: number;
	winrate: number;
	userLevel: number;
	gameXP: number;
	rankTitleId: string;
	rankTitle: IBlockedUserProfileRankTitle;
	depositLevelId: string;
	depositLevel: IBlockedUserProfileDepositLevel;
	usdtBalance: number;
	coinBalance: number;
	id: string;
}

export interface IBlockedUserProfileUser {
	id: string;
	userName: string;
	email: string;
	avatarUrl: string;
	createdAt: string;
	updatedAt: string;
	profileId: string;
	profile: any;
}

export interface IBlockedUserProfileRankTitle {
	keyword: string;
	title: string;
	imageUrl: string;
	order: number;
	id: string;
}

export interface IBlockedUserProfileDepositLevel {
	keyword: string;
	title: string;
	imageUrl: string;
	order: number;
	id: string;
}

export interface IUpdateUserData {
	userName: string;
	profilePicId: string;
	gender: string;
	birthDate: string;
}

export interface IUserIdentityData {
	id: string;
	userName: string;
	email: string;
	gender: string;
	birthDate: string;
	createdAt: string;
	lastUpdatedAt: string;
	profilePicId: string;
	userPlan: {
		subscriptionPlanId: string;
		subscriptionPlanKey: string;
		startDate: string;
		expiryDate: string;
		id: string;
	};
	referralUserId: string;
	referralUser: string;
	avatar: {
		id: string;
		imageUri: string;
		thumbImageUri: string;
	};
}

export class UserInfoModel {
	userName: string = String('');
	avatarUrl: string = String('');
	id: string = String('');
}
