import { addIcons } from 'ionicons';
import { jwtDecode } from 'jwt-decode';
import { eye, leaf } from 'ionicons/icons';
import { initializeApp } from 'firebase/app';
import { register } from 'swiper/element/bundle';
import { IProfile } from './core/models/profile-api.model';
import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AppConfig } from './core/constants/app-config.constants';
import { AuthService } from './core/services/api/auth/auth.service';
import { ITokenObject } from './core/models/auth/token-object.model';
import { getMessaging, getToken, deleteToken } from 'firebase/messaging';
import { MenuLabelHandler } from './core/enums/app-sidemenu-handler.enum';
import { UserService } from './core/services/api/user-service/user.service';
import { ThemeService } from './core/services/app/theme-service/theme.service';
import { ToastService } from './core/services/app/toast-service/toast.service';
import { DisableReceiveInvitationModel } from './core/models/invitation.model';
import { DisableReceiveInvitationTimeEnum } from './core/enums/invitation.enum';
import { ISidemenuLabel, ISidemenuPage } from './core/models/app-sidemenu.model';
import { LayoutService } from './core/services/app/layout-service/layout.service';
import { StorageService } from './core/services/app/storage-service/storage.service';
import { FullScreenLoadingModel } from './core/models/app-full-screen-loading.model';
import { AlertController, IonModal, MenuController, Platform } from '@ionic/angular';

import { AppEngineService } from './core/services/app/app-engine-service/app-engine.service';
import { AppProfileService } from './core/services/app/app-profile-service/app-profile.service';
import { AppFriendsService } from './core/services/app/app-friends-service/app-friends.service';

import { AppNotificationService } from './core/services/app/app-notification-service/app-notification.service';
import { DuelSignalService } from './core/services/signal/engine-signal-service/duel-signal-service/duel-signal.service';
import { TournamentSignalService } from './core/services/signal/engine-signal-service/tournament-signal-service/tournament-signal.service';
import { TimeSyncService } from './core/services/app/timesync-service/timesync.service';
import { logError } from './core/utils/functions.utils';
import { EngineSignalService } from './core/services/signal/engine-signal-service/engine-signal.service';
import { ProfileSignalService } from './core/services/signal/profile-signal-service/profile-signal.service';
import { ChatSignalService } from './core/services/signal/chat-service/chat.service';
import { DataSyncService } from './core/services/api/data-sync-service/data-sync.service';
import { FullScreenLoadingTypeEnum } from './core/enums/app-full-screen-loading.enum';

register();
// const timesync = require('timesync');

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
	@ViewChild(IonModal) invitationModal!: IonModal;
	public loading: FullScreenLoadingModel = new FullScreenLoadingModel();
	public signalLoading: FullScreenLoadingModel = new FullScreenLoadingModel();

	public currentFCMToken: string = '';
	public appHasError: boolean = false;
	public errorMessage: string | null = null;

	public friendRequestsCount: number = 0;
	public isInvitationModalOpen: boolean = false;
	public user: IProfile = new IProfile();

	public constructor(
		// private _platform: Platform,
		private _platform: Platform,
		private _storage: StorageService,
		private _userService: UserService,
		private _authService: AuthService,
		private _themeService: ThemeService,
		private _toastService: ToastService,
		private _layoutService: LayoutService,
		private _appProfileService: AppProfileService, // DO_NOT_DELETE:
		private _oidcSecurityService: OidcSecurityService,
		private _appNotificationService: AppNotificationService,
		private _dataSyncService: DataSyncService, // DO_NOT_DELETE:
	) {
		this.addIcons();
		this._themeService.loadTheme();

		// #region Check Auth
		this._oidcSecurityService.checkAuth().subscribe({
			next: (result) => {
				if (!result.accessToken) return;

				let TokenObject: ITokenObject = jwtDecode(result.accessToken);
				this._authService.setAccessToken(result.accessToken);
				this._authService.setUserRole(TokenObject.role);
				this._authService.getUserData();

				this.checkNotificationPermission();
			},
			error: (error) => {},
		});

		// Optionally, subscribe to access token expiration (manually calculated)
		// this._oidcSecurityService.getAccessToken().subscribe((accessToken) => {
		// 	if (accessToken) {
		// 		this._authService.userData$.subscribe((userData) => {
		// 			if (userData.sub !== '') {
		// 				this._layoutService.loading.next({
		// 					isShow: false,
		// 					title: '',
		// 					type: FullScreenLoadingTypeEnum.Default,
		// 					isShowTryAgainButton: false,
		// 				});

		// 			}
		// 		});
		// 	}
		// });
		// #endregion

		this._appProfileService.profile.subscribe((value) => {
			this.user = value;
		});
		this._appNotificationService.friendRequestsCount.subscribe((value) => {
			this.friendRequestsCount = value;
		});
		this._layoutService.loading.subscribe((value) => {
			this.loading = value;
		});
		this._layoutService.signalLoading.subscribe((value) => {
			this.signalLoading = value;
		});
		this._layoutService.errorMessage$.subscribe((message) => {
			this.errorMessage = message;
		});
	}

	public ngOnInit(): void {
		this.handleDuelTermsAndConditionsAcceptance();
		this.handleDisableReceiveInvitation();
	}

	private checkNotificationPermission(): void {
		if (Notification.permission === 'default' || Notification.permission === 'denied') {
			this.showToast();
		} else {
			this.initializeFireBase();
		}
	}

	private showToast(): void {
		this._toastService.info('Allow Notification Permisson to have better experience', '', 0, {
			buttonText: 'Allow',
			handler: () => {
				Notification.requestPermission()
					.then((permission) => {
						if (permission === 'granted') {
							this.initializeFireBase();
						}
					})
					.catch((err) => this._toastService.error(err, 'information-outline'));
			},
		});
	}

	private async initializeFireBase(): Promise<void> {
		const app = initializeApp(environment.firebaseConfig);
		const messaging = getMessaging(app);
		let agents = '';
		this._platform.platforms().forEach((agent, index) => {
			agents = agents + (index == 0 ? '' : '-') + agent;
		});
		getToken(messaging, { vapidKey: environment.vapidKey })
			.then(async (currentToken) => {
				if (currentToken) {
					this.getStorageItem('notification-token').then((value) => {
						const notificationToken = value;
						if (notificationToken && notificationToken === currentToken) {
							this.currentFCMToken = notificationToken;
						} else {
							this.currentFCMToken = currentToken;
							this._userService
								.registerFCMToken({
									agent: agents,
									token: currentToken,
								})
								.subscribe();
							this.setStorageItem('notification-token', currentToken);
							localStorage.setItem('notification-token', currentToken);
						}
					});
				} else {
				}
			})
			.catch((err) => {});
	}

	private addIcons(): void {
		addIcons({
			'app-icon-tbt-coin': 'assets/images/tbt-coin-white.svg',
			'app-icon-tether-icon': 'assets/images/tether-icon.svg',
			'app-icon-xp-icon': 'assets/images/xp-icon.svg',
			'app-icon-deposit-wallet': 'assets/images/deposit-wallet-icon.svg',
			'app-icon-withdraw-wallet': 'assets/images/withdraw-wallet-icon.svg',
			'app-icon-receive-money': 'assets/images/receive-money-icon.svg',
			'app-icon-send-money': 'assets/images/send-money-icon.svg',
			'app-icon-plus-money': 'assets/images/plus-money-icon.svg',
			'app-icon-minus-money': 'assets/images/minus-money-icon.svg',
			'app-icon-user-bar-status': 'assets/images/user-bar-status.svg',
			'app-icon-filter-left': 'assets/svg/filter-left.svg',
			'app-icon-ban': 'assets/images/ban.svg',
			'app-icon-tournaments-history': 'assets/svg/tournaments-history-icon.svg',
			'app-icon-pencil-square': 'assets/svg/pencil-square.svg',
			'app-icon-patch-check': 'assets/svg/patch-check.svg',
			'app-icon-arrow-repeat': 'assets/svg/arrow-repeat.svg',
			'app-icon-calendar4-week': 'assets/svg/calendar4-week.svg',
			'app-icon-coin': 'assets/svg/coin.svg',
			'app-icon-ranking': 'assets/svg/ranking.svg',
			'app-icon-diagram-2': 'assets/svg/diagram-2.svg',
			eye,
			leaf,
		});
	}

	public async logOut(): Promise<void> {
		this.getStorageItem('notification-token').then((value) => {
			const notificationToken = value;
			if (notificationToken && notificationToken !== '') {
				this._userService.unregisterFCMToken(notificationToken).subscribe({
					next: (response) => {
						const app = initializeApp(environment.firebaseConfig);
						const messaging = getMessaging(app);
						deleteToken(messaging).then(async (result) => {
							localStorage.removeItem('notification-token');
							this._layoutService.loading.next({
								isShow: true,
								title: 'Logout...',
								type: FullScreenLoadingTypeEnum.Default,
								isShowTryAgainButton: false,
							});
							this._authService.logout();
						});
					},
				});
			}
		});
	}

	private async setStorageItem(key: string, value: any): Promise<any> {
		await this._storage.set(key, value);
	}

	private async getStorageItem(key: string): Promise<any> {
		return await this._storage.get(key);
	}

	private async handleDuelTermsAndConditionsAcceptance(): Promise<void> {
		let duelTermsAndConditionsAccepted = await this._storage.get(AppConfig.duelTermsAndConditionsAccepted);
		if (duelTermsAndConditionsAccepted === undefined || duelTermsAndConditionsAccepted === null) {
			duelTermsAndConditionsAccepted = false;
			await this._storage.set(AppConfig.duelTermsAndConditionsAccepted, duelTermsAndConditionsAccepted);
		}
		this._layoutService.duelTermsAndConditionsAccepted.next(duelTermsAndConditionsAccepted);
	}

	private async handleDisableReceiveInvitation(): Promise<void> {
		let disableReceiveInvitation: DisableReceiveInvitationModel = await this._storage.get(AppConfig.disableReceiveInvitation);
		if (disableReceiveInvitation === undefined || disableReceiveInvitation === null) {
			disableReceiveInvitation = {
				startedAt: '',
				endsAt: '',
				time: DisableReceiveInvitationTimeEnum._0_min,
			};
			await this._storage.set(AppConfig.disableReceiveInvitation, disableReceiveInvitation);
		}
		this._layoutService.disableReceiveInvitation.next(disableReceiveInvitation);
	}
}
