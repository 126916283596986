import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IonicModule, ModalController, RangeCustomEvent } from '@ionic/angular';
import { CountdownConfig, CountdownEvent, CountdownModule } from 'ngx-countdown';
import { BattleInvitationReceivedDto, BattleInvitationReceivedResponseDto, BattleParticipantUserModel } from 'src/app/core/models/engine-signal.model';
import { GameService } from 'src/app/core/services/api/game-service/game.service';
import { LayoutService } from 'src/app/core/services/app/layout-service/layout.service';
import { ToastService } from 'src/app/core/services/app/toast-service/toast.service';
import { millisecondsToSeconds } from 'src/app/core/utils/functions.utils';
import { DepositTypeEnum } from 'src/app/core/enums/game.enum';
import { AvatarComponent } from '../avatar/avatar.component';
import { AppEngineService } from 'src/app/core/services/app/app-engine-service/app-engine.service';
import { CountdownTimerComponent } from '../countdown-timer/countdown-timer.component';
import { AppConfig } from 'src/app/core/constants/app-config.constants';
import { StorageService } from 'src/app/core/services/app/storage-service/storage.service';
import { DisableReceiveInvitationModel } from 'src/app/core/models/invitation.model';
import { CircularCountdownTimerComponent } from '../circular-countdown-timer/circular-countdown-timer.component';
import { FullScreenLoadingTypeEnum } from 'src/app/core/enums/app-full-screen-loading.enum';

@Component({
	selector: 'app-battle-invitation',
	standalone: true,
	templateUrl: './battle-invitation.component.html',
	styleUrls: ['./battle-invitation.component.scss'],
	imports: [IonicModule, CommonModule, CountdownModule, AvatarComponent, CountdownTimerComponent, CircularCountdownTimerComponent],
})
export class BattleInvitationComponent implements OnInit {
	// public invitation: BattleInvitationReceivedDto = new BattleInvitationReceivedDto();
	public receivedInvitationList: Array<BattleInvitationReceivedDto> = new Array<BattleInvitationReceivedDto>();
	public disableReceiveInvitation: DisableReceiveInvitationModel = new DisableReceiveInvitationModel();
	public sender: BattleParticipantUserModel = new BattleParticipantUserModel();
	public invitationCountdownConfig: Array<CountdownConfig> = new Array<CountdownConfig>();

	public constructor(
		private _modalCtrl: ModalController,
		private _layoutService: LayoutService,
		private _gameService: GameService,
		private _toastService: ToastService,
		private _appEngineService: AppEngineService,
		private _storage: StorageService,
	) {
		this._appEngineService.receivedInvitationList.subscribe((receivedInvitationList) => {
			console.log('Invitation Component : constructor : receivedInvitationList', receivedInvitationList);
			this.receivedInvitationList = receivedInvitationList;
		});

		this._layoutService.disableReceiveInvitation.subscribe((value) => {
			this.disableReceiveInvitation = value;
		});
	}

	public ngOnInit(): void {
		// console.log('🔵 invitation', this.invitation);
		// this.invitationCountdownConfig.leftTime = millisecondsToSeconds(this.invitation.remainingExpirationTime);
	}

	/**
	 * close
	 */
	public async close() {
		await this._modalCtrl.dismiss(undefined, 'close');
	}

	/**
	 * accept
	 */
	public accept(invitationId: string): void {
		this._layoutService.loading.next({
			isShow: true,
			title: 'Accepting Invitation...',
			type: FullScreenLoadingTypeEnum.Default,
			isShowTryAgainButton: false,
		});

		this._gameService.acceptFriendBattleInvitation(invitationId).subscribe({
			next: (response) => {
				if (response.isSuccess) {
					this._layoutService.loading.next({
						isShow: false,
						title: '',
						type: FullScreenLoadingTypeEnum.Default,
						isShowTryAgainButton: false,
					});
					this._toastService.success(response.successMessage, 'checkmark-outline');
				} else {
					this._layoutService.loading.next({
						isShow: false,
						title: '',
						type: FullScreenLoadingTypeEnum.Default,
						isShowTryAgainButton: false,
					});
					this._toastService.error(response.errors[0], 'alert-outline');
				}
			},
		});

		// this.close();
	}

	/**
	 * reject
	 */
	public reject(invitationId: string): void {
		this._layoutService.loading.next({
			isShow: true,
			title: 'Rejecting Invitation...',
			type: FullScreenLoadingTypeEnum.Default,
			isShowTryAgainButton: false,
		});

		this._gameService.denyFriendBattleInvitation(invitationId).subscribe({
			next: (response) => {
				if (response.isSuccess) {
					this._layoutService.loading.next({
						isShow: false,
						title: '',
						type: FullScreenLoadingTypeEnum.Default,
						isShowTryAgainButton: false,
					});
					this._toastService.success(response.successMessage, 'checkmark-outline');
				} else {
					this._layoutService.loading.next({
						isShow: false,
						title: '',
						type: FullScreenLoadingTypeEnum.Default,
						isShowTryAgainButton: false,
					});
					this._toastService.error(response.errors[0], 'alert-outline');
				}
			},
		});
		// this.close();
	}

	/**
	 * invitationCountdownEventHandler
	 */
	public invitationCountdownEventHandler(e: CountdownEvent, index: number) {
		if (e.action === 'done') {
			console.log('countdown ' + index + ' done.');
			this.invitationCountdownConfig = this.invitationCountdownConfig.splice(index, 1);
		} else {
			console.log('countdown ' + index + ' not done.');
		}
	}

	/**
	 * onTimerComplete
	 */
	public onTimerComplete(): void {
		console.log('Timer has completed!');
		console.log('🔵 this.receivedInvitationList 🔵', this.receivedInvitationList);
		// Add any additional logic you want to execute when the timer finishes
	}

	/**
	 * rejectAllInvitations
	 */
	public rejectAllInvitations() {
		this.receivedInvitationList.forEach((invitation, index) => {
			console.log('🔵 invitation-' + index, invitation);
			this.reject(invitation.id);
		});
	}

	/**
	 * handleSelectChange
	 */
	public async handleSelectChange(e: CustomEvent) {
		const now = new Date();
		this._layoutService.loading.next({
			isShow: true,
			title: 'Setting Mute Invitation...',
			type: FullScreenLoadingTypeEnum.Default,
			isShowTryAgainButton: false,
		});

		this.disableReceiveInvitation.startedAt = now.toString();
		this.disableReceiveInvitation.time = e.detail.value;
		this.disableReceiveInvitation.endsAt = new Date(now.valueOf() + e.detail.value * 60 * 1000).toString();

		await this._storage.set(AppConfig.disableReceiveInvitation, this.disableReceiveInvitation);

		this._layoutService.disableReceiveInvitation.next(this.disableReceiveInvitation);
		this._layoutService.loading.next({ isShow: false, title: '', type: FullScreenLoadingTypeEnum.Default, isShowTryAgainButton: false });
	}

	// Method that gets triggered when the countdown completes
	onCountdownCompleted() {
		console.log('🕛✔ Countdown is complete!');
	}

	/**
	 * getRemainingSeconds
	 */
	public getTotalSeconds(createdAt: string, expiredAt: string): number {
		// console.log('🔵 getTotalSeconds', Math.floor(millisecondsToSeconds(new Date(expiredAt).valueOf() - new Date(createdAt).valueOf())));
		const totalSeconds = Math.floor(millisecondsToSeconds(new Date(expiredAt).valueOf() - new Date(createdAt).valueOf()));
		return totalSeconds;
	}

	/**
	 * getRemainingSeconds
	 */
	public getRemainingSeconds(endsAt: string): number {
		const remaining = Math.floor(millisecondsToSeconds(new Date(endsAt).valueOf() - new Date().valueOf()));
		return remaining;
	}
}
