import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IMainResponse } from 'src/app/core/models/api.model';
import { BaseProfileApiService } from '../base/base-profile-api.service';
import { INotificationResponse } from 'src/app/core/models/notifications.model';
import { NotificationApiFilter } from 'src/app/core/enums/notification.enum';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	private readonly controller: string = 'notification';
	public constructor(private _baseProfileApiService: BaseProfileApiService) {}

	public getAllNotifications(pageNumber: number, type: string, filter: NotificationApiFilter): Observable<IMainResponse<INotificationResponse>> {
		return this._baseProfileApiService.get<IMainResponse<INotificationResponse>>(`${this.controller}?pageNumber=${pageNumber}&type=${type}&filter=${filter}`);
	}

	public markAsRead(notificationId: string): Observable<IMainResponse<boolean>> {
		return this._baseProfileApiService.put<IMainResponse<boolean>>(`${this.controller}/mark_as_seen/${notificationId}`);
	}

	public markAllAsRead(): Observable<IMainResponse<boolean>> {
		return this._baseProfileApiService.put<IMainResponse<boolean>>(`${this.controller}/mark_all_as_seen`);
	}
}
