<div
	class="col-12 m-0 p-1 px-3 justify-content-center custom-btn"
	[ngClass]="mode + ' ' + 'custom-btn-' + color + ' ' + mode + '-' + color + ' ' + 'custom-btn-' + padding + '-padding'"
>
	@if(loading){

	<span class="spinner-border spinner-border-sm"></span>
	<span class="visually-hidden" role="status">Loading...</span>
	} @if(!loading){

	<div class="row m-0 p-0">
		<span class="btn-text m-0 p-0">{{ text }}</span>
	</div>
	}
</div>
