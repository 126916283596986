import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IMainResponse, IPaginatoinData } from 'src/app/core/models/api.model';
import { BaseChatApiService } from '../base/base-chat-api.service';
import { IMessageSent, ISendMessageBody } from 'src/app/core/models/chat-api-model';
import { IChatDialog } from 'src/app/core/models/chat.model';

@Injectable({
	providedIn: 'root',
})
export class ChatService {
	private readonly controller: string = 'chat';
	public constructor(private _baseChatApiService: BaseChatApiService) {}

	public sendMessage(body: ISendMessageBody, responseType = {}): Observable<IMainResponse<IMessageSent>> {
		return this._baseChatApiService.post<IMainResponse<IMessageSent>>(`${this.controller}/send_message`, body);
	}

	public getChatItems(): Observable<IMainResponse<any>> {
		return this._baseChatApiService.get<IMainResponse<any>>(`${this.controller}/groups`);
	}

	public getChatMessagesByGroupId(groupId: string, pageNumber: number): Observable<IMainResponse<IPaginatoinData<IChatDialog>>> {
		return this._baseChatApiService.get<IMainResponse<IPaginatoinData<IChatDialog>>>(`${this.controller}/groups/${groupId}/messages?pageNumber=${pageNumber}`);
	}
	public getChatMessagesByUserId(userId: string, pageNumber: number): Observable<IMainResponse<any>> {
		return this._baseChatApiService.get<IMainResponse<any>>(`${this.controller}/groups/users/${userId}/messages?pageNumber=${pageNumber}`);
	}

	public getFriendList(): Observable<IMainResponse<any>> {
		return this._baseChatApiService.get<IMainResponse<any>>(`friend`);
	}
}
