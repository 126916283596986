import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '../toast-service/toast.service';

@Injectable({
	providedIn: 'root',
})
export class VisibilityService implements OnDestroy {
	// Observable to track visibility state
	private visibilitySubject = new BehaviorSubject<boolean>(!document.hidden);
	visibility$ = this.visibilitySubject.asObservable();

	constructor(private _toastService: ToastService) {
		// Bind the event listener
		document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
	}

	// Clean up the event listener when the service is destroyed
	ngOnDestroy() {
		document.removeEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
	}

	private handleVisibilityChange() {
		const isVisible = document.visibilityState === 'visible';
		this.visibilitySubject.next(isVisible);
		// console.log(isVisible ? 'App has woken up' : 'App is in the background');
		// this._toastService.info(isVisible ? 'App has woken up' : 'App is in the background');
	}
}
