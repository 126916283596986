// import { PageTransitionAnimation } from '../router-outlet/router-outlet.component';
import { TransitionOptions } from '@ionic/core/dist/types/utils/transition';
import { Animation, createAnimation } from '@ionic/core';
import { PageTransitionAnimation } from './custom.animation';

export const slideAnimation: any = (baseElement: HTMLElement, options: TransitionOptions): Animation => {
	const enteringEl = options.enteringEl;
	const leavingEl: any = options.leavingEl;
	const isBackDirection = options.direction === 'back';

	const rootTransition = createAnimation().addElement(enteringEl).duration(300).easing('ease').beforeRemoveClass('ion-page-invisible');

	const enteringContentAnimation = createAnimation().addElement(enteringEl);
	const leavingContentAnimation = createAnimation().addElement(leavingEl);
	if (isBackDirection) {
		enteringContentAnimation.fromTo('transform', 'translateX(-100%)', 'translateX(0%)');
		leavingContentAnimation.fromTo('transform', 'translateX(0%)', 'translateX(100%)');
	} else {
		enteringContentAnimation.fromTo('transform', 'translateX(100%)', 'translateX(0%)');
		leavingContentAnimation.fromTo('transform', 'translateX(0%)', 'translateX(-100%)');
	}
	rootTransition.addAnimation(enteringContentAnimation);
	rootTransition.addAnimation(leavingContentAnimation);

	return rootTransition;
};
