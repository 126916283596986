import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IMainResponse } from 'src/app/core/models/api.model';
import { BaseEngineApiService } from '../base/base-engine-api.service';
import { objectToQueryParam } from 'src/app/core/utils/functions.utils';
import { IUserBattleDetail } from 'src/app/core/models/battle-detail.model';
import { DuelListParamsModel } from 'src/app/core/models/engine-generic-api.model';
import { GameModel, GetDuelListResponseModel } from 'src/app/core/models/game.model';
import { LobbiesStartSearchingDto, ILobbiesStartSearchingRequest, SetChoiceOptionModel } from 'src/app/core/models/engine-signal.model';

@Injectable({
	providedIn: 'root',
})
export class GameService {
	private readonly controller: string = 'battle';

	public constructor(private _baseEngineApiService: BaseEngineApiService) {}

	public getBattleById(battleId: string): Observable<IMainResponse<IUserBattleDetail>> {
		return this._baseEngineApiService.get<IMainResponse<IUserBattleDetail>>(`${this.controller}/${battleId}`);
	}

	public lobbiesStartSearching(body: ILobbiesStartSearchingRequest, responseType = {}): Observable<IMainResponse<LobbiesStartSearchingDto>> {
		return this._baseEngineApiService.post<IMainResponse<LobbiesStartSearchingDto>>(
			`${this.controller}/lobbies/start_searching`,
			body,
			responseType,
		);
	}
	public cancelWaiting(duelSearchingId: string): Observable<IMainResponse<boolean>> {
		return this._baseEngineApiService.put<IMainResponse<boolean>>(`${this.controller}/searching/${duelSearchingId}/cancel`);
	}
	public cancelReady(lobbyId: string): Observable<IMainResponse<boolean>> {
		return this._baseEngineApiService.put<IMainResponse<boolean>>(`${this.controller}/lobbies/${lobbyId}/cancel`);
	}
	public cancelBattle(battleId: string): Observable<IMainResponse<boolean>> {
		return this._baseEngineApiService.put<IMainResponse<boolean>>(`${this.controller}/${battleId}/cancel`);
	}
	public playerIsReadyInLobby(lobbyId: string, responseType = {}): Observable<IMainResponse<string>> {
		return this._baseEngineApiService.post<IMainResponse<string>>(`${this.controller}/lobbies/${lobbyId}/user_is_ready`, responseType);
	}
	public inviteFriendToDuelBattle(
		targetUserId: string,
		body: ILobbiesStartSearchingRequest,
		responseType = {},
	): Observable<IMainResponse<LobbiesStartSearchingDto>> {
		return this._baseEngineApiService.post<IMainResponse<LobbiesStartSearchingDto>>(
			`${this.controller}/invitations/friends/${targetUserId}`,
			body,
			responseType,
		);
	}
	public acceptFriendBattleInvitation(invitationId: string): Observable<IMainResponse<string>> {
		return this._baseEngineApiService.put<IMainResponse<string>>(`${this.controller}/invitations/accept/${invitationId}`);
	}
	public denyFriendBattleInvitation(invitationId: string, responseType = {}): Observable<any> {
		return this._baseEngineApiService.delete(`${this.controller}/invitations/deny/${invitationId}`, responseType);
	}
	public setChoiceOption(body: SetChoiceOptionModel, battleId: string): Observable<IMainResponse<string>> {
		return this._baseEngineApiService.put<IMainResponse<string>>(`${this.controller}/${battleId}/set_choice_option`, body);
	}
	public getLobbiesState(): Observable<any> {
		return this._baseEngineApiService.get<any>(`${this.controller}/lobbies/state`);
	}
	public getDuelList(params?: DuelListParamsModel): Observable<IMainResponse<GetDuelListResponseModel>> {
		const queryParams = objectToQueryParam(params);

		return this._baseEngineApiService.get<IMainResponse<GetDuelListResponseModel>>(`${this.controller}${queryParams}`);
	}
}
