import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { AppTheme } from 'src/app/core/enums/app-theme.enum';

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	private activeTheme: string = AppTheme.dark;

	public constructor(private _storage: Storage) {
		this._storage.create();
	}

	get currentTheme(): string {
		return this.activeTheme;
	}

	// Change the theme
	public async setTheme(newTheme: string) {
		document.body.classList.remove(this.activeTheme);
		document.body.classList.add(newTheme);
		await this._storage.set('theme', newTheme);
		this.activeTheme = newTheme;
	}

	// Load the saved theme
	public async loadTheme() {
		const savedTheme = await this._storage.get('theme');
		if (savedTheme) {
			this.setTheme(savedTheme);
		} else {
			this.setTheme(this.activeTheme);
			await this._storage.set('theme', this.activeTheme);
		}
	}
}
