import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
	selector: 'app-lottie-reader',
	templateUrl: './lottie-reader.component.html',
	styleUrls: ['./lottie-reader.component.scss'],
})
export class LottieReaderComponent implements OnChanges {
	@Input() public fileName: string = '';
	@Input() public loop: boolean = true;
	@Input() public autoplay: boolean = true;
	@Input() titleText: string = '';
	@Output() animationCreated: EventEmitter<AnimationItem> = new EventEmitter<AnimationItem>();
	@ViewChild('lottieContainer') lottieContainer!: ElementRef;

	public options: AnimationOptions | null = null;
	public showAnimation: boolean = true; // For recreating ng-lottie

	public constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['fileName']) {
			this.updateAnimation();
		}
	}

	private updateAnimation(): void {
		this.showAnimation = false; // Temporarily hide ng-lottie
		setTimeout(() => {
			this.options = {
				path: `../../../../assets/lotties/${this.fileName}.json`,
				autoplay: this.autoplay,
				loop: this.loop,
			};
			this.showAnimation = true; // Recreate ng-lottie
		}, 0); // Re-enable after the DOM updates
	}

	public onAnimate(animationItem: AnimationItem): void {
		this.animationCreated.emit(animationItem);
	}
}
