import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { CapsuleComponent } from './capsule/capsule.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LottieReaderComponent } from './lottie/lottie-reader.component';
import { SimpleCardComponent } from './simple-card/simple-card.component';
import { TextButtonComponent } from './text-button/text-button.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { DetailedCardComponent } from './detailed-card/detailed-card.component';
import { NumberHumanizePipe } from 'src/app/core/pipes/number-humanize.pipe';
import { QRCodeModule } from 'angularx-qrcode';

export function playerFactory(): any {
	return import('lottie-web');
}
@NgModule({
	providers: [],
	imports: [
		QRCodeModule,
		FormsModule,
		IonicModule,
		CommonModule,
		TextButtonComponent,
		ReactiveFormsModule,
		LottieComponent,

		// LottieModule.forRoot({ player: playerFactory }),
	],
	declarations: [LottieReaderComponent, NumberHumanizePipe],
	exports: [LottieReaderComponent, NumberHumanizePipe, QRCodeModule],
})
export class SharedModule {}
