import { Injectable, NgZone } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	public constructor(private _storage: Storage, private _ngZone: NgZone) {
		this._init();
	}

	private _init(): void {
		this._storage.create();
	}

	public async set(key: string, value: any) {
		await this._storage?.set(key, value);
	}

	public async get(key: string): Promise<any> {
		const value = await this._storage?.get(key);
		// return value;
		return this._ngZone.run(() => value);
	}

	public async remove(key: string): Promise<void> {
		await this._storage?.remove(key);
	}

	public async clear(): Promise<void> {
		await this._storage?.clear();
	}
}
