import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IBattleDetail } from 'src/app/core/models/battle.model';
import { BaseProfileApiService } from '../base/base-profile-api.service';
import { IProfile, IReferral } from 'src/app/core/models/profile-api.model';
import { IMainResponse, IPaginatoinData } from 'src/app/core/models/api.model';
import { BattleFilterItems } from 'src/app/core/enums/battle-filter-items.enum';
import { IQuest, IQuestReward, IRewardsValue } from 'src/app/core/models/quest.model';

@Injectable({
	providedIn: 'root',
})
export class ProfileService {
	private readonly controller: string = 'profile';
	public constructor(private _baseProfileApiService: BaseProfileApiService) {}

	protected getProfile(): Observable<IMainResponse<IProfile>> {
		return this._baseProfileApiService.get<IMainResponse<IProfile>>(`${this.controller}`);
	}
	public getProfileById(userId: string): Observable<IMainResponse<IProfile>> {
		return this._baseProfileApiService.get<IMainResponse<IProfile>>(`${this.controller}/users/${userId}`);
	}
	public getInvitedUsers(): Observable<IMainResponse<Array<IReferral>>> {
		return this._baseProfileApiService.get<IMainResponse<Array<IReferral>>>(`${this.controller}/invited_users`);
	}
	public getBattles(pageNumber: number, filterBy: BattleFilterItems = BattleFilterItems.All): Observable<IMainResponse<IPaginatoinData<IBattleDetail>>> {
		return this._baseProfileApiService.get<IMainResponse<IPaginatoinData<IBattleDetail>>>(`${this.controller}/battles?pageNumber=${pageNumber}&filterBy=${filterBy}`);
	}
	public getUserBattles(userId: string, pageNumber: number, filterBy: BattleFilterItems = BattleFilterItems.All): Observable<IMainResponse<IPaginatoinData<IBattleDetail>>> {
		return this._baseProfileApiService.get<IMainResponse<IPaginatoinData<IBattleDetail>>>(
			`${this.controller}/users/${userId}/battles?pageNumber=${pageNumber}&filterBy=${filterBy}`,
		);
	}
	public getBattleById(battleId: string): Observable<IMainResponse<IBattleDetail>> {
		return this._baseProfileApiService.get<IMainResponse<IBattleDetail>>(`${this.controller}/battles/${battleId}`);
	}

	public getQuests(): Observable<IMainResponse<IRewardsValue<Array<IQuest>>>> {
		return this._baseProfileApiService.get<IMainResponse<IRewardsValue<Array<IQuest>>>>(`${this.controller}/rewards`);
	}
	public claimReward(typeId: number): Observable<IMainResponse<Array<IQuestReward>>> {
		return this._baseProfileApiService.get<IMainResponse<Array<IQuestReward>>>(`${this.controller}/rewards/claim?typeId=${typeId}`);
	}
}
