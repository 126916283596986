import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IFriendItem } from 'src/app/core/models/friend.model';
import { ChatService } from '../../api/chat-service/chat.service';
import { ChatSignalService } from '../../signal/chat-service/chat.service';
import { ProfileSignalService } from '../../signal/profile-signal-service/profile-signal.service';
import { ProfileService } from '../../api/profile-service/profile.service';
import { FriendService } from '../../api/friend-service/friend.service';

@Injectable({
	providedIn: 'root',
})
export class AppFriendsService {
	private friendsList: Array<IFriendItem> = new Array<IFriendItem>();
	public friends: BehaviorSubject<Array<IFriendItem>> = new BehaviorSubject<Array<IFriendItem>>(new Array<IFriendItem>());
	public constructor(private _chatService: ChatService, private _friendService: FriendService, private _profileSignalService: ProfileSignalService) {
		this._profileSignalService.friendIsOnline.subscribe((value) => {
			this.friendsList.forEach((friend) => {
				if (friend.id === value.id) {
					friend.isOnline = value.isOnline;
				}
			});
			this.friends.next(this.sortFriends(this.friendsList));
		});
		this._profileSignalService.friendIsOffline.subscribe((value) => {
			this.friendsList.forEach((friend) => {
				if (friend.id === value.id) {
					friend.isOnline = value.isOnline;
				}
			});
			this.friends.next(this.sortFriends(this.friendsList));
		});
		this._profileSignalService.friendIsRemoved.subscribe((removedFriend) => {
			let targetFriendIndex = this.friendsList.findIndex((friend) => friend.id == removedFriend.id);
			if (targetFriendIndex > -1) {
				this.friendsList.splice(targetFriendIndex, 1);
			}
			this.friends.next(this.sortFriends(this.friendsList));
		});
		this._profileSignalService.friendIsAdded.subscribe((player) => {
			console.log(player);

			this.friendsList.push({
				avatarUrl: player.data.userInfo.avatarUrl,
				id: player.data.userInfo.id,
				isFriend: true,
				isOnline: player.data.userInfo.isOnline,
				isPlayerInBattle: false,
				// email: null,
				profile: player.data.userInfo.profile,
				userName: player.data.userInfo.userName,
			});
			this.friends.next(this.sortFriends(this.friendsList));
		});
		this._profileSignalService.userIsInBattle.subscribe((player) => {
			this.friendsList.forEach((friend) => {
				if (friend.id == player.id) {
					friend.isPlayerInBattle = player.isPlayerInBattle;
				}
			});
			this.friends.next(this.sortFriends(this.friendsList));
		});
		this._profileSignalService.userIsAvailableForBattle.subscribe((player) => {
			this.friendsList.forEach((friend) => {
				if (friend.id == player.id) {
					friend.isPlayerInBattle = player.isPlayerInBattle;
				}
			});
			this.friends.next(this.sortFriends(this.friendsList));
		});
	}

	/**
	 * init
	 */
	public init() {
		this.getFriends();

		console.log('✅ AppFriendsService Initialized.');
	}

	/**
	 * getFriends
	 */
	public getFriends(): void {
		this._friendService.getFriendList().subscribe({
			next: (response: any) => {
				this.friendsList = this.sortFriends(response.value);

				this.friends.next(this.friendsList);
			},
			error: (error: any) => {},
		});
	}

	private sortFriends(friends: Array<IFriendItem>) {
		return friends.sort((a: IFriendItem, b: IFriendItem) => (b.isOnline ? 1 : 0) - (a.isOnline ? 1 : 0));
	}
}
