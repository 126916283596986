import { create, TimeSync } from 'timesync';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DateTime } from 'luxon';

@Injectable({
	providedIn: 'root',
})
export class TimeSyncService {
	private ts!: TimeSync;

	public constructor() {}

	/**
	 * init
	 */
	public init(): void {
		this.ts = create({
			server: environment.ENGINE_API + 'generic/time_sync',
			interval: 10000,
			repeat: 1,
		});
		this.ts.on('sync', (state: any) => {});
		this.ts.on('error', (err: any) => {});

		console.log('✅ TimeSyncService Initialized.');
	}

	/**
	 * getSynchronizedTime
	 */
	public getSynchronizedTime(): number {
		return this.ts.now();
	}

	/**
	 * getDiffInSeconds
	 */
	public getDiffInSeconds(targetDate: string): number {
		// Validate the input date
		if (!DateTime.fromISO(targetDate).isValid) {
			throw new Error(`Invalid date format: ${targetDate}`);
		}

		// Get synchronized current time
		const now = DateTime.fromMillis(this.getSynchronizedTime());

		// Parse the target date using Luxon's DateTime
		const target = DateTime.fromISO(targetDate);

		// Calculate the difference in seconds
		const differenceInSeconds = target.diff(now, 'seconds').seconds;

		// Return the difference rounded to the nearest second
		return Math.ceil(differenceInSeconds);
	}
}
