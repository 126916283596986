import { Injectable } from '@angular/core';
import { ChatSignalService } from '../../signal/chat-service/chat.service';

@Injectable({
	providedIn: 'root',
})
export class AppChatService {
	public constructor(private _chatSignalService: ChatSignalService) {}

	/**
	 * init
	 */
	public init() {
		this._chatSignalService.getMessagesToCheckUnreads();

		console.log('✅ AppChatService Initialized.');
	}
}
