<!-- <div #lottieContainer class="lottie-animation">
	<ng-lottie [options]="options" (animationCreated)="onAnimate($event)"> </ng-lottie>
</div> -->

<div *ngIf="showAnimation">
	<ng-lottie [options]="options" (animationCreated)="onAnimate($event)"> </ng-lottie>
</div>

<!-- 
<div class="container-sm" style="text-align: center">
	<div class="splash-text">
		<h1>
			{{ titleText }}
		</h1>
	</div>
</div> -->
