<div class="position-relative d-inline-flex justify-content-center align-items-center app-avatar">
	@if(rotateBorder) {
	<div
		class="app-rotate-circle-border"
		[ngClass]="
			{
				'32': 'app-avatar-32-gradient-border',
				'50': 'app-avatar-50-gradient-border',
				'64': 'app-avatar-64-gradient-border',
				'80': 'app-avatar-80-gradient-border',
				'100': 'app-avatar-100-gradient-border',
				'128': 'app-avatar-128-gradient-border',
				'150': 'app-avatar-150-gradient-border',
				'200': 'app-avatar-200-gradient-border'
			}[size]
		"
	></div>
	}
	<div
		class="overflow-hidden"
		[ngClass]="[
			{
				'32': 'app-' + shape + '-32',
				'50': 'app-' + shape + '-50',
				'64': 'app-' + shape + '-64',
				'80': 'app-' + shape + '-80',
				'100': 'app-' + shape + '-100',
				'128': 'app-' + shape + '-128',
				'150': 'app-' + shape + '-150',
				'200': 'app-' + shape + '-200',
			}[size],
			rotateBorder ? 'position-absolute' : '',
			skeleton ? 'app-shine-animation' : '',
			solidBorder ? 'app-white-border_' + borderWidth + 'px' : '',
			boxShadowAnimation ? boxShadowColor + '-box-shadow-animation' : '',
		]"
	>
		<!-- #region !Skeleton -->
		@if (!skeleton) {
		<img [src]="imageSource" class="w-100 h-100 app-avatar-image" [ngClass]="[rotateBorder ? 'position-absolute' : '']" />
		}

		<!-- #endregion -->

		<!-- #region Skeleton -->
		@if (skeleton && skeletonType === 'suffle-image') { @for (item of randomAvatars; track $index) {
		<img [src]="item" class="w-100 h-100 app-avatar-image" [ngClass]="[rotateBorder ? 'position-absolute' : '', shuffleIndex === $index ? 'd-flex' : 'd-none']" />
		} }
		<!-- #endregion -->
	</div>
</div>
