import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../../api/auth/auth.service';
import { IProfile } from 'src/app/core/models/profile-api.model';
import { ProfileService } from '../../api/profile-service/profile.service';
import { AppNotificationType } from 'src/app/core/enums/notification.enum';
import { BaseProfileApiService } from '../../api/base/base-profile-api.service';
import { ProfileSignalService } from '../../signal/profile-signal-service/profile-signal.service';
import { IRankingChangedMetaData } from 'src/app/core/models/notifications-meta-data/ranking-changed-meta-data';
import { IBattleWasEndedMetaData } from 'src/app/core/models/notifications-meta-data/battle-was-ended-meta-data.model';
import { IInvitedFriendHasBeenRegisteredMetaData } from 'src/app/core/models/notifications-meta-data/invited-friend-has-been-registered-meta-data';

@Injectable({
	providedIn: 'root',
})
export class AppProfileService extends ProfileService {
	// private profileDataSubject: BehaviorSubject<IProfile | null> = new BehaviorSubject<IProfile | null>(null);
	// public profileData$: Observable<IProfile | null> = this.profileDataSubject.asObservable();

	private localProfile: IProfile = new IProfile();
	public profile: BehaviorSubject<IProfile> = new BehaviorSubject<IProfile>(new IProfile());

	public constructor(
		private _profileSignalService: ProfileSignalService,
		private _authService: AuthService,
		_baseProfileApiService: BaseProfileApiService,
	) {
		super(_baseProfileApiService);
		this._authService.userData$.subscribe((value) => {
			if (value.sub !== '') {
				this._profileSignalService.newNotificationReceived.subscribe((value) => {
					this.handleProfileChange(value);
				});
				this._profileSignalService.userBalanceWasChanged.subscribe((value) => {
					this.localProfile.profile.coinBalance = value.profile.coinBalance;
					this.localProfile.profile.usdtBalance = value.profile.usdtBalance;
					this.profile.next(this.localProfile);
				});
			}
		});
	}

	public init(): void {
		this.getProfile().subscribe({
			next: (response: any) => {
				this.localProfile = response.value;
				this.profile.next(response.value);
			},
			error: (error: any) => {},
		});

		console.log('✅ AppProfileService Initialized.');
	}

	private handleProfileChange(value: { type: AppNotificationType; metadata: string }): void {
		switch (value.type) {
			case AppNotificationType.RankingChanged:
				this.profileRankingChanged(JSON.parse(value.metadata));
				break;
			case AppNotificationType.BattleWasEnded:
				this.profileBattleWasEnded(JSON.parse(value.metadata));
				break;
			case AppNotificationType.DepositLevelChanged:
				this.profileDepositLevelChanged(JSON.parse(value.metadata));
				break;
			case AppNotificationType.UserLevelChanged:
				this.profileUserLevelChanged(JSON.parse(value.metadata));
				break;
			case AppNotificationType.InvitedFriendHasBeenRegistered:
				this.profileInvitedFriendHasBeenRegistered(JSON.parse(value.metadata));
				break;
			case AppNotificationType.RankTitleChanged:
				this.profileRankTitleChanged(JSON.parse(value.metadata));
				break;
			default:
				break;
		}
	}

	private profileRankingChanged(metadata: IRankingChangedMetaData): void {
		this.localProfile.profile.rankNumber = metadata.Data.Profile.RankNumber;
		this.localProfile.profile.rankTitle = metadata.Data.Profile.RankTitle;
		this.localProfile.profile.rankTitleId = metadata.Data.Profile.RankTitleId;
		this.profile.next(this.localProfile);
	}
	private profileBattleWasEnded(metadata: IBattleWasEndedMetaData): void {
		// this.localProfile = metadata.Data.UpdatedProfile;
		this.localProfile.profile.userLevel = metadata.Data.UpdatedProfile.UserLevel;
		this.localProfile.profile.gameXP = metadata.Data.UpdatedProfile.GameXP;
		this.localProfile.profile.winrate = metadata.Data.UpdatedProfile.Winrate;
		this.localProfile.profile.levelXpRange.min = metadata.Data.UpdatedProfile.LevelXpRange.Min;
		this.localProfile.profile.levelXpRange.max = metadata.Data.UpdatedProfile.LevelXpRange.Max;
		this.localProfile.profile.currentWinStreak = metadata.Data.UpdatedProfile.CurrentWinStreak;
		this.localProfile.profile.longestWinStreak = metadata.Data.UpdatedProfile.LongestWinStreak;
		// DepositLevel
		this.localProfile.profile.depositLevelId = metadata.Data.UpdatedProfile.DepositLevelId;
		this.localProfile.profile.depositLevel.id = metadata.Data.UpdatedProfile.DepositLevel.Id;
		this.localProfile.profile.depositLevel.imageUrl = metadata.Data.UpdatedProfile.DepositLevel.ImageUrl;
		this.localProfile.profile.depositLevel.keyword = metadata.Data.UpdatedProfile.DepositLevel.Keyword;
		this.localProfile.profile.depositLevel.order = metadata.Data.UpdatedProfile.DepositLevel.Order;
		this.localProfile.profile.depositLevel.title = metadata.Data.UpdatedProfile.DepositLevel.Title;
		// RankTitle;
		this.localProfile.profile.rankNumber = metadata.Data.UpdatedProfile.RankNumber;
		this.localProfile.profile.rankTitleId = metadata.Data.UpdatedProfile.RankTitleId;
		this.localProfile.profile.rankTitle.id = metadata.Data.UpdatedProfile.RankTitle.Id;
		this.localProfile.profile.rankTitle.imageUrl = metadata.Data.UpdatedProfile.RankTitle.ImageUrl;
		this.localProfile.profile.rankTitle.keyword = metadata.Data.UpdatedProfile.RankTitle.Keyword;
		this.localProfile.profile.rankTitle.order = metadata.Data.UpdatedProfile.RankTitle.Order;
		this.localProfile.profile.rankTitle.title = metadata.Data.UpdatedProfile.RankTitle.Title;
		this.profile.next(this.localProfile);
	}
	private profileInvitedFriendHasBeenRegistered(metadata: IInvitedFriendHasBeenRegisteredMetaData): void {
		this.localProfile.profile.coinBalance = this.localProfile.profile.coinBalance + metadata.Data.CoinReward;
		this.localProfile.profile.gameXP = this.localProfile.profile.gameXP + metadata.Data.XpReward;
		this.profile.next(this.localProfile);
	}
	private profileDepositLevelChanged(metadata: any): void {}
	private profileUserLevelChanged(metadata: any): void {}
	private profileRankTitleChanged(metadata: any): void {}
}
