export enum TournamentStatusEnum {
	Created = 'Created',
	WaitingForSubscription = 'WaitingForSubscription',
	Running = 'Running',
	Ended = 'Ended',
	Canceled = 'Canceled',
}

export enum TournamentResultEnum {
	Waiting = 'Waiting',
	Winner = 'Winner',
	Loser = 'Loser',
	Draw = 'Draw',
	GiveUp = 'GiveUp',
}

export enum TournamentBattleCountdownTypeEnum {
	BattlePrediction = 'BattlePrediction',
	BattleEndsAt = 'BattleEndsAt',
	BattleGapTime = 'BattleGapTime',
}
