<button
	#neonButton
	class="d-flex justify-content-center align-items-center gap-2"
	[ngClass]="[
		'neon-button',
		'neon-button-' + color,
		full ? 'w-100' : '',
		disabled || loading ? 'disabled' : '',
		isActive ? 'active' : '',
		iconPosition === 'start' ? 'flex-row' : 'flex-row-reverse',
		{
			small: 'small',
			default: '',
			large: 'large'
		}[size],
		bottomShadow ? 'bottom-shadow' : '',
		shineAnimation ? 'shine-active' : '',
		iconOnly ? 'icon-only' : ''
	]"
	[ngStyle]="{ 'margin-bottom': !bottomShadow && 0 }"
	[style.border-radius]="borderRadius"
	[style.--border-radius]="borderRadius"
	[style.--color]="getColor()"
	[style.--box-shadow-blur]="boxShadowBlur"
	[style.--box-shadow-spread]="boxShadowSpread"
	[disabled]="disabled || loading"
	(click)="handleClick($event)"
>
	<ion-icon class="neon-button-icon" *ngIf="iconName && !loading" [name]="iconName"></ion-icon>
	<span class="neon-button-text" *ngIf="!loading && !iconOnly">{{ text }}</span>
	<div *ngIf="loading" [ngClass]="['app-three-dots-loading-' + color]"></div>

	<!-- Shine container -->
	<div class="shine-container" *ngIf="shineAnimation && !loading && !disabled" [style.border-radius]="borderRadius">
		<div class="shine"></div>
	</div>
	<div
		class="box-shadow-animation-container"
		*ngIf="boxShadowAnimation && !loading && !disabled"
		[ngClass]="[color + '-box-shadow-animation']"
		[style.border-radius]="borderRadius"
	></div>
</button>
