import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AnimationController, IonModal, ModalController } from '@ionic/angular';
import { IUserData } from 'src/app/core/models/auth/user-data.model';
import {
	BattleInvitationReceivedDto,
	BattleInvitationReceivedResponseDto,
	BattleParticipantModel,
	BattlePredictionChoiceWasChangedModel,
	BattleStartedBattleDto,
	BattleStartedResponseDto,
	LobbiesStartSearchingDto,
	LobbyWasCreatedDto,
} from 'src/app/core/models/engine-signal.model';
import { TraderBattleGameService } from 'src/app/modules/games/trader-battle-game/services/trader-battle-game.service';
import { AuthService } from '../../api/auth/auth.service';
import { EngineSignalService } from '../../signal/engine-signal-service/engine-signal.service';
import { LayoutService } from '../layout-service/layout.service';
import { ToastService } from '../toast-service/toast.service';
import { BattleInvitationComponent } from 'src/app/modules/shared/battle-invitation/battle-invitation.component';
import { millisecondsToSeconds } from 'src/app/core/utils/functions.utils';
import { GameService } from '../../api/game-service/game.service';
import { BehaviorSubject, filter } from 'rxjs';
import { GameStatusEnum, DepositTypeEnum } from 'src/app/core/enums/game.enum';
import { DisableReceiveInvitationModel } from 'src/app/core/models/invitation.model';
import { DisableReceiveInvitationTimeEnum } from 'src/app/core/enums/invitation.enum';
import { AppConfig } from 'src/app/core/constants/app-config.constants';
import { StorageService } from '../storage-service/storage.service';
import { DuelSignalService } from '../../signal/engine-signal-service/duel-signal-service/duel-signal.service';
import { FullScreenLoadingTypeEnum } from 'src/app/core/enums/app-full-screen-loading.enum';

@Injectable({
	providedIn: 'root',
})
export class AppEngineService {
	public user: IUserData = this._authService.emptyUser;
	public currentBattleId: string = String('');
	public disableReceiveInvitation: DisableReceiveInvitationModel = new DisableReceiveInvitationModel();
	public currentUrl: string = String('');
	public lobbies: Array<LobbyWasCreatedDto> = new Array<LobbyWasCreatedDto>();
	public lobbyRequests: Array<LobbiesStartSearchingDto> = new Array<LobbiesStartSearchingDto>();
	public invitations: Array<BattleInvitationReceivedDto> = new Array<BattleInvitationReceivedDto>();
	public inPredictionTimeBattleList: BehaviorSubject<Array<BattleStartedBattleDto>> = new BehaviorSubject<Array<BattleStartedBattleDto>>(
		new Array<BattleStartedBattleDto>(),
	);
	public runningBattleList: BehaviorSubject<Array<BattleStartedBattleDto>> = new BehaviorSubject<Array<BattleStartedBattleDto>>(
		new Array<BattleStartedBattleDto>(),
	);
	public receivedInvitationList: BehaviorSubject<Array<BattleInvitationReceivedDto>> = new BehaviorSubject<Array<BattleInvitationReceivedDto>>(
		new Array<BattleInvitationReceivedDto>(),
	);
	private modal!: HTMLIonModalElement;

	public constructor(
		private _router: Router,
		// private _engineSignalService: EngineSignalService,
		private _duelSignalService: DuelSignalService,
		private _traderBattleGameService: TraderBattleGameService,
		private _toastService: ToastService,
		private _layoutService: LayoutService,
		private _authService: AuthService,
		private _modalCtrl: ModalController,
		private _animationCtrl: AnimationController,
		private _gameService: GameService,
		private _storage: StorageService,
		private _activatedRoute: ActivatedRoute,
	) {
		this.createModal();

		this._layoutService.disableReceiveInvitation.subscribe((value) => {
			this.disableReceiveInvitation = value;
		});

		this._authService.userData$.subscribe((userData) => {
			if (userData.sub !== '') {
				this._layoutService.loading.next({
					isShow: false,
					title: '',
					type: FullScreenLoadingTypeEnum.Default,
					isShowTryAgainButton: false,
				});
				this.user = userData;

				this.getRunningBattleList();

				this.getLobbiesState();

				this.currentUrl = window.location.pathname;
				this.getStorageItem(AppConfig.currentBattleId).then((value) => {
					this.currentBattleId = value;
				});

				// #region Handle In Prediction Time Battle
				this.getInPredictionTimeBattleList();

				this.inPredictionTimeBattleList.subscribe((value) => {
					if (value.length > 0) {
						this.setStorageItem(AppConfig.inPredictionTimeBattle, value[0]);
						this._router.navigate([`/play/trader-battle/prediction/${value[0].id}`]);
						this._layoutService.loading.next({
							isShow: false,
							title: '',
							type: FullScreenLoadingTypeEnum.Default,
							isShowTryAgainButton: false,
						});
					}
				});
				// #endregion

				// #region Handle Running Battles
				// this.runningBattleList.subscribe((value) => {
				// 	if (value.length > 0) {
				// 		// this.setStorageItem(AppConfig.runningBattles, value);
				// 		value.forEach((element) => {
				// 			if (this.currentBattleId !== '') {
				// 				if (this.currentUrl) {
				// 					this.currentUrl = this.currentUrl.split('/')[this.currentUrl.split('/').length - 2];
				// 				}
				// 				if (element.id === this.currentBattleId && this.currentUrl === 'in-progress') {
				// 					this._router.navigate([`/play/trader-battle/in-progress/${element.id}`]);
				// 					this._layoutService.loading.next({
				// 						isShow: false,
				// 						title: '',
				// 						type: FullScreenLoadingTypeEnum.Default,
				// 						isShowTryAgainButton: false,
				// 					});
				// 				}
				// 			}
				// 		});
				// 	}
				// });
				// #endregion
			}
		});

		this._duelSignalService.findingOpponentsWasStarted.subscribe(async (lobby: LobbiesStartSearchingDto) => {
			this.lobbyRequests = await this._storage.get(AppConfig.lobbyRequests);
			this.lobbyRequests.push(lobby);
			this.setStorageItem(AppConfig.lobbyRequests, this.lobbyRequests);

			this._router.navigate([`play/trader-battle/lobby-waiting/${lobby.id}`]);
		});

		this._duelSignalService.findingOpponentsWasCanceled.subscribe(async (cancelerUserId: string, lobby: LobbiesStartSearchingDto) => {
			this.setStorageItem(AppConfig.lobbyRequests, []);
			this._router.navigate(['/play/trader-battle/lobby-making']);
		});

		this._duelSignalService.findingOpponentsWasExpired.subscribe(async (lobby: LobbiesStartSearchingDto) => {
			this.setStorageItem(AppConfig.lobbyRequests, []);
			const currentPath = this._router.url.split('/')[this._router.url.split('/').length - 2];

			if (currentPath === 'lobby-waiting') {
				this._router.navigate(['/play/trader-battle/lobby-making']);
			}
			this._toastService.error('findingOpponentsWasExpired', 'alert-outline');
		});

		this._duelSignalService.battleInvitationSent.subscribe((response) => {});

		this._duelSignalService.battleInvitationReceived.subscribe((response) => {
			console.log('🟢 AppEngineService: battleInvitationReceived:', response);

			if (
				this.disableReceiveInvitation.time === DisableReceiveInvitationTimeEnum._0_min ||
				(this.disableReceiveInvitation.endsAt !== '' && new Date().valueOf() > new Date(this.disableReceiveInvitation.endsAt).valueOf())
			) {
				if (!this.modal.isOpen) {
					console.log('🔵 this.disableReceiveInvitation.time', this.disableReceiveInvitation.time);
					this.invitations.push(response.invitation);
					this.receivedInvitationList.next(this.invitations);
					this.setStorageItem(AppConfig.invitations, this.invitations);
					this.resetDisableReceiveInvitation();
					this.modal.present();
				}
			}
		});

		this._duelSignalService.friendBattleInvitationWasExpired.subscribe((item) => {
			console.log('🟢 AppEngineService: friendBattleInvitationWasExpired:', item);

			this.invitations.shift();
			this.setStorageItem(AppConfig.invitations, this.invitations);

			if (this.invitations.length === 0) {
				this.modal.dismiss(null, 'close');
				this.createModal();
			} else {
				this.receivedInvitationList.next(this.invitations);
			}
		});

		this._duelSignalService.friendBattleInvitationWasDenied.subscribe((invitation) => {
			console.log('🟢 AppEngineService: friendBattleInvitationWasDenied:', invitation);

			this.invitations.shift();
			this.setStorageItem(AppConfig.invitations, this.invitations);

			if (this.invitations.length === 0) {
				this.modal.dismiss(null, 'close');
				this.createModal();
			} else {
				this.receivedInvitationList.next(this.invitations);
			}
		});

		this._duelSignalService.friendBattleInvitationWasAccepted.subscribe((invitation: BattleInvitationReceivedDto, lobby: LobbyWasCreatedDto) => {
			console.log('🟢 AppEngineService: friendBattleInvitationWasAccepted:', invitation, lobby);
			this.invitations = [];
			this.setStorageItem(AppConfig.invitations, this.invitations);
			this.modal.dismiss(null, 'close');
			this.createModal();
		});

		this._duelSignalService.lobbyWasCreated.subscribe(async (lobby: LobbyWasCreatedDto) => {
			console.log('🟢 AppEngineService: lobbyWasCreated:', lobby);
			const currentPath = this._router.url.split('/')[this._router.url.split('/').length - 2];

			// Set lobbyRequests = []
			this.setStorageItem(AppConfig.lobbyRequests, []);

			// Set Lobbies
			this.lobbies = await this._storage.get(AppConfig.lobbies);
			this.lobbies.push(lobby);
			this.setStorageItem(AppConfig.lobbies, this.lobbies);

			// this._traderBattleGameService.createdLobby.next(lobby);

			this._router.navigate([`play/trader-battle/lobby-ready/${lobby.id}`]);
			// if (currentPath !== 'lobby-waiting') {
			// }
		});

		this._duelSignalService.playerIsReadyInLobby.subscribe((userId) => {
			console.log('🟢 AppEngineService: playerIsReadyInLobby:', userId);
			if (userId === this.user.sub) {
				this._traderBattleGameService.iAmReady.next(true);
			} else {
				if (!this._traderBattleGameService.iAmReady.value) {
					this._toastService.info('Your Opponent Is Ready Now and Waiting for Your Readiness!', 'information-outline');
				}
			}
		});

		this._duelSignalService.lobbyWasExpired.subscribe((lobby: LobbyWasCreatedDto) => {
			this.setStorageItem(AppConfig.lobbies, []);
			const currentPath = this._router.url.split('/')[this._router.url.split('/').length - 2];

			if (currentPath === 'lobby-ready') {
				this._router.navigate(['/play/trader-battle/lobby-making']);
			}
			this._toastService.error('lobbyWasExpired', 'alert-outline');
		});

		this._duelSignalService.lobbyWasCanceled.subscribe((cancelerUserId: string, lobby: LobbyWasCreatedDto) => {
			this.setStorageItem(AppConfig.lobbies, []);

			this._router.navigate(['/play/trader-battle/lobby-making']);
			this._toastService.info('Lobby Was Canceled!', 'information-outline');
		});

		this._duelSignalService.creatingAndPreparingBattle.subscribe((item) => {
			console.log('🟢 AppEngineService: creatingAndPreparingBattle:', item);
		});

		this._duelSignalService.preparingBattleWasCanceled.subscribe((item) => {
			console.log('🟢 AppEngineService: preparingBattleWasCanceled:', item);
		});

		this._duelSignalService.battleStarted.subscribe((item: BattleStartedResponseDto) => {
			console.log('🟢 AppEngineService: battleStarted:', item.lobby, item.battle);
			// //////////////
			this.setStorageItem(AppConfig.lobbies, []);
			this.setStorageItem(AppConfig.inPredictionTimeBattle, item.battle);
			// //////////////

			// this.inPredictionTimeBattleList.value.push(item.battle);
			this.getRunningBattleList();

			this._router.navigate([`/play/trader-battle/prediction/${item.battle.id}`]);
		});

		this._duelSignalService.battlePredictionChoiceWasChanged.subscribe((item: BattlePredictionChoiceWasChangedModel) => {
			console.log('🟢 AppEngineService: battlePredictionChoiceWasChanged:', item);
			this._traderBattleGameService.selectedChoice.next(item.selectedChoice);

			this.getStorageItem(AppConfig.inPredictionTimeBattle).then((value) => {
				if (value) {
					let inPredictionTimeBattleTemp = value;

					inPredictionTimeBattleTemp.participants.forEach((element: BattleParticipantModel, index: number) => {
						if (element.userId === item.userId) {
							inPredictionTimeBattleTemp.participants[index].selectedChoice = item.selectedChoice;
						}
					});

					this.setStorageItem(AppConfig.inPredictionTimeBattle, inPredictionTimeBattleTemp);
				}
			});
		});

		this._duelSignalService.battleRenewed.subscribe((item) => {
			console.log('🟢 AppEngineService: battleRenewed:', item);
		});

		this._duelSignalService.battleEnded.subscribe((battle: BattleStartedBattleDto) => {
			console.log('🟢 AppEngineService: battleEnded:', battle);
			const currentPath = this._router.url.split('/')[this._router.url.split('/').length - 2];
			const currentBattleId = this._router.url.split('/')[this._router.url.split('/').length - 1];
			console.log('🔵 currentBattleId', currentBattleId);

			this.getRunningBattleList();
			// this.inPredictionTimeBattleList.value.forEach((runningDuel, index) => {
			// 	if (runningDuel.id === battle.id) {
			// 		this.inPredictionTimeBattleList.value.splice(index, 1);
			// 	}
			// });

			if (this.currentBattleId === battle.id) {
				this.setStorageItem(AppConfig.currentBattleId, '');
			}
			if (currentPath === 'in-progress' && battle.id === currentBattleId) {
				this._router.navigate([`/play/trader-battle/result/${battle.id}`], {
					state: {
						data: {
							battle,
						},
					},
				});
			} else {
				this._toastService.info('Battle Ended!', 'information-outline');
			}
		});

		this._duelSignalService.battleCanceled.subscribe((item) => {
			console.log('🟢 AppEngineService: battleCanceled:', item);
		});
	}

	// #region Private Methods
	private async createModal(): Promise<void> {
		this.modal = await this._modalCtrl.create({
			component: BattleInvitationComponent,
			id: 'battle-invitation-modal',
			canDismiss: (data?: any, role?: string) => {
				return new Promise<boolean>((resolve) => resolve(role === 'close'));
			},
			enterAnimation: this.enterAnimation,
			leaveAnimation: this.leaveAnimation,
		});

		const { data, role } = await this.modal.onWillDismiss();

		if (role === 'close' || role == 'backdrop') {
			console.log('▶▶▶▶ close ◀◀◀◀');
		}
		if (role === 'confirm') {
			console.log('▶▶▶▶ confirm ◀◀◀◀');
		}
	}

	private enterAnimation = (baseEl: HTMLElement) => {
		const root = baseEl.shadowRoot!;

		const backdropAnimation = this._animationCtrl
			.create()
			.addElement(root.querySelector('ion-backdrop')!)
			.fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

		const wrapperAnimation = this._animationCtrl
			.create()
			.addElement(root.querySelector('.modal-wrapper')!)
			.keyframes([
				{ offset: 0, opacity: '0', transform: 'scale(0)' },
				{ offset: 1, opacity: '0.99', transform: 'scale(1)' },
			]);

		return this._animationCtrl.create().addElement(baseEl).easing('ease-out').duration(200).addAnimation([backdropAnimation, wrapperAnimation]);
	};

	private leaveAnimation = (baseEl: HTMLElement) => {
		return this.enterAnimation(baseEl).direction('reverse');
	};

	private getInPredictionTimeBattleList(): void {
		// this._layoutService.loading.next({ isShow: true, title: 'Loading In Prediction Time Battles...', type: FullScreenLoadingTypeEnum.Default, isShowTryAgainButton: false });
		this._gameService.getDuelList({ loadOnlyInPredictionTimeBattle: true }).subscribe({
			next: (response) => {
				this.inPredictionTimeBattleList.next(response.value.items);
				this._layoutService.loading.next({
					isShow: false,
					title: '',
					type: FullScreenLoadingTypeEnum.Default,
					isShowTryAgainButton: false,
				});
			},
		});
	}

	private async resetDisableReceiveInvitation() {
		let disableReceiveInvitation = {
			startedAt: '',
			endsAt: '',
			time: DisableReceiveInvitationTimeEnum._0_min,
		};
		this.setStorageItem(AppConfig.disableReceiveInvitation, disableReceiveInvitation);
		this._layoutService.disableReceiveInvitation.next(disableReceiveInvitation);
	}

	private async setStorageItem(key: string, value: any): Promise<any> {
		await this._storage.set(key, value);
	}

	private async getStorageItem(key: string): Promise<any> {
		return await this._storage.get(key);
	}

	private getLobbiesState(): void {
		this._gameService.getLobbiesState().subscribe({
			next: (response) => {
				this._layoutService.loading.next({
					isShow: false,
					title: '',
					type: FullScreenLoadingTypeEnum.Default,
					isShowTryAgainButton: false,
				});
				console.log('🔵 getLobbiesState:', response.value);

				// #region Set Response Values to Storage and Variables
				console.log('🔵 response.value.lobbies', response.value.lobbies);
				this.setStorageItem(AppConfig.lobbies, response.value.lobbies);
				this.lobbies = response.value.lobbies;

				this.setStorageItem(AppConfig.lobbyRequests, response.value.lobbyRequests);
				this.lobbyRequests = response.value.lobbyRequests;

				this.setStorageItem(AppConfig.invitations, response.value.invitations);
				this.invitations = response.value.invitations;
				// #endregion

				// #region Handle Lobby Requests
				if (this.lobbyRequests.length !== 0) {
					this._layoutService.loading.next({
						isShow: true,
						title: 'Loading Lobby Requests...',
						type: FullScreenLoadingTypeEnum.Default,
						isShowTryAgainButton: false,
					});

					this.lobbyRequests.forEach((item: LobbiesStartSearchingDto) => {
						if (item.isActive === true) {
							this._router.navigate([`play/trader-battle/lobby-waiting/${item.id}`]);

							this._layoutService.loading.next({
								isShow: false,
								title: '',
								type: FullScreenLoadingTypeEnum.Default,
								isShowTryAgainButton: false,
							});
						}
					});
				}
				// #endregion

				// #region Handle Lobbies
				if (this.lobbies.length !== 0) {
					this._layoutService.loading.next({
						isShow: true,
						title: 'Loading Lobbies...',
						type: FullScreenLoadingTypeEnum.Default,
						isShowTryAgainButton: false,
					});

					this.lobbies.forEach((item: LobbyWasCreatedDto) => {
						if (item.isActive === true) {
							// this._traderBattleGameService.createdLobby.next(item);

							this._router.navigate([`play/trader-battle/lobby-ready/${item.id}`]);

							this._layoutService.loading.next({
								isShow: false,
								title: '',
								type: FullScreenLoadingTypeEnum.Default,
								isShowTryAgainButton: false,
							});
							for (const [key, value] of Object.entries(item.readiness)) {
								if (key === this.user.sub) {
									if (value === true) {
										this._traderBattleGameService.iAmReady.next(true);
									}
								}
							}
						}
					});
				}
				// #endregion

				// #region Handle Invitations
				if (this.invitations.length !== 0) {
					if (
						this.disableReceiveInvitation.time === DisableReceiveInvitationTimeEnum._0_min ||
						(this.disableReceiveInvitation.endsAt !== '' &&
							new Date().valueOf() > new Date(this.disableReceiveInvitation.endsAt).valueOf())
					) {
						this.resetDisableReceiveInvitation();
						this.invitations.forEach((element) => {
							if (element.sender.id !== this.user.sub) {
								this.receivedInvitationList.next(this.invitations);
								this.modal.present();
							}
						});
					}
				}
				// #endregion
			},
		});
	}
	// #endregion

	/**
	 * init
	 */
	public init() {
		console.log('✅ AppEngineService Initialized.');
	}

	/**
	 * getRunningBattleList
	 */
	public getRunningBattleList(): void {
		// this._layoutService.loading.next({ isShow: true, title: 'Loading Running Battles...', type: FullScreenLoadingTypeEnum.Default, isShowTryAgainButton: false });

		this._gameService.getDuelList({ status: GameStatusEnum.Running }).subscribe({
			next: (response) => {
				if (response.value && response.value.items) {
					this.runningBattleList.next(response.value.items);
					this.setStorageItem(AppConfig.runningBattles, response.value.items);
				} else {
					console.error('Unexpected response structure', response);
				}
				this._layoutService.loading.next({
					isShow: false,
					title: '',
					type: FullScreenLoadingTypeEnum.Default,
					isShowTryAgainButton: false,
				});
			},
			error: (err) => {
				console.error('Error fetching running battle list', err);
				this._layoutService.loading.next({
					isShow: false,
					title: '',
					type: FullScreenLoadingTypeEnum.Default,
					isShowTryAgainButton: true,
				});
			},
		});
	}
}
