import { GenericGameKeyEnum, GenericGameThemeEnum, GenericGameTourGuideTypeEnum, GenericGameTypeEnum } from '../enums/engine-generic.enum';
import { DepositTypeEnum, GameStatusEnum } from '../enums/game.enum';
import { TransactionsFilterToken } from '../enums/transaction-token.enum';
import * as SignalR from '@microsoft/signalr';

export interface IGenericGame {
	id: string;
	title: string;
	key: GenericGameKeyEnum;
	iconUrl: string;
	coverUrl: string;
	shortDescription: string;
	description: string;
	termsAndConditions: string;
	usersAtBattle: number;
	usersAtLobby: number;
	theme: GenericGameThemeEnum;
	type: GenericGameTypeEnum;
	isActive: true;
	tourGuides: Array<IGenericGameTourGuide>;
}

export interface IGenericGameTourGuide {
	type: GenericGameTourGuideTypeEnum;
	url: string;
	title: string;
	description: string;
	metaData: string;
	order: number;
	id: string;
}

export class DuelListParamsModel {
	status?: GameStatusEnum;
	token?: DepositTypeEnum;
	pageNumber?: number;
	pageSize?: number;
	loadOnlyInPredictionTimeBattle?: boolean = false;
}

export class CreatedHubConnectionModel {
	isReady: boolean = false;
	connection: SignalR.HubConnection | null = null;
}
