// import { PageTransitionAnimation } from '../router-outlet/router-outlet.component';
import { TransitionOptions } from '@ionic/core/dist/types/utils/transition';
import { Animation, createAnimation } from '@ionic/core';
// import { pageASelector } from '../page-a/page-a.component';
// import { pageBSelector } from '../page-b/page-b.component';
// import { pageCSelector } from '../page-c/page-c.component';
// import { slideVerticallyAnimation } from './slide-vertically.animation';
// import { slideHorizontallyAnimation } from './slide-horizontally.animation';
import { BackButtonEventDetail, Platforms, getPlatforms, isPlatform } from '@ionic/core';
import { slideAnimation } from './slide.animation';

export type PageTransitionAnimation = (baseEl: HTMLElement, opts?: TransitionOptions) => Animation;

export const customPageTransitionAnimation: any = (baseElement: HTMLElement, options: TransitionOptions): Animation => {
	const enteringEl = options.enteringEl;
	const leavingEl = options.leavingEl;
	const isBackDirection = options.direction === 'back';

	// if (areTagsEqual(enteringEl, 'pageASelector') && areTagsEqual(leavingEl, 'pageBSelector')) {
	// 	return slideVerticallyAnimation(baseElement, options);
	// }

	// if (areTagsEqual(enteringEl, 'pageBSelector') && areTagsEqual(leavingEl, 'pageASelector')) {
	// 	return slideVerticallyAnimation(baseElement, options);
	// }

	// if (isPlatform(window, 'ios')) {
	// 	return slideVerticallyAnimation(baseElement, options);
	// }
	return slideAnimation(baseElement, options);
};

export const areTagsEqual = (element: HTMLElement | undefined, tag: string): boolean => {
	return Boolean(element && element.tagName.toLowerCase() === tag.toLowerCase());
};
