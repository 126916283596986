import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'numberHumanize',
})
export class NumberHumanizePipe implements PipeTransform {
	transform(value: number, unit: boolean): string {
		if (value === null || value === undefined) return '';
		if (value < 1000) return value.toString();

		const units = ['K', 'M', 'B', 'T']; // K for Thousand, M for Million, B for Billion, T for Trillion
		let unitIndex = -1;
		let humanizedValue = value;

		while (humanizedValue >= 1000 && unitIndex < units.length - 1) {
			humanizedValue /= 1000;
			unitIndex++;
		}
		if (unit) {
			return `${humanizedValue.toFixed(2)}${units[unitIndex]}`;
		} else {
			return `${humanizedValue.toFixed(2)}`;
		}
	}
}
