import { Injectable } from '@angular/core';
import { BaseEngineApiService } from '../base/base-engine-api.service';
import { Observable } from 'rxjs';
import { IMainResponse, IPaginatoinData } from 'src/app/core/models/api.model';
import { TournamentBattleModel, TournamentListParamsModel, TournamentModel } from 'src/app/core/models/tournament.model';
import { objectToQueryParam } from 'src/app/core/utils/functions.utils';
import { TournamentStatusEnum } from 'src/app/core/enums/tournament.enum';

@Injectable({
	providedIn: 'root',
})
export class TournamentService {
	private readonly controller: string = 'tournament';

	constructor(private _baseEngineApiService: BaseEngineApiService) {}

	// Tournament
	public getTournamentList(
		params?: TournamentListParamsModel,
		statuses?: Array<TournamentStatusEnum>,
	): Observable<IMainResponse<IPaginatoinData<TournamentModel>>> {
		let queryParam = objectToQueryParam(params);
		let statuseQueries = '';
		if (statuses && statuses.length !== 0) {
			statuses.forEach((status) => {
				statuseQueries = statuseQueries + '&status=' + status;
			});
		}
		return this._baseEngineApiService.get<IMainResponse<IPaginatoinData<TournamentModel>>>(
			`${this.controller}/tournaments${queryParam}${statuseQueries}`,
		);
	}

	// public getTournamentList(params?: TournamentListParamsModel): Observable<IMainResponse<Array<TournamentModel>>> {
	// 	const queryParams = objectToQueryParam(params);

	// 	return this._baseEngineApiService.get<IMainResponse<Array<TournamentModel>>>(`${this.controller}/tournaments${queryParams}`);
	// }
	public getTournamentById(tournamentId: string, responseType = {}): Observable<IMainResponse<TournamentModel>> {
		return this._baseEngineApiService.get<IMainResponse<TournamentModel>>(`${this.controller}/tournaments/${tournamentId}`, responseType);
	}
	public subscribeTournament(tournamentId: string, responseType = {}): Observable<IMainResponse<string>> {
		return this._baseEngineApiService.post<IMainResponse<string>>(`${this.controller}/tournaments/${tournamentId}/subscribe`, responseType);
	}
	public unsubscribeTournament(tournamentId: string, responseType = {}): Observable<any> {
		return this._baseEngineApiService.delete(`${this.controller}/tournaments/${tournamentId}/unsubscribe`, responseType);
	}
}
