import * as SignalR from '@microsoft/signalr';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '../../api/auth/auth.service';
import { environment } from 'src/environments/environment';
import { IFriendIsOnline } from '../chat-service/chat.service';
import { IFriendisAdded, IFriendisRemoved } from 'src/app/core/models/notification-signal.model';
import { GameService } from '../../api/game-service/game.service';
import { BehaviorSubject, catchError, concatMap, delay, filter, Observable, of, ReplaySubject, retryWhen, Subscription, take, throwError } from 'rxjs';
import {
	BattleInvitationReceivedDto,
	BattleInvitationReceivedResponseDto,
	BattleParticipantUserModel,
	BattlePredictionChoiceWasChangedModel,
	BattleStartedBattleDto,
	BattleStartedResponseDto,
	FriendBattleInvitationWasAcceptedDto,
	LobbiesStartSearchingDto,
	LobbyWasCreatedDto,
} from 'src/app/core/models/engine-signal.model';
import { SelectedChoiceEnum } from 'src/app/core/enums/game.enum';
import { LayoutService } from '../../app/layout-service/layout.service';
import { TournamentSignalService } from './tournament-signal-service/tournament-signal.service';
import { DuelSignalService } from './duel-signal-service/duel-signal.service';
import { CreatedHubConnectionModel } from 'src/app/core/models/engine-generic-api.model';
import { Platform } from '@ionic/angular';
import { ToastService } from '../../app/toast-service/toast.service';
import { VisibilityService } from '../../app/visibility-service/visibility.service';
import { logError } from 'src/app/core/utils/functions.utils';
import { FullScreenLoadingTypeEnum } from 'src/app/core/enums/app-full-screen-loading.enum';

@Injectable({
	providedIn: 'root',
})
export class EngineSignalService implements OnDestroy {
	protected hubConnection: SignalR.HubConnection | null = null;
	private readonly maxRetries = 5;
	private reconnectDelayFactor = 1000; // Initial retry delay (1 second)
	private reconnectDelay = 1000; // 1 second delay
	private readonly signalUrl: string = environment.ENGINE_HUB;
	private visibilitySubscription: Subscription;
	private connectionStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public connectionEstablished: BehaviorSubject<CreatedHubConnectionModel> = new BehaviorSubject<CreatedHubConnectionModel>(
		new CreatedHubConnectionModel(),
	);

	public constructor(
		protected _authService: AuthService,
		protected _gameService: GameService,
		protected _layoutService: LayoutService,
		private _platform: Platform,
		private _toastService: ToastService,
		private _visibilityService: VisibilityService,
	) {
		// Visibility Handler
		this.visibilitySubscription = this._visibilityService.visibility$.subscribe((isVisible) => {
			if (isVisible) {
				try {
					const connection = this.getHubConnection();
					if (
						connection.state !== SignalR.HubConnectionState.Connected &&
						connection.state !== SignalR.HubConnectionState.Connecting
					) {
						// Rebuild and start connection if not already connected
						this.rebuildAndStartConnection().catch((err) =>
							logError(err, 'Error rebuilding connection on visibility change'),
						);
					}
				} catch (err) {
					logError(err, 'Error checking connection state');
				}
			}
		});

		// #region Platform Pause and Resume Handlers
		this._platform.pause.subscribe(() => {
			// Pause or disconnect SignalR when app is minimized
			this._toastService.info('Platform Paused!', '');
		});

		this._platform.resume.subscribe(async () => {
			// Reconnect when the app is resumed
			this._toastService.info('Platform Resumed!', '');
		});
		// #endregion
	}

	ngOnDestroy(): void {
		if (this.hubConnection) {
			this.killConnection();
		}
		this.visibilitySubscription.unsubscribe();
	}

	private getHubConnection(): SignalR.HubConnection {
		if (!this.hubConnection) {
			throw new Error('Engine SignalR hub connection is not initialized.');
		}
		return this.hubConnection;
	}

	// Build the SignalR connection
	private async buildConnection(): Promise<void> {
		const accessToken = await this._authService.getAccessToken();

		this._layoutService.signalLoading.next({
			isShow: true,
			title: 'Building the Application Service...',
			type: FullScreenLoadingTypeEnum.Signal,
			isShowTryAgainButton: false,
		});

		if (!accessToken) {
			console.warn('🔴 No access token available. Retrying connection in 1 second.');
			setTimeout(() => this.rebuildAndStartConnection(), 1000);
			return;
		}

		if (this.hubConnection) {
			this.killConnection();
		}

		this.hubConnection = new SignalR.HubConnectionBuilder()
			.withUrl(this.signalUrl, {
				accessTokenFactory: () => accessToken,
				logger: SignalR.LogLevel.Debug,
				skipNegotiation: true,
				transport: SignalR.HttpTransportType.WebSockets,
			})
			.build();

		this.hubConnection.onclose(() => {
			console.warn('✖ Engine Signal Closed.');
			this.updateConnectionStatus(false);
			// this.reconnectWithRetries();
		});

		console.log('SignalR connection built');
	}

	// Start the SignalR connection
	private async startConnection(): Promise<void> {
		try {
			const connection = this.getHubConnection();
			await connection.start();
			if (connection.state === SignalR.HubConnectionState.Connected) {
				console.log('✔ Connected to Engine SignalR.');
				this.updateConnectionStatus(true);
				this.connectionEstablished.next({
					connection: this.hubConnection,
					isReady: true,
				});
			}
		} catch (err) {
			logError(err, 'Error starting SignalR connection');
			this.updateConnectionStatus(false);
			// this.reconnectWithRetries();
		}
	}

	/**
	 * rebuildAndStartConnection
	 */
	public async rebuildAndStartConnection(): Promise<void> {
		try {
			await this.buildConnection();
			await this.startConnection();
		} catch (err) {
			logError(err, 'Rebuild and start connection failed');
		}
	}

	// Retry the connection with exponential backoff
	// private retryConnection(): void {
	// 	let retries = 0;

	// 	this.updateConnectionStatus(false);

	// 	const retryObservable = new Observable<void>((observer) => {
	// 		const intervalId = setInterval(() => {
	// 			if (retries < this.maxRetries) {
	// 				retries++;
	// 				this.retryLogic(observer, retries, intervalId);
	// 			} else {
	// 				clearInterval(intervalId);
	// 				observer.error('Max retries reached. Could not reconnect to SignalR.');
	// 				this.updateConnectionStatus(false);
	// 			}
	// 		}, this.reconnectDelay);
	// 	});

	// 	retryObservable
	// 		.pipe(
	// 			retryWhen((errors) =>
	// 				errors.pipe(
	// 					take(this.maxRetries), // Limit the number of retries
	// 					concatMap((error, count) => {
	// 						// Use concatMap to create a delay between retries
	// 						const delayTime = Math.pow(2, count) * 1000; // Exponential backoff
	// 						console.log(`Retrying SignalR connection in ${delayTime}ms...`);
	// 						return of(error).pipe(delay(delayTime)); // Apply the delay
	// 					}),
	// 				),
	// 			),
	// 			catchError((err) => {
	// 				logError(err, 'SignalR reconnection failed');
	// 				this.updateConnectionStatus(false);
	// 				return throwError(() => new Error('SignalR reconnection failed')); // Use the new error throwing pattern
	// 			}),
	// 		)
	// 		.subscribe();
	// }

	// Retry logic helper
	// private retryLogic(observer: any, retries: number, intervalId: ReturnType<typeof setInterval>): void {
	// 	try {
	// 		const connection = this.getHubConnection();
	// 		connection
	// 			.start()
	// 			.then(() => {
	// 				clearInterval(intervalId); // Clear the interval if connection is successful
	// 				this.updateConnectionStatus(true);
	// 				observer.next(); // Signal success
	// 				observer.complete(); // Complete the observable
	// 			})
	// 			.catch(() => {
	// 				this.updateConnectionStatus(false);
	// 			});
	// 	} catch (err) {
	// 		console.error(`SignalR retry ${retries} failed, retrying...`);
	// 		this.updateConnectionStatus(false);
	// 	}
	// }

	// Kill the existing connection
	private killConnection(): void {
		if (this.hubConnection) {
			this.hubConnection.stop().catch((err) => {
				logError(err, 'Error stopping SignalR connection');
			});
			this.hubConnection = null;
		}
	}

	// Update connection status based on the current state
	private updateConnectionStatus(isConnected: boolean): void {
		this.connectionStatusSubject.next(isConnected);
	}

	// Method to get the current connection status
	public getConnectionStatus(): Observable<boolean> {
		return this.connectionStatusSubject.asObservable();
	}
}
