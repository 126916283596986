import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IonicModule, LoadingController } from '@ionic/angular';
import { LayoutService } from 'src/app/core/services/app/layout-service/layout.service';
import { SharedModule } from '../shared.module';
import { Router } from '@angular/router';
import { FullScreenLoadingModel } from 'src/app/core/models/app-full-screen-loading.model';
import { NeonButtonComponent } from '../neon-button/neon-button.component';
import { ButtonComponent } from '../button/button.component';
import { FullScreenLoadingTypeEnum } from 'src/app/core/enums/app-full-screen-loading.enum';
import { FullScreenLoadingTypeType } from 'src/app/core/types/app-full-screen-loading.type';

@Component({
	selector: 'app-full-screen-loading',
	standalone: true,
	templateUrl: './full-screen-loading.component.html',
	styleUrls: ['./full-screen-loading.component.scss'],
	imports: [IonicModule, CommonModule, SharedModule, NeonButtonComponent, ButtonComponent],
})
export class FullScreenLoadingComponent implements OnInit {
	public loading: FullScreenLoadingModel = new FullScreenLoadingModel();
	public fullScreenLoadingTypeEnum = FullScreenLoadingTypeEnum;

	public constructor(private _layoutService: LayoutService, private _router: Router, private cdr: ChangeDetectorRef) {
		this._layoutService.loading.subscribe((value) => {
			this.loading = value;
			// setTimeout(() => {
			// });
		});
		// this._layoutService.signalLoading.subscribe((value) => {
		// 	setTimeout(() => {
		// 		this.loading = value;
		// 	});
		// });
	}

	public ngOnInit(): void {}

	// Compute Lottie file name dynamically based on loading type
	get lottieFileName(): string {
		switch (this.loading.type) {
			case FullScreenLoadingTypeEnum.Signal:
				return 'signal-loading';
			case FullScreenLoadingTypeEnum.Default:
			default:
				return 'candlestick-loading';
		}
	}

	/**
	 * reloadApp
	 */
	public reloadApp() {
		window.location.reload();
	}
}
