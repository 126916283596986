<ion-app>
	<ion-split-pane contentId="main-content" when="true">
		<ion-router-outlet id="main-content" [animated]="true" [swipeGesture]="false"></ion-router-outlet>
	</ion-split-pane>

	@if (loading.isShow || signalLoading.isShow) {
	<app-full-screen-loading />

	}
</ion-app>
