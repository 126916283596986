import { Injectable, OnDestroy } from '@angular/core';
import { IonContent, Platform } from '@ionic/angular';
import { BehaviorSubject, combineLatest, map, Subject, Subscription } from 'rxjs';
import { AppConfig } from 'src/app/core/constants/app-config.constants';
import { FullScreenLoadingModel } from 'src/app/core/models/app-full-screen-loading.model';
import { IAppHeader } from 'src/app/core/models/app-header.model';
import { DisableReceiveInvitationModel } from 'src/app/core/models/invitation.model';
import { DisableReceiveInvitationTimeType } from 'src/app/core/types/invitation.type';
import { ToastService } from '../toast-service/toast.service';
import { VisibilityService } from '../visibility-service/visibility.service';
import { EngineSignalService } from '../../signal/engine-signal-service/engine-signal.service';

@Injectable({
	providedIn: 'root',
})
export class LayoutService implements OnDestroy {
	public loading: BehaviorSubject<FullScreenLoadingModel> = new BehaviorSubject<FullScreenLoadingModel>(new FullScreenLoadingModel());
	public signalLoading: BehaviorSubject<FullScreenLoadingModel> = new BehaviorSubject<FullScreenLoadingModel>(new FullScreenLoadingModel());
	public appHasError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isTournamentItemScroll: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private errorMessageSubject = new Subject<string | null>();
	errorMessage$ = this.errorMessageSubject.asObservable();

	private tournamentContentRef!: IonContent;
	// public invitationModalCanDismiss: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public initialHeaderConfig: IAppHeader = {
		title: AppConfig.title,
	};
	public headerConfig: BehaviorSubject<IAppHeader> = new BehaviorSubject<IAppHeader>(this.initialHeaderConfig);
	public tabBarVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	public gameCloseButtonVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public duelTermsAndConditionsAccepted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public disableReceiveInvitation: BehaviorSubject<DisableReceiveInvitationModel> = new BehaviorSubject<DisableReceiveInvitationModel>(
		new DisableReceiveInvitationModel(),
	);
	public settingsButtonVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public openSideMenu: Subject<boolean> = new Subject<boolean>();
	public constructor(private _platform: Platform, private _toastService: ToastService, private _visibilityService: VisibilityService) {
		this.presentPlatformToast();

		// this._platform.pause.subscribe(() => {
		// 	// Pause or disconnect SignalR when app is minimized
		// 	this._toastService.info('Platform Paused!', '');
		// });

		// this._platform.resume.subscribe(async () => {
		// 	// Reconnect when the app is resumed
		// 	this._toastService.info('Platform Resumed!', '');
		// });
	}

	ngOnDestroy() {}

	// Method to show error message
	showError(message: string) {
		this.errorMessageSubject.next(message);
	}

	// Method to clear the error message
	clearError() {
		this.errorMessageSubject.next(null);
	}

	/**
	 * scrollToTarget
	 */
	public scrollToTarget(id: string): void {
		document.querySelector(`#${id}`)?.scrollIntoView({ behavior: 'smooth' });
	}

	/**
	 * scrollAllContentsToTop
	 */
	public scrollAllContentsToTop(): void {
		const contentElements = document.querySelectorAll('ion-content');
		contentElements.forEach((content: any) => content.scrollToTop(500));
	}

	async presentPlatformToast() {
		let platformType = 'unknown';

		if (this._platform.is('ios')) {
			platformType = 'iOS';
		} else if (this._platform.is('android')) {
			platformType = 'Android';
		} else if (this._platform.is('desktop')) {
			platformType = 'Desktop';
		} else if (this._platform.is('mobileweb')) {
			platformType = 'Mobile Web';
		}

		// this._toastService.info(`You are using ${platformType}`, 'checkmark-outline', 2000);
	}

	// private handleVisibilityChange() {
	// 	if (document.visibilityState === 'hidden') {
	// 		console.log('App is in the background');
	// 		this._toastService.info('App is in the background!', '');
	// 		// Perform actions needed when the app goes into the background
	// 	} else if (document.visibilityState === 'visible') {
	// 		console.log('App has woken up');
	// 		this._toastService.info('App has woken up!', '');
	// 		// Perform actions needed when the app is brought to the foreground
	// 	}
	// }
}
