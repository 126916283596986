<div class="position-fixed d-flex flex-column justify-content-center align-items-center w-100 h-100 app-loading">
	<ng-container *ngIf="loading.type === fullScreenLoadingTypeEnum.Default">
		<div class="lottie-animation-box m-0 p-4 d-flex justify-content-center">
			<app-lottie-reader [fileName]="'candlestick-loading'"> </app-lottie-reader>
		</div>
	</ng-container>
	<!-- <ng-container *ngIf="loading.type === fullScreenLoadingTypeEnum.Signal">
		<div class="app-m dot-pulse"></div>
		<div class="lottie-animation-box m-0 p-4 d-flex justify-content-center">
			<app-lottie-reader [fileName]="'signal-loading'"> </app-lottie-reader>
		</div>
	</ng-container> -->

	<div class="d-flex flex-column justify-content-center align-items-center text-light">
		<div *ngIf="loading.title; else defaultTitle">{{ loading.title }}</div>
		<ng-template #defaultTitle><div>Loading...</div></ng-template>
		<!-- <div>Type: {{ loading.type }}</div> -->
	</div>

	@if (loading.isShowTryAgainButton) {
	<!-- <app-button shape="pill" size="small" color="primary">Try Again</app-button> -->
	<app-neon-button
		class="position-absolute bottom-0 start-50 translate-middle-x w-100 p-3"
		text="Reconnect"
		[full]="true"
		[animateOnClick]="true"
		boxShadowSpread="0.2em"
		(click)="reloadApp()"
	/>
	}
</div>
