export enum AppNotificationType {
	AdminMessage = 'AdminMessage', //ok
	FriendshipCreated = 'FriendshipCreated', // ok
	BattleWasEnded = 'BattleWasEnded', // ok
	UserLevelChanged = 'UserLevelChanged',
	DepositLevelChanged = 'DepositLevelChanged',
	RankTitleChanged = 'RankTitleChanged',
	RankingChanged = 'RankingChanged', //ok
	InvitedFriendHasBeenRegistered = 'InvitedFriendHasBeenRegistered', // ok
	TournamentStartingAlert = 'TournamentStartingAlert', // ok
	TournamentWasStarted = 'TournamentWasStarted', //ok
	TournamentWasEnded = 'TournamentWasEnded', //ok
	TournamentWasCanceled = 'TournamentWasCanceled', //ok
}

export enum NotificationApiFilter {
	All = 'All',
	Seen = 'Seen',
	Unseen = 'Unseen',
}
