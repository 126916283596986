import { Injectable, OnDestroy } from '@angular/core';
import { forkJoin, catchError, of, BehaviorSubject, Observable, combineLatest, map, Subscription } from 'rxjs';
import { ChatService } from '../chat-service/chat.service';
import { NotificationService } from '../notification-service/notification.service';
import { ProfileService } from '../profile-service/profile.service';
import { IProfile } from 'src/app/core/models/profile-api.model';
import { EngineSignalService } from '../../signal/engine-signal-service/engine-signal.service';
import { LayoutService } from '../../app/layout-service/layout.service';
import { ProfileSignalService } from '../../signal/profile-signal-service/profile-signal.service';
import { ChatSignalService } from '../../signal/chat-service/chat.service';
import { FullScreenLoadingTypeEnum } from 'src/app/core/enums/app-full-screen-loading.enum';
import { AppProfileService } from '../../app/app-profile-service/app-profile.service';
import { AppNotificationService } from '../../app/app-notification-service/app-notification.service';
import { AppFriendsService } from '../../app/app-friends-service/app-friends.service';
import { AppEngineService } from '../../app/app-engine-service/app-engine.service';
import { AppTournamentService } from '../../app/app-tournament-service/app-tournament.service';
import { TimeSyncService } from '../../app/timesync-service/timesync.service';
import { AppChatService } from '../../app/app-chat-service/app-chat.service';
import { AuthService } from '../auth/auth.service';
import { logError } from 'src/app/core/utils/functions.utils';
import { DuelSignalService } from '../../signal/engine-signal-service/duel-signal-service/duel-signal.service';
import { TournamentSignalService } from '../../signal/engine-signal-service/tournament-signal-service/tournament-signal.service';
import { VisibilityService } from '../../app/visibility-service/visibility.service';

@Injectable({
	providedIn: 'root',
})
export class DataSyncService implements OnDestroy {
	private profileDataSubject: BehaviorSubject<IProfile | null> = new BehaviorSubject<IProfile | null>(null);
	private visibilitySubscription: Subscription | null = null;
	private isLoading = true;
	private isFirstBoot = true;
	// private notificationsDataSubject: BehaviorSubject<any> = new BehaviorSubject(null);
	// private engineDataSubject: BehaviorSubject<any> = new BehaviorSubject(null);
	// private chatDataSubject: BehaviorSubject<any> = new BehaviorSubject(null);

	// notificationsData$ = this.notificationsDataSubject.asObservable();
	// engineData$ = this.engineDataSubject.asObservable();
	// chatData$ = this.chatDataSubject.asObservable();
	private _connections: Array<Observable<boolean>> = [
		this._engineSignalService.getConnectionStatus(),
		this._profileSignalService.getConnectionStatus(),
		this._chatSignalService.getConnectionStatus(),
	];

	constructor(
		// private _storage: Storage, // Add storage to persist data
		private _authService: AuthService,
		private _timeSyncService: TimeSyncService,
		private _appProfileService: AppProfileService,
		private _appNotificationService: AppNotificationService,
		private _appFriendsService: AppFriendsService,
		private _appEngineService: AppEngineService,
		private _tournamentSignalService: TournamentSignalService, // DO_NOT_DELETE:
		private _duelSignalService: DuelSignalService, // DO_NOT_DELETE:
		private _appTournamentService: AppTournamentService,
		private _appChatService: AppChatService,
		private _engineSignalService: EngineSignalService,
		private _profileSignalService: ProfileSignalService,
		private _chatSignalService: ChatSignalService,
		private _layoutService: LayoutService,
		private _visibilityService: VisibilityService,
	) {
		this._timeSyncService.init();

		// this.loadPersistedData();
		this._authService.userData$.subscribe((value) => {
			if (value.sub !== '') {
				this.connectToSignals();
				this.syncAppData();
				this.reloadOrSyncOnVisibilityChange();
				this.monitorConnections();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.visibilitySubscription) {
			this.visibilitySubscription.unsubscribe();
		}
	}

	// private async loadPersistedData() {
	// 	const persistedProfile = await this._storage.get('profile');
	// 	// const persistedNotifications = await this.storage.get('notifications');
	// 	// const persistedEngine = await this.storage.get('engine');
	// 	// const persistedChat = await this.storage.get('chat');

	// 	if (persistedProfile) {
	// 		this.profileDataSubject.next(persistedProfile);
	// 	}
	// 	// if (persistedNotifications) {
	// 	//   this.notificationsDataSubject.next(persistedNotifications);
	// 	// }
	// 	// if (persistedEngine) {
	// 	//   this.engineDataSubject.next(persistedEngine);
	// 	// }
	// 	// if (persistedChat) {
	// 	//   this.chatDataSubject.next(persistedChat);
	// 	// }
	// }

	// Sync data with the server

	public syncAppData(): void {
		this._appProfileService.init();
		this._appEngineService.init();
		this._appTournamentService.init();
		this._appFriendsService.init();
		this._appNotificationService.init();
		this._appChatService.init();

		// Similarly for other services like notifications, engine, and chat...
	}

	private connectToSignals(): void {
		// Profile Signal - Rebuild and start connection if not already connected
		this._profileSignalService
			.rebuildAndStartConnection()
			.catch((err) => logError(err, 'Error rebuilding profile connection on visibility change'));

		// Engine Signal - Rebuild and start connection if not already connected
		this._engineSignalService.rebuildAndStartConnection().catch((err) => logError(err, 'Error rebuilding engine connection on visibility change'));

		// Chat Signal - Rebuild and start connection if not already connected
		this._chatSignalService.rebuildAndStartConnection().catch((err) => logError(err, 'Error rebuilding chat connection on visibility change'));
	}

	private monitorConnections(): void {
		combineLatest(this._connections)
			.pipe(
				map((statuses: Array<boolean>) => statuses.some((status) => !status)),
				catchError((error) => {
					console.error('Error monitoring connections:', error);
					return of(true); // Default to loading state on error
				}),
			)
			.subscribe((isLoading) => {
				console.log('isLoading: ', isLoading);
				this.isLoading = isLoading;
				this._layoutService.signalLoading.next({
					isShow: isLoading,
					title: '',
					type: FullScreenLoadingTypeEnum.Signal,
					isShowTryAgainButton: isLoading,
				});
			});
	}

	private reloadOrSyncOnVisibilityChange() {
		this.visibilitySubscription = this._visibilityService.visibility$.subscribe((isVisible) => {
			if (isVisible && !this.isFirstBoot) {
				if (this.isLoading === true) {
					window.location.reload();
				} else {
					this.syncAppData();
				}
			} else {
				this.isFirstBoot = false;
			}
		});
	}
}
