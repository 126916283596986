<div
	class="countdown-timer d-flex justify-content-center align-items-center gap-2"
	[ngClass]="{
		'flex-column-reverse': timeDisplayPosition === 'top',
		'flex-row-reverse': timeDisplayPosition === 'left',
		'flex-row': timeDisplayPosition === 'right',
		'flex-column': timeDisplayPosition === 'bottom'
	}"
>
	<div *ngIf="!showTimeOnly" class="particle-container" [style.width]="diameter + 'px'" [style.height]="diameter + 'px'">
		<div
			*ngFor="let particle of particles"
			class="particle"
			[ngStyle]="{
				'left': particle.x + 'px',
				'top': particle.y + 'px',
				'--radiusX': particle.radiusX + 'px',
				'--radiusY': particle.radiusY + 'px',
				'opacity': particle.opacity,
				'width': particle.size + 'px',
				'height': particle.size + 'px',
				'background-color': particleColor,
			}"
		></div>
	</div>

	<svg *ngIf="!showTimeOnly" [attr.width]="diameter" [attr.height]="diameter" [attr.viewBox]="'0 0 ' + diameter + ' ' + diameter">
		<!-- #region Pie -->
		<!-- Background Circle for Pie -->
		<circle
			*ngIf="shape === 'pie'"
			class="background-circle"
			[attr.cx]="diameter / 2"
			[attr.cy]="diameter / 2"
			[attr.r]="radius"
			[attr.fill]="backgroundColor"
		></circle>
		<!-- Background Circle for Pie Shape, shows when not started -->
		<circle
			*ngIf="shape === 'pie' && showBackgroundCircle"
			[attr.cx]="diameter / 2"
			[attr.cy]="diameter / 2"
			[attr.r]="radius"
			[attr.fill]="foregroundColor"
		></circle>

		<!-- Pie shape (for countdown) -->
		<path
			*ngIf="shape === 'pie' && timerStarted"
			class="pie-shape"
			[attr.d]="describeArc(diameter / 2, diameter / 2, radius, 0, currentAngle)"
			[attr.fill]="foregroundColor"
		></path>

		<!-- Time in Center for Pie when timeDisplayPosition is 'center' -->
		<text
			*ngIf="shape === 'pie' && timeDisplayPosition === 'center'"
			[attr.x]="diameter / 2"
			[attr.y]="diameter / 2"
			text-anchor="middle"
			dominant-baseline="middle"
			[attr.fill]="textColor"
			[attr.font-size]="fontSize"
		>
			{{ formattedTime }}
		</text>
		<!-- #endregion -->

		<!-- #region Stroke -->
		<!-- Background Circle for Stroke -->
		<circle
			*ngIf="shape === 'stroke'"
			class="background-circle"
			[attr.cx]="diameter / 2"
			[attr.cy]="diameter / 2"
			[attr.r]="radius"
			[attr.stroke-width]="strokeWidth"
			[attr.stroke]="backgroundColor"
			fill="none"
		></circle>

		<!-- Foreground Circle (for stroke) -->
		<circle
			*ngIf="shape === 'stroke'"
			class="foreground-circle"
			[attr.cx]="diameter / 2"
			[attr.cy]="diameter / 2"
			[attr.r]="radius"
			[attr.stroke-width]="strokeWidth"
			[attr.stroke]="foregroundColor"
			[attr.stroke-dasharray]="circumference"
			[attr.stroke-dashoffset]="progressValue"
			stroke-linecap="round"
			fill="none"
			style="transform: rotate(-90deg); transform-origin: 50% 50%"
		></circle>

		<!-- Dot at the end of stroke -->
		<!-- <circle
			*ngIf="shape === 'stroke' && timerStarted"
			class="progress-dot"
			[attr.cx]="dotPosition.x"
			[attr.cy]="dotPosition.y"
			[attr.r]="5"
			[attr.fill]="foregroundColor"
		></circle> -->

		<!-- Time in Center for Stroke when timeDisplayPosition is 'center' (ignored for stroke) -->
		<text
			*ngIf="shape === 'stroke' && timeDisplayPosition === 'center'"
			[attr.x]="diameter / 2"
			[attr.y]="diameter / 2"
			text-anchor="middle"
			dominant-baseline="middle"
			[attr.fill]="textColor"
			[attr.font-size]="fontSize"
		>
			{{ formattedTime }}
		</text>
		<!-- #endregion -->
	</svg>

	<!-- Timer Display outside SVG for all shapes if timeDisplayPosition is not 'center' -->
	<div *ngIf="timeDisplayPosition !== 'center'" class="time-display" [ngStyle]="{ color: textColor, fontSize: fontSize }">
		{{ formattedTime }}
	</div>

	<div *ngIf="showTimeOnly" class="time-display" [ngStyle]="{ color: textColor, fontSize: fontSize + 'px' }">
		{{ formattedTime }}
	</div>
</div>

<!-- Buttons are always at the bottom -->
<!-- <div class="controls d-flex justify-content-center mt-3">
	<button (click)="startTimer()">Start</button>
	<button (click)="pauseTimer()">Pause</button>
	<button (click)="resetTimer()">Reset</button>
</div> -->
