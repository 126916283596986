import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../constants/app-config.constants';
import { AuthService } from '../services/api/auth/auth.service';
import { StorageService } from '../services/app/storage-service/storage.service';

export const loginGuard = async () => {
	const authService: AuthService = inject(AuthService);
	const storage: StorageService = inject(StorageService);
	const router: Router = inject(Router);
	let isAuthenticated: boolean = authService.getIsAuthenticated();
	const isSeenIntro = await storage.get(AppConfig.introSeen);
	if (!isAuthenticated && isSeenIntro) {
		return true;
	}
	isSeenIntro ? router.navigate(['home']) : router.navigate(['intro']);
	return false;
};
