import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { Router } from '@angular/router';
import {
	GroupedTournomentModel as GroupedTournamentModel,
	TournamentListParamsModel,
	TournamentModel,
	TournamentSignalDto,
	TournamentSubscriptionsUpdatedModel,
} from 'src/app/core/models/tournament.model';
import { TournamentService } from '../../api/tournament-service/tournament.service';
import { ToastService } from '../toast-service/toast.service';
import { TournamentResultEnum, TournamentStatusEnum } from 'src/app/core/enums/tournament.enum';
import { TournamentSignalService } from '../../signal/engine-signal-service/tournament-signal-service/tournament-signal.service';
import { AppConfig } from 'src/app/core/constants/app-config.constants';
import { LayoutService } from '../layout-service/layout.service';
import { StorageService } from '../storage-service/storage.service';
import { FullScreenLoadingTypeEnum } from 'src/app/core/enums/app-full-screen-loading.enum';
import { IUserData } from 'src/app/core/models/auth/user-data.model';
import { AuthService } from '../../api/auth/auth.service';
import { AppEngineService } from '../app-engine-service/app-engine.service';

@Injectable({
	providedIn: 'root',
})
export class AppTournamentService {
	public user: IUserData = this._authService.emptyUser;
	private upcomingTournamentsDataSubject: BehaviorSubject<Array<GroupedTournamentModel>> = new BehaviorSubject<Array<GroupedTournamentModel>>(
		new Array<GroupedTournamentModel>(),
	);
	private mineTournamentsDataSubject: BehaviorSubject<Array<GroupedTournamentModel>> = new BehaviorSubject<Array<GroupedTournamentModel>>(
		new Array<GroupedTournamentModel>(),
	);
	private allTournamentsDataSubject: BehaviorSubject<Array<GroupedTournamentModel>> = new BehaviorSubject<Array<GroupedTournamentModel>>(
		new Array<GroupedTournamentModel>(),
	);
	// private tournamentList: Array<TournamentModel> = new Array<TournamentModel>();
	private allDataSubject = [this.upcomingTournamentsDataSubject, this.mineTournamentsDataSubject, this.allTournamentsDataSubject];
	private pageSize: number = 20;
	private upcomingTournamentsPageNumber: number = 1;
	private mineTournamentsPageNumber: number = 1;
	private allTournamentsPageNumber: number = 1;

	public upcomingTournamentsData$: Observable<Array<GroupedTournamentModel>> = this.upcomingTournamentsDataSubject.asObservable();
	public mineTournamentsData$: Observable<Array<GroupedTournamentModel>> = this.mineTournamentsDataSubject.asObservable();
	public allTournamentsData$: Observable<Array<GroupedTournamentModel>> = this.allTournamentsDataSubject.asObservable();

	// public tournaments: BehaviorSubject<Array<TournamentModel>> = new BehaviorSubject<Array<TournamentModel>>(new Array<TournamentModel>());
	public selectedTournament: BehaviorSubject<TournamentModel> = new BehaviorSubject<TournamentModel>(new TournamentModel());

	constructor(
		private _router: Router,
		private _tournamentService: TournamentService,
		private _toastService: ToastService,
		private _tournamentSignalService: TournamentSignalService,
		private _layoutService: LayoutService,
		private _storage: StorageService,
		private _authService: AuthService,
		private _appEngineService: AppEngineService,
	) {
		this._authService.userData$.subscribe((userData) => {
			if (userData.sub !== '') {
				this.user = userData;
			}
		});

		// Subscribe to the `tournamentSubscriptionWasStarted` event to handle when a tournament's subscription starts
		this._tournamentSignalService.tournamentSubscriptionWasStarted.subscribe((tournament: TournamentModel) => {
			console.log('🟢 AppTournamentService: tournamentSubscriptionWasStarted:', tournament);

			// Store the current URL for later comparison
			const currentUrl = this._router.url;

			// Update the tournament status
			this.allDataSubject.forEach((subject) => {
				this.updateTournamentStatus(subject, tournament.id, TournamentStatusEnum.WaitingForSubscription);
			});

			// If the current page is not the tournament's page, show a toast notification with a link to view the tournament
			if (currentUrl !== `/tournaments/tournament/${tournament.id}`) {
				this._toastService.info(`The Subscription of Tournament <b>${tournament.title}</b> Was Started.`, 'information-outline', 3000, {
					buttonText: 'View Tournament',
					handler: (router: Router) => {
						router.navigate([`/tournaments/tournament/${tournament.id}`]); // Navigate to the tournament's page
					},
				});
			}

			// Check if the current URL matches the selected tournament's page
			if (currentUrl === `/tournaments/tournament/${tournament.id}`) {
				// get tournament by id again
				this.getTournamentById(tournament.id);
			}
		});

		this._tournamentSignalService.tournamentSubscriptionsUpdated.subscribe(
			(tournamentSubscriptionsUpdated: TournamentSubscriptionsUpdatedModel) => {
				console.log(
					'🟢 AppTournamentService: tournamentSubscriptionsUpdated:',
					tournamentSubscriptionsUpdated.id,
					tournamentSubscriptionsUpdated.subscriptions,
				);

				// Store the current URL for later comparison
				const currentUrl = this._router.url;

				// Find and update the tournament numberOfSubscribers in the list
				this.allDataSubject.forEach((subject) => {
					this.updateTournamentSubscribers(
						subject,
						tournamentSubscriptionsUpdated.id,
						tournamentSubscriptionsUpdated.subscriptions,
					);
				});

				// Check if the current URL matches the selected tournament's page
				if (currentUrl === `/tournaments/tournament/${tournamentSubscriptionsUpdated.id}`) {
					// Create a shallow copy of the current `selectedTournament` object and update its numberOfSubscribers
					const updatedTournament = {
						...this.selectedTournament.getValue(),
						numberOfSubscribers: tournamentSubscriptionsUpdated.subscriptions,
					};

					// Emit the updated tournament object to notify subscribers
					this.selectedTournament.next(updatedTournament);
				}
			},
		);

		this._tournamentSignalService.tournamentIsStartingAlert.subscribe((tournamentId: string) => {
			console.log('🟢 AppTournamentService: tournamentIsStartingAlert:', tournamentId);
			this._toastService.info('tournamentIsStartingAlert', 'information-outline');
		});

		this._tournamentSignalService.tournamentWasStarted.subscribe((tournament: TournamentModel) => {
			console.log('🟢 AppTournamentService: tournamentWasStarted:', tournament);

			// Store the current URL for later comparison
			const currentUrl = this._router.url;

			// Update the tournament status
			this.allDataSubject.forEach((subject) => {
				this.updateTournamentStatus(subject, tournament.id, TournamentStatusEnum.Running);
			});

			// If the current page is not the tournament's page, show a toast notification with a link to view the tournament
			if (currentUrl !== `/tournaments/tournament/${tournament.id}`) {
				this._toastService.info(`Tournament <b>${tournament.title}</b> Was Started.`, 'information-outline', 3000, {
					buttonText: 'View Tournament',
					handler: (router: Router) => {
						router.navigate([`/tournaments/tournament/${tournament.id}`]); // Navigate to the tournament's page
					},
				});
			}

			// Check if the current URL matches the selected tournament's page
			if (currentUrl === `/tournaments/tournament/${tournament.id}`) {
				// get tournament by id again
				this.getTournamentById(tournament.id);
			}
		});

		this._tournamentSignalService.tournamentWasCanceled.subscribe((tournament: TournamentModel) => {
			console.log('🟢 AppTournamentService: tournamentWasCanceled:', tournament);

			// Store the current URL for later comparison
			const currentUrl = this._router.url;

			// Update the tournament status
			this.allDataSubject.forEach((subject) => {
				this.updateTournamentStatus(subject, tournament.id, TournamentStatusEnum.Canceled);
			});

			// If the current page is not the tournament's page, show a toast notification
			if (currentUrl !== `/tournaments/tournament/${tournament.id}`) {
				this._toastService.info(`Tournament <b>${tournament.title}</b> Was Canceled.`, 'information-outline');
			}

			// Check if the current URL matches the selected tournament's page
			if (currentUrl === `/tournaments/tournament/${tournament.id}`) {
				// get tournament by id again
				this.getTournamentById(tournament.id);
			}
		});

		this._tournamentSignalService.tournamentBattleWasStarted.subscribe((tournamentBattleWasStarted: TournamentSignalDto) => {
			console.log(
				'🟢 AppTournamentService: tournamentBattleWasStarted:',
				tournamentBattleWasStarted.tournament,
				tournamentBattleWasStarted.battle,
			);

			// Store the current URL for later comparison
			const currentUrl = this._router.url;

			this._appEngineService.getRunningBattleList();
			this.setStorageItem(AppConfig.inPredictionTimeBattle, tournamentBattleWasStarted.battle);

			// If the current page is not the tournament's page, show a toast notification with a link to view the tournament
			if (currentUrl !== `/tournaments/tournament/${tournamentBattleWasStarted.tournament.id}`) {
				const currentSubscriber = tournamentBattleWasStarted.tournament.subscribers.find((item) => item.userId === this.user.sub);
				if (currentSubscriber?.result === TournamentResultEnum.Waiting) {
					this._toastService.info(
						`Battle <b>#${tournamentBattleWasStarted.tournament.currentIteration}</b> of <b>${tournamentBattleWasStarted.tournament.numberOfIterations}</b> of <b>${tournamentBattleWasStarted.tournament.title}</b> Tournament Was Started.`,
						'information-outline',
						3000,
						{
							buttonText: 'Go Battle',
							handler: (router: Router) => {
								router.navigate([`/play/trader-battle/prediction/${tournamentBattleWasStarted.battle.id}`]);
							},
						},
					);
				}
			}

			// Check if the current URL matches the selected tournament's page
			if (currentUrl === `/tournaments/tournament/${tournamentBattleWasStarted.tournament.id}`) {
				// get tournament by id again
				this.getTournamentById(tournamentBattleWasStarted.tournament.id);

				// Trigger tournament item scroll event for subscribers to scroll to the current battle
				this._layoutService.isTournamentItemScroll.next(true);
			}
		});

		this._tournamentSignalService.tournamentBattleWasEnded.subscribe((tournamentBattleWasEnded: TournamentSignalDto) => {
			console.log('🟢 AppTournamentService: tournamentBattleWasEnded:', tournamentBattleWasEnded.tournament, tournamentBattleWasEnded.battle);

			const currentUrl = this._router.url;

			this._appEngineService.getRunningBattleList();
			this.removeStorageItem(AppConfig.inPredictionTimeBattle);

			if (currentUrl === `/play/trader-battle/in-progress/${tournamentBattleWasEnded.battle.id}`) {
				this._router.navigate([`/tournaments/tournament/${tournamentBattleWasEnded.tournament.id}`]);
			}

			// If the current page is not the tournament's page, show a toast notification with a link to view the tournament
			if (currentUrl !== `/tournaments/tournament/${tournamentBattleWasEnded.tournament.id}`) {
				const currentSubscriber = tournamentBattleWasEnded.tournament.subscribers.find((item) => item.userId === this.user.sub);
				if (currentSubscriber?.result === TournamentResultEnum.Waiting) {
					this._toastService.info(
						`Battle <b>#${tournamentBattleWasEnded.tournament.currentIteration}</b> of <b>${tournamentBattleWasEnded.tournament.numberOfIterations}</b> of <b>${tournamentBattleWasEnded.tournament.title}</b> Tournament Was Ended.`,
						'information-outline',
					);
				}
			}

			// Check if the current URL matches the selected tournament's page
			if (currentUrl === `/tournaments/tournament/${tournamentBattleWasEnded.tournament.id}`) {
				// get tournament by id again
				this.getTournamentById(tournamentBattleWasEnded.tournament.id);

				// Reset tournament item scroll to prevent automatic scroll to current battle
				this._layoutService.isTournamentItemScroll.next(false);
			}
		});

		this._tournamentSignalService.tournamentWasEnded.subscribe((tournament: TournamentModel) => {
			console.log('🟢 AppTournamentService: tournamentWasEnded:', tournament);

			// Store the current URL for later comparison
			const currentUrl = this._router.url;

			// Update the tournament status
			this.allDataSubject.forEach((subject) => {
				this.updateTournamentStatus(subject, tournament.id, TournamentStatusEnum.Ended);
			});

			// If the current page is not the tournament's page, show a toast notification with a link to view the tournament
			if (currentUrl !== `/tournaments/tournament/${tournament.id}`) {
				this._toastService.info(`Tournament <b>${tournament.title}</b> Was Ended.`, 'information-outline');
			}

			// Check if the current URL matches the selected tournament's page
			if (currentUrl === `/tournaments/tournament/${tournament.id}`) {
				// get tournament by id again
				this.getTournamentById(tournament.id);

				// Scroll to Top
				this._layoutService.scrollAllContentsToTop();
			}
		});

		this._tournamentSignalService.youWonTournament.subscribe((tournament: TournamentModel) => {
			console.log('🟢 AppTournamentService: youWonTournament:', tournament);

			// Store the current URL for later comparison
			const currentUrl = this._router.url;

			// Update the tournament result
			this.allDataSubject.forEach((subject) => {
				this.updateTournamentResult(subject, tournament.id, TournamentResultEnum.Winner);
			});

			// If the current page is not the tournament's page, show a toast notification with a link to view the tournament
			if (currentUrl !== `/tournaments/tournament/${tournament.id}`) {
				this._toastService.success(`You Won the <b>${tournament.title}</b> Tournament.`, 'checkmark-outline');
			}

			// Check if the current URL matches the selected tournament's page
			if (currentUrl === `/tournaments/tournament/${tournament.id}`) {
				// get tournament by id again
				this.getTournamentById(tournament.id);
			}
		});

		this._tournamentSignalService.youLostTournament.subscribe((tournament: TournamentModel) => {
			console.log('🟢 AppTournamentService: youLostTournament:', tournament);

			// Store the current URL for later comparison
			const currentUrl = this._router.url;

			// Update the tournament result
			this.allDataSubject.forEach((subject) => {
				this.updateTournamentResult(subject, tournament.id, TournamentResultEnum.Loser);
			});

			// If the current page is not the tournament's page, show a toast notification with a link to view the tournament
			if (currentUrl !== `/tournaments/tournament/${tournament.id}`) {
				this._toastService.error(`You Lost the <b>${tournament.title}</b> Tournament.`, 'alert-outline');
			}

			// Check if the current URL matches the selected tournament's page
			if (currentUrl === `/tournaments/tournament/${tournament.id}`) {
				// get tournament by id again
				this.getTournamentById(tournament.id);
			}
		});
	}

	// #region Private Methods
	private getUpcomingTournaments(pageNumber: number, pageSize: number): void {
		// this.resetStates();

		this._tournamentService.getTournamentList({ loadUpcomingEvents: true, pageNumber: pageNumber, pageSize: pageSize }).subscribe({
			next: (response: any) => {
				const tournaments = response.value;
				if (tournaments.length) {
					const filteredTournaments: Array<TournamentModel> = this.filterTournamentsByStatus(tournaments, [
						TournamentStatusEnum.Canceled,
						TournamentStatusEnum.Ended,
					]);
					const groupedTournaments: Array<GroupedTournamentModel> = this.groupTournamentsByDate(filteredTournaments);
					// Ascending Sort
					groupedTournaments.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf());
					// Descending Sort
					// groupedTournaments.sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());

					// this.setStorageItem(AppConfig.upcomingTournaments, groupedTournaments);
					this.upcomingTournamentsDataSubject.next(groupedTournaments);
				} else {
					// this.setStorageItem(AppConfig.upcomingTournaments, []);
					this.upcomingTournamentsDataSubject.next([]);
				}
			},
			error: (error: any) => {
				this._toastService.error('Faild to Get Upcoming Tournaments!', 'alert-outline');
				throw new Error('Faild to Get Upcoming Tournaments!');
			},
		});
	}

	private getMineTournaments(pageNumber: number, pageSize: number): void {
		// this.resetStates();

		this._tournamentService.getTournamentList({ loadOnlyMyTournaments: true, pageNumber: pageNumber, pageSize: pageSize }).subscribe({
			next: (response: any) => {
				const tournaments = response.value;
				if (tournaments.length) {
					let filteredTournaments: Array<TournamentModel> = this.filterTournamentsBySubscriptionStatus(tournaments);
					filteredTournaments = this.filterTournamentsByStatus(tournaments, [
						TournamentStatusEnum.Canceled,
						TournamentStatusEnum.Ended,
					]);
					const groupedTournaments: Array<GroupedTournamentModel> = this.groupTournamentsByDate(filteredTournaments);
					// Ascending Sort
					groupedTournaments.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf());
					// Descending Sort
					// groupedTournaments.sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());

					// this.setStorageItem(AppConfig.mineTournaments, groupedTournaments);
					this.mineTournamentsDataSubject.next(groupedTournaments);
				} else {
					// this.setStorageItem(AppConfig.mineTournaments, []);
					this.mineTournamentsDataSubject.next([]);
				}
			},
			error: (error: any) => {
				this._toastService.error('Faild to Get Mine Tournaments!', 'alert-outline');
				throw new Error('Faild to Get Mine Tournaments!');
			},
		});
	}

	private getAllTournaments(pageNumber: number, pageSize: number): void {
		// this.resetStates();

		this._tournamentService.getTournamentList({ pageNumber: pageNumber, pageSize: pageSize }).subscribe({
			next: (response: any) => {
				const tournaments = response.value;
				if (tournaments.length) {
					const filteredTournaments: Array<TournamentModel> = this.filterTournamentsByStatus(tournaments, [
						TournamentStatusEnum.Canceled,
						TournamentStatusEnum.Ended,
					]);
					const groupedTournaments: Array<GroupedTournamentModel> = this.groupTournamentsByDate(filteredTournaments);
					// Ascending Sort
					groupedTournaments.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf());
					// Descending Sort
					// groupedTournaments.sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());

					// this.setStorageItem(AppConfig.allTournaments, groupedTournaments);
					this.allTournamentsDataSubject.next(groupedTournaments);
				} else {
					// this.setStorageItem(AppConfig.allTournaments, []);
					this.allTournamentsDataSubject.next([]);
				}
			},
			error: (error: any) => {
				this._toastService.error('Faild to Get All Tournaments!', 'alert-outline');
				throw new Error('Faild to Get All Tournaments!');
			},
		});
	}

	private updateTournamentStatus(subject: BehaviorSubject<Array<GroupedTournamentModel>>, tournamentId: string, newStatus: TournamentStatusEnum): void {
		// Create an updated array with immutability
		const updatedGroupedTournaments = subject.getValue().map((group) => ({
			...group,
			tournaments: group.tournaments.map((tournament) =>
				tournament.id === tournamentId ? { ...tournament, status: newStatus } : tournament,
			),
		}));

		// Emit the updated array
		subject.next(updatedGroupedTournaments);
	}

	private updateTournamentSubscribers(subject: BehaviorSubject<Array<GroupedTournamentModel>>, tournamentId: string, newSubscribersCount: number): void {
		// Create an updated array with immutability
		const updatedGroupedTournaments = subject.getValue().map((group) => ({
			...group,
			tournaments: group.tournaments.map((tournament) =>
				tournament.id === tournamentId ? { ...tournament, numberOfSubscribers: newSubscribersCount } : tournament,
			),
		}));

		// Emit the updated array
		subject.next(updatedGroupedTournaments);
	}

	private updateTournamentResult(
		subject: BehaviorSubject<Array<GroupedTournamentModel>>,
		tournamentId: string,
		tournamentResult: TournamentResultEnum,
	): void {
		const updatedGroupedTournaments = subject.getValue().map((group) => ({
			...group,
			tournaments: group.tournaments.map((tournament) => (tournament.id === tournamentId ? { ...tournament, tournamentResult } : tournament)),
		}));

		subject.next(updatedGroupedTournaments);
	}

	private updateTournamentSubscriptionStatus(
		subject: BehaviorSubject<Array<GroupedTournamentModel>>,
		tournamentId: string,
		isUserSubscribed: boolean,
	): void {
		const currentUrl = this._router.url;

		// Create an updated array with immutability
		const updatedGroupedTournaments = subject.getValue().map((group) => ({
			...group,
			tournaments: group.tournaments.map((tournament) =>
				tournament.id === tournamentId ? { ...tournament, isUserSubscribed: isUserSubscribed } : tournament,
			),
		}));

		// Emit the updated list to the BehaviorSubject
		subject.next(updatedGroupedTournaments);

		// Check if the current URL matches the selected tournament's page
		if (currentUrl === `/tournaments/tournament/${tournamentId}`) {
			// Create a shallow copy of the selectedTournament and update the subscription status
			const updatedTournament = {
				...this.selectedTournament.getValue(),
				isUserSubscribed: isUserSubscribed,
			};

			// Emit the updated tournament object
			this.selectedTournament.next(updatedTournament);
		}
	}

	private filterTournamentsBySubscriptionStatus(tournaments: Array<TournamentModel>): Array<TournamentModel> {
		return tournaments.filter((tournament) => tournament.isUserSubscribed);
	}

	private filterTournamentsByStatus(tournaments: Array<TournamentModel>, statuses: Array<TournamentStatusEnum>): Array<TournamentModel> {
		return tournaments.filter((tournament) => !statuses.includes(tournament.status));
	}

	private groupTournamentsByDate(tournaments: Array<TournamentModel>) {
		const groups = tournaments.reduce((groups: any, tournament: TournamentModel) => {
			const date = tournament.startsAt.split('T')[0];
			if (!groups[date]) {
				groups[date] = [];
			}
			groups[date].push(tournament);
			return groups;
		}, {});

		const groupArrays = Object.keys(groups).map((date) => {
			return {
				date,
				tournaments: groups[date],
			};
		});

		return groupArrays;
	}

	private resetStates() {
		this.upcomingTournamentsDataSubject.next([]);
		this.mineTournamentsDataSubject.next([]);
		this.allTournamentsDataSubject.next([]);
		this.selectedTournament.next(new TournamentModel());
	}

	private async setStorageItem(key: string, value: any): Promise<any> {
		await this._storage.set(key, value);
	}

	private async getStorageItem(key: string): Promise<any> {
		return await this._storage.get(key);
	}

	private async removeStorageItem(key: string): Promise<any> {
		return await this._storage.remove(key);
	}
	// #endregion

	/**
	 * init
	 */
	public init() {
		this.getUpcomingTournaments(this.upcomingTournamentsPageNumber, this.pageSize);
		this.getMineTournaments(this.mineTournamentsPageNumber, this.pageSize);
		this.getAllTournaments(this.allTournamentsPageNumber, this.pageSize);

		console.log('✅ AppTournamentService Initialized.');
	}

	/**
	 * getTournamentById
	 */
	public getTournamentById(tournamentId: string): void {
		// this._layoutService.loading.next({ isShow: true, title: 'Loading Tournament...', type: FullScreenLoadingTypeEnum.Default, isShowTryAgainButton: false });
		this.selectedTournament.next(new TournamentModel());

		this._tournamentService.getTournamentById(tournamentId).subscribe({
			next: (response: any) => {
				if (response.isSuccess) {
					this.selectedTournament.next(response.value);
					// this._layoutService.loading.next({ isShow: false, title: '', type: FullScreenLoadingTypeEnum.Default, isShowTryAgainButton: false });
				} else {
					// Show error toast with the first error message
					this._toastService.error(response.errors[0], 'alert-outline');
				}
			},
			error: (error: any) => {
				// this._layoutService.loading.next({ isShow: false, title: '', type: FullScreenLoadingTypeEnum.Default, isShowTryAgainButton: false });
				this._toastService.error('Faild to Get Tournament!', 'alert-outline');
				throw new Error('Faild to Get Tournament!');
			},
		});
	}

	/**
	 * subscribeTournament
	 */
	public subscribeTournament(tournamentId: string): Observable<boolean> {
		return this._tournamentService.subscribeTournament(tournamentId).pipe(
			tap({
				next: (response) => {
					if (response.isSuccess) {
						// Update the tournament status in all subjects
						this.allDataSubject.forEach((subject) => {
							this.updateTournamentSubscriptionStatus(subject, tournamentId, true);
						});

						// Show success toast
						this._toastService.success(response.successMessage, 'checkmark-outline');
					} else {
						// Show error toast with the first error message
						this._toastService.error(response.errors[0], 'alert-outline');
					}
				},
				error: () => {
					this._toastService.error('An error occurred', 'alert-outline');
				},
			}),
			map((response) => response.isSuccess), // Return success status to the caller
			catchError(() => of(false)), // Return false in case of an error
		);
	}

	/**
	 * unsubscribeTournament
	 */
	public unsubscribeTournament(tournamentId: string): Observable<boolean> {
		return this._tournamentService.unsubscribeTournament(tournamentId).pipe(
			tap({
				next: (response) => {
					if (response.isSuccess) {
						// Update the tournament status in all subjects
						this.allDataSubject.forEach((subject) => {
							this.updateTournamentSubscriptionStatus(subject, tournamentId, false);
						});

						// Show success toast
						this._toastService.success(response.successMessage, 'checkmark-outline');
					} else {
						// Show error toast with the first error message
						this._toastService.error(response.errors[0], 'alert-outline');
					}
				},
				error: () => {
					this._toastService.error('An error occurred', 'alert-outline');
				},
			}),
			map((response) => response.isSuccess), // Return success status to the caller
			catchError(() => of(false)), // Return false in case of an error
		);
	}

	/**
	 * nextUpcomingTournamentsPage
	 */
	public nextUpcomingTournamentsPage() {
		this.upcomingTournamentsPageNumber++;
	}

	/**
	 * nextMineTournamentsPage
	 */
	public nextMineTournamentsPage() {
		this.mineTournamentsPageNumber++;
	}

	/**
	 * nextAllTournamentsPage
	 */
	public nextAllTournamentsPage() {
		this.allTournamentsPageNumber++;
	}
}
