import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-text-button',
	templateUrl: './text-button.component.html',
	styleUrls: ['./text-button.component.scss'],
	standalone: true,
	imports: [CommonModule],
})
export class TextButtonComponent implements OnInit {
	@Input() public loading: boolean = false;
	@Input() public text: string = 'button';
	@Input() public mode: 'shine' | 'simple' | 'solid' = 'simple';
	@Input() public padding: 'fix' | 'auto' = 'auto';
	@Input() public color: 'red' | 'green' | 'blue' | 'white' | 'primary' = 'white';
	constructor() {}

	ngOnInit() {}
}
