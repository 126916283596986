import { Injectable, OnDestroy } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Subject, Subscription, concatMap } from 'rxjs';
import { Router } from '@angular/router';
import { ToastDataModel, ToastAction } from 'src/app/core/models/toast.model';
import { ToastEnum } from 'src/app/core/enums/toast.enum';

@Injectable({
	providedIn: 'root',
})
export class ToastService implements OnDestroy {
	private toastQueue: Subject<ToastDataModel> = new Subject<ToastDataModel>();
	private toastSubscription!: Subscription;

	public toastButtons = [
		// {
		// 	icon: 'close-outline',
		// 	role: 'cancel',
		// 	handler: () => {},
		// },
	];

	constructor(private _toastController: ToastController, private router: Router) {
		this.toastSubscription = this.toastQueue.pipe(concatMap((toastData) => this.presentToast(toastData))).subscribe();
	}

	ngOnDestroy(): void {
		this.toastSubscription.unsubscribe();
	}

	// Methods to enqueue toasts
	public success(message: string, icon?: string, duration: number = 3000, action?: ToastAction) {
		this.enqueueToast({ message: message || 'Success.', type: ToastEnum.success, icon, duration, action });
	}

	public error(message: string, icon?: string, duration: number = 3000, action?: ToastAction) {
		this.enqueueToast({ message: message || 'Error!', type: ToastEnum.error, icon, duration, action });
	}

	public warning(message: string, icon?: string, duration: number = 3000, action?: ToastAction) {
		this.enqueueToast({ message: message || 'Warning!', type: ToastEnum.warning, icon, duration, action });
	}

	public info(message: string, icon?: string, duration: number = 3000, action?: ToastAction) {
		this.enqueueToast({ message: message || 'Info!', type: ToastEnum.info, icon, duration, action });
	}

	// Enqueues the toast data to be displayed
	private enqueueToast(toastData: ToastDataModel) {
		this.toastQueue.next(toastData);
	}

	// Presents the toast and waits for it to be dismissed before showing the next
	private async presentToast(toastData: ToastDataModel) {
		const toastButtons = toastData.action
			? [
					{
						text: toastData.action.buttonText || 'Go',
						handler: () => {
							// Call the dynamic handler (could be a routing function or custom logic)
							if (toastData.action?.handler) {
								toastData.action.handler(this.router);
							} else {
								console.warn('Toast action handler is not defined.');
							}
						},
					},
					...this.toastButtons,
			  ]
			: this.toastButtons;

		const toast = await this._toastController.create({
			message: toastData.message,
			duration: toastData.duration,
			position: 'top',
			mode: 'ios',
			// translucent: true,
			swipeGesture: 'vertical',
			layout: 'baseline',
			cssClass: `custom-toast-${toastData.type} below-header`,
			buttons: toastButtons,
			...(toastData.icon !== '' && {icon: toastData.icon}),
		});

		await toast.present();
		await toast.onDidDismiss(); // Wait for the toast to be dismissed
	}
}
