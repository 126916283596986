import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class BaseProfileApiService {
	public constructor(private http: HttpClient) {}

	public get<T>(path: string, params = {}): Observable<T> {
		return this.http.get<T>(`${environment.PROFILE_API}${path}`, { params });
	}

	public put<T>(path: string, body = {}): Observable<T> {
		return this.http.put<T>(`${environment.PROFILE_API}${path}`, JSON.stringify(body));
	}

	public post<T>(path: string, body = {}, responseType = {}): Observable<T> {
		return this.http.post<T>(`${environment.PROFILE_API}${path}`, JSON.stringify(body), responseType);
	}

	public postFileData<T>(path: string, body: FormData): Observable<HttpEvent<T>> {
		return this.http.post<T>(`${environment.PROFILE_API}${path}`, body, { reportProgress: true, observe: 'events' });
	}

	public delete(path: any, options = {}): Observable<any> {
		const httpParams = new HttpParams();
		httpParams.set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, DELETE');
		const optionss = { params: httpParams };
		return this.http.delete(`${environment.PROFILE_API}${path}`);
	}
}
