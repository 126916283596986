import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IMainResponse } from 'src/app/core/models/api.model';
import { BaseProfileApiService } from '../base/base-profile-api.service';
import { IBlockedUser, IRegisterFCM } from 'src/app/core/models/user.model';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private readonly controller: string = 'user';
	public constructor(private _baseProfileApiService: BaseProfileApiService) {}

	public searchUser(term: string, pageNumber: number): Observable<IMainResponse<any>> {
		return this._baseProfileApiService.get<IMainResponse<any>>(`${this.controller}/search?term=${term}&pageNumber=${pageNumber}`);
	}

	public blockUser(userId: string): Observable<IMainResponse<any>> {
		return this._baseProfileApiService.put<IMainResponse<any>>(`${this.controller}/block/${userId}`);
	}

	public unblockUser(userId: string): Observable<IMainResponse<any>> {
		return this._baseProfileApiService.put<IMainResponse<any>>(`${this.controller}/unblock/${userId}`);
	}

	public blockedlist(): Observable<IMainResponse<Array<IBlockedUser>>> {
		return this._baseProfileApiService.get<IMainResponse<Array<IBlockedUser>>>(`${this.controller}/blacklist`);
	}

	public registerFCMToken(registerFCM: IRegisterFCM): Observable<IMainResponse<boolean>> {
		return this._baseProfileApiService.put<IMainResponse<boolean>>(`${this.controller}/register_fcm_token`, registerFCM);
	}
	public unregisterFCMToken(fcmToken: string): Observable<IMainResponse<boolean>> {
		return this._baseProfileApiService.put<IMainResponse<boolean>>(`${this.controller}/unregister_fcm_token`, { token: fcmToken });
	}
}
