export const environment = {
	production: true,
	baseAuthApiUrl: 'https://auth.tradingbattle.me',
	OIDC_ISSUER: 'https://auth.tradingbattle.me',
	OIDC_CLIENT_ID: 'TradingGameCenter.App',
	OIDC_REDIRECT_URI: 'https://app.tradingbattle.me/auth-callback',
	POST_LOGOUT_REDIRECT_URL: 'https://app.tradingbattle.me',
	
	// identity
	IDENTITY_API: 'https://auth.tradingbattle.me/api/v1/',

	// Profile
	PROFILE_API: 'https://profile.tradingbattle.me/api/v1/',
	PROFILE_HUB: 'https://profile.tradingbattle.me/hubs/notifications',

	// Engine
	ENGINE_API: 'https://engine.tradingbattle.me/api/v1/',
	ENGINE_HUB: 'https://engine.tradingbattle.me/hubs/engine',

	// Notification
	NOTIFICATIONS_HUB: 'https://profile.tradingbattle.me/hubs/notifications/',

	// Chat
	CHAT_API: 'https://chat.tradingbattle.me/api/v1/',
	CHAT_HUB: 'https://chat.tradingbattle.me/hubs/chat',

	// Accounting
	ACCOUNTING_API: 'https://accounting.tradingbattle.me/api/v1/',

	// Firebase Config
	firebaseConfig: {
		apiKey: 'AIzaSyAlg4Eewz-HMki5dlBW6Q3agIsGtAdlGBU',
		authDomain: 'tradingbattle-c90e8.firebaseapp.com',
		projectId: 'tradingbattle-c90e8',
		storageBucket: 'tradingbattle-c90e8.appspot.com',
		messagingSenderId: '688259822689',
		appId: '1:688259822689:web:88b9dc2e67cb12296fe620',
		measurementId: 'G-KFBRWVZ9MJ',
	},
	vapidKey: 'BNVv3I9W50-nXsb_bfKqMOBmdeAN628duce3KUAx-x_hak_9Vjsx8sS6IpSZBSatnFnD6hcYSl0I3cwkRkD1Bc8',
};
