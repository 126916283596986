import { NgModule } from '@angular/core';
import { loginGuard } from './core/guards/login.guard';
import { introGuard } from './core/guards/intro.guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FullScreenLoadingComponent } from './modules/shared/full-screen-loading/full-screen-loading.component';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./modules/tabs/tabs.module').then((m) => m.TabsPageModule),
	},
	{
		path: 'play',
		loadChildren: () => import('./modules/games/games.module').then((m) => m.GamesModule),
	},
	{
		path: 'intro',
		loadChildren: () => import('./modules/intro/intro.module').then((m) => m.IntroModule),
		canMatch: [introGuard],
	},
	{
		path: 'auth',
		loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
		canMatch: [loginGuard],
	},
	{
		path: 'auth-callback',
		component: FullScreenLoadingComponent,
		canMatch: [loginGuard],
	},
	{
		path: '**',
		redirectTo: '/home',
		pathMatch: 'full',
	},
];
@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
