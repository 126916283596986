export enum GameTypeEnum {
	Duel,
	Tournament,
}

export enum BattleTypeEnum {
	Duel = 'Duel',
	Tournament = 'Tournament',
}

export enum DepositTypeEnum {
	USDT = 'USDT',
	TBT = 'TBT',
}

export enum GameStatusEnum {
	Running = 'Running',
	Ended = 'Ended',
	Canceled = 'Canceled',
}

export enum SelectedChoiceEnum {
	Up = 'Up',
	None = 'None',
	Down = 'Down',
	Canceled = 'Canceled',
}

export enum BattleResultEnum {
	Winner = 'Winner',
	None = 'None',
	Loser = 'Loser',
	Draw = 'Draw',
}

export enum KlineIntervalEnum {
	OneSecond = 'OneSecond',
	OneMinute = 'OneMinute',
	ThreeMinutes = 'ThreeMinutes',
	FiveMinutes = 'FiveMinutes',
	FifteenMinutes = 'FifteenMinutes',
	ThirtyMinutes = 'ThirtyMinutes',
	OneHour = 'OneHour',
	TwoHour = 'TwoHour',
	FourHour = 'FourHour',
	SixHour = 'SixHour',
	EightHour = 'EightHour',
	TwelveHour = 'TwelveHour',
	OneDay = 'OneDay',
	ThreeDay = 'ThreeDay',
	OneWeek = 'OneWeek',
	OneMonth = 'OneMonth',
}

export enum TickerSymbolEnum {
	TRXUSDT = 'TRXUSDT',
}

export enum TickerSymbolNameEnum {
	TRXUSDT = 'TRX - USDT',
}

export enum TickerTypeEnum {
	Crypto = 'Crypto',
}
