import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../../../environments/environment';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, take, finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/api/auth/auth.service';
import { LayoutService } from '../services/app/layout-service/layout.service';
import { jwtDecode } from 'jwt-decode';
import { ITokenObject } from '../models/auth/token-object.model';

import { EngineSignalService } from '../services/signal/engine-signal-service/engine-signal.service';
import { ProfileSignalService } from '../services/signal/profile-signal-service/profile-signal.service';
import { ChatSignalService } from '../services/signal/chat-service/chat.service';
import { FullScreenLoadingTypeEnum } from '../enums/app-full-screen-loading.enum';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
	private _isRefreshing: boolean = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		private _router: Router,
		private _oidcSecurityService: OidcSecurityService,
		private _authService: AuthService,
		private _layoutService: LayoutService,
		private _chatSignalService: ChatSignalService,
		private _profileSignalService: ProfileSignalService,
		private _engineSignalService: EngineSignalService,
	) {}

	public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		// Convert async/await logic to a Promise and wrap it in Observable
		return from(this.handleRequest(request, next));
	}

	// Convert the intercept logic into async
	private async handleRequest(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
		try {
			// Attach token if available
			const newRequest = await this.requestMerged(request);

			// Handle the request and wait for response
			const response = await next.handle(newRequest).toPromise();

			// Check if response is undefined and throw an error if necessary
			if (!response) {
				throw new Error('Request handling resulted in undefined.');
			}

			return response;
		} catch (error) {
			// Handle error cases
			if (error instanceof HttpErrorResponse) {
				switch (error.status) {
					case 401:
						return await this.handle401Error(request, next);
					case 402:
						console.log('▶▶▶▶ CASE 402 ◀◀◀◀');
						this._router.navigate(['/auth']);
						break;
					case 404:
					case 406:
					case 429:
						console.log('▶▶▶▶ CASE 429 ◀◀◀◀');
						throw error;
					case 500:
						throw error;
					case 400:
						throw error;
					default:
						// return this.handleError(error.error.Message, request);
						throw '';
				}
			}
			throw error;
		}
	}

	private async requestMerged(request: HttpRequest<any>): Promise<HttpRequest<any>> {
		let result = this.attachTokenToRequest(request);
		return result;
	}

	private async attachTokenToRequest(request: HttpRequest<any>): Promise<HttpRequest<any>> {
		if (request.url === environment.OIDC_ISSUER + '/connect/token') return request;

		let headersConfig: any = {
			'Content-Type': 'application/json',
			'Accept': 'text/plain',
		};

		if (request.body instanceof FormData) {
			headersConfig = {};
		}

		request = request.clone({
			setHeaders: { ...headersConfig },
		});

		const access_token = await this._authService.getAccessToken();
		if (access_token) {
			request = request.clone({
				setHeaders: { Authorization: `Bearer ${access_token}` },
			});
		}

		return request;
	}

	private handleError(error: HttpErrorResponse, request: HttpRequest<any>): Observable<never> {
		let myRequest: HttpRequest<any> = request.clone();
		if (myRequest.headers.has('handleError')) {
		}
		return throwError(() => error);
	}

	// Handle 401 error (async/await style)
	private async handle401Error(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
		this._layoutService.loading.next({
			isShow: true,
			title: 'Refreshing User Session...',
			type: FullScreenLoadingTypeEnum.Default,
			isShowTryAgainButton: false,
		});

		if (!this._isRefreshing) {
			this._isRefreshing = true;
			this.refreshTokenSubject.next(null);

			try {
				// Force refresh session and ensure response is valid
				const response = await this._oidcSecurityService.forceRefreshSession().toPromise();

				// Add null/undefined check for response
				if (!response || !response.accessToken) {
					throw new Error('Failed to refresh token: Response is undefined or invalid.');
				}

				console.log('▶▶ Token has been refreshed!');

				this._isRefreshing = false;

				// Decode and store new access token
				const tokenObject: ITokenObject = jwtDecode(response.accessToken);
				this._authService.setAccessToken(response.accessToken);
				this._authService.setUserRole(tokenObject.role);
				this._authService.getUserData();

				this._chatSignalService.rebuildAndStartConnection();
				this._profileSignalService.rebuildAndStartConnection();
				this._engineSignalService.rebuildAndStartConnection();

				this._layoutService.loading.next({
					isShow: false,
					title: '',
					type: FullScreenLoadingTypeEnum.Default,
					isShowTryAgainButton: false,
				});

				// Retry the failed request with the new token
				const newRequest = await this.requestMerged(request);
				const result = await next.handle(newRequest).toPromise();

				// Assert that result is not undefined
				if (!result) {
					throw new Error('Request handling resulted in undefined.');
				}

				return result;
			} catch (error) {
				console.error('▶ Error while refreshing the token: ◀', error);
				console.error(':::: Test logging ::::', error);
				this._authService.logout();

				this._layoutService.loading.next({
					isShow: false,
					title: '',
					type: FullScreenLoadingTypeEnum.Default,
					isShowTryAgainButton: false,
				});
				this._isRefreshing = false;

				// Handle token refresh error, redirect to login or show error message
				if (
					(error as ResponseError).status === 404 ||
					(error as ResponseError).status === 402 ||
					(error as ResponseError).status === 400
				) {
					this._authService.logout();
				}

				// Re-throw the error to ensure the return type is not undefined
				throw error;
			} finally {
				this._isRefreshing = false;
			}
		} else {
			// If already refreshing, wait for the new token to arrive via refreshTokenSubject
			return new Promise((resolve, reject) => {
				this.refreshTokenSubject
					.pipe(
						filter((token) => token !== null),
						take(1),
					)
					.subscribe(async () => {
						const newRequest = await this.requestMerged(request);
						next.handle(newRequest)
							.toPromise()
							.then((result) => {
								// Ensure the result is valid before resolving
								if (!result) {
									reject(new Error('Request handling resulted in undefined.'));
								} else {
									resolve(result);
								}
							})
							.catch(reject);
					});
			});
		}
	}
}

interface ResponseError extends Error {
	status?: number;
}
