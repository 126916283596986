<ion-header mode="md">
	<ion-toolbar>
		<ion-title class="text-center">Battle Invitation</ion-title>

		<!-- <ion-button slot="end" fill="clear" shape="round" color="light" class="mx-2" (click)="cancel()">
				<ion-icon slot="icon-only" name="close-outline"></ion-icon>
			</ion-button> -->
	</ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
	<div class="d-flex flex-row justify-content-between align-items-center mb-3 font-light">
		<ion-select
			labelPlacement="stacked"
			label="Disable Receive Invitation For:"
			[value]="disableReceiveInvitation.time"
			interface="popover"
			(ionChange)="handleSelectChange($event)"
		>
			<ion-icon slot="start" name="timer-outline"></ion-icon>
			<ion-select-option [value]="0">None</ion-select-option>
			<ion-select-option [value]="5">5 Minutes</ion-select-option>
			<ion-select-option [value]="10">10 Minutes</ion-select-option>
			<ion-select-option [value]="15">15 Minutes</ion-select-option>
		</ion-select>
	</div>

	<ion-button expand="block" shape="round" size="small" (click)="rejectAllInvitations()" class="mb-2">Reject All</ion-button>
	@for (invitation of receivedInvitationList; track $index) {
	<div class="d-flex flex-column justify-content-center align-items-between gap-2 mb-2 p-2 rounded app-card-background font-light">
		<div class="d-flex flex-row justify-content-between align-items-center">
			<div class="d-flex flex-row justify-content-center align-items-center">
				<app-avatar [imageUrl]="invitation.sender.avatarUrl" size="32" shape="circle" [rotateBorder]="true" />
				<div class="mx-2">{{ invitation.sender.userName }}</div>
			</div>
			<!-- <countdown [config]="invitationCountdownConfig[$index]" /> -->
			<!-- {{ countdownHandler(invitation.invitation.remainingExpirationTime) }} -->
			<!-- <app-countdown-timer [milliseconds]="invitation.invitation.remainingExpirationTime" (timerComplete)="onTimerComplete()"></app-countdown-timer> -->
			@if (getRemainingSeconds(invitation.expiredAt) > 0) {

			<app-circular-countdown-timer
				class="ms-auto"
				[totalSeconds]="getTotalSeconds(invitation.createdAt, invitation.expiredAt)"
				[remainingTime]="getRemainingSeconds(invitation.expiredAt)"
				[showTimeOnly]="false"
				[shape]="'stroke'"
				[dynamicColorChange]="true"
				[backgroundColor]="'var(--color-B2)'"
				[foregroundColor]="'var(--app-color-success)'"
				[textColor]="'var(--app-color-white)'"
				[fontSize]="14"
				[strokeWidth]="5"
				[diameter]="50"
				timeDisplayPosition="center"
				(countdownCompleted)="onCountdownCompleted()"
				[minParticleSize]="1"
				[maxParticleSize]="5"
				[particleColor]="'var(--app-color-success)'"
				[particleSpread]="10"
			/>
			} @else {
			<div class="app-fade-in-out">Canceling...</div>
			}
		</div>
		<div class="d-flex flex-row justify-content-around align-items-center px-3">
			<div class="d-flex flex-column justify-content-center align-items-center">
				@if (invitation.depositType === 'USDT') {
				<img class="app-circle-32" src="assets/cryptocurrency-icons/svg/color/usdt.svg" alt="USDT Icon" />
				} @if (invitation.depositType === 'TBT') {
				<img class="app-circle-32" src="assets/images/tbt-coin.png" alt="TBT Coin Icon" />
				}
				<div>{{ invitation.depositType }}</div>
			</div>

			<div class="d-flex flex-column justify-content-center align-items-center">
				<!-- <div>Value</div> -->
				<h2>@if (invitation.depositType === 'USDT') {$}{{ invitation.depositAmoutRange.max }}</h2>
			</div>
		</div>
		<div class="d-flex flex-row justify-content-between align-items-center">
			<ion-button
				*ngIf="getRemainingSeconds(invitation.expiredAt) > 0"
				size="small"
				(click)="reject(invitation.id)"
				color="light"
				class="w-50"
				>Reject</ion-button
			>
			<ion-button *ngIf="getRemainingSeconds(invitation.expiredAt) > 0" size="small" (click)="accept(invitation.id)" class="w-50"
				>Accept</ion-button
			>
		</div>
	</div>
	}
</ion-content>
