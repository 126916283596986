import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserPlanEnum } from 'src/app/core/enums/user-plan.enum';
import { UserRoleEnum } from 'src/app/core/enums/user-role.enum';
import { IUserData } from 'src/app/core/models/auth/user-data.model';
import { ITokenObject } from 'src/app/core/models/auth/token-object.model';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private AccessToken: string = String('');
	private RefreshToken: string = String('');
	private isAuthenticated: boolean = false;
	public userRole: UserRoleEnum = UserRoleEnum.unknown;
	public emptyUser: IUserData = {
		sub: '',
		email: '',
		name: '',
		plan: UserPlanEnum.Basic,
		avatar: '',
		role: UserRoleEnum.unknown,
		client_id: '',
	};
	public userData$: BehaviorSubject<IUserData> = new BehaviorSubject<IUserData>(this.emptyUser);

	public constructor(private _oidcSecurityService: OidcSecurityService) {
		// Subscribe to token events
		this._oidcSecurityService.checkSessionChanged$.subscribe((hasChanged) => {
			if (hasChanged) {
			}
		});
	}

	public login(): void {
		this._oidcSecurityService.authorize();
	}

	public logout(): void {
		this._oidcSecurityService.logoffAndRevokeTokens().subscribe((result) => {});
		this.setIsAuthenticated(false);
	}

	public getUserData(): void {
		let auth = localStorage.getItem('0-TradingGameCenter.App');
		// let auth = sessionStorage.getItem('0-TradingGameCenter.App');
		if (auth) {
			let authObj = JSON.parse(auth);
			let accessToken = authObj?.authnResult?.access_token;
			let refresh_token = authObj?.authnResult?.refresh_token;

			if (accessToken !== null && accessToken !== undefined && accessToken !== '') {
				let TokenObject: ITokenObject = jwtDecode(accessToken);
				let data: IUserData = {
					sub: TokenObject.sub,
					email: TokenObject.email,
					name: TokenObject.name,
					plan: TokenObject.plan,
					avatar: TokenObject.avatar,
					role: TokenObject.role,
					client_id: TokenObject.client_id,
				};

				this.userData$.next(data);
				this.AccessToken = accessToken;
			}
			if (refresh_token !== null && refresh_token !== undefined && refresh_token !== '') {
				this.setRefreshToken(refresh_token);
			}
		}
	}

	// public refreshToken(): void {
	// 	// this._oidcSecurityService.refreshToken().subscribe((result) => console.log(result));
	// }

	public setUserRole(role: UserRoleEnum): void {
		this.userRole = role;
	}

	public getUserRole(): UserRoleEnum {
		return this.userRole;
	}

	public setAccessToken(accessToken: string): void {
		this.AccessToken = accessToken;
		this.setIsAuthenticated(true);
	}

	public async getAccessToken(): Promise<string> {
		let result: string = '';

		// Check if access token is already cached in the service
		if (this.AccessToken !== null && this.AccessToken !== '' && this.AccessToken !== undefined) {
			return this.AccessToken;
		}

		// Simulate waiting for session storage to be populated
		const auth = await this.getAuthFromSessionStorage(); // Wait for session storage read

		if (auth) {
			let authObj = JSON.parse(auth);
			let accessToken = authObj?.authnResult?.access_token;

			if (accessToken !== null && accessToken !== undefined && accessToken !== '') {
				this.AccessToken = accessToken;
				result = accessToken;
			}
		}

		return result;
	}

	// Helper method to get data from sessionStorage
	private getAuthFromSessionStorage(): Promise<string | null> {
		return new Promise((resolve) => {
			// CustomOidcStorageService;
			const auth = localStorage.getItem('0-TradingGameCenter.App');
			// const auth = sessionStorage.getItem('0-TradingGameCenter.App');
			resolve(auth); // Resolve the session storage result
		});
	}

	public setRefreshToken(refreshToken: string): void {
		this.RefreshToken = refreshToken;
	}

	public getRefreshToken(): string {
		return this.RefreshToken;
	}

	private setIsAuthenticated(value: boolean): void {
		this.isAuthenticated = value;
	}

	public getIsAuthenticated(): boolean {
		return this.isAuthenticated;
	}
}
