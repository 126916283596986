import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
	selector: 'app-avatar',
	standalone: true,
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss'],
	imports: [CommonModule],
})
export class AvatarComponent implements OnInit, OnChanges {
	@Input() public imageUrl: string = String('');
	@Input() public rotateBorder: boolean = false;
	@Input() public solidBorder: boolean = false;
	@Input() public boxShadowAnimation: boolean = false;
	@Input() public skeleton: boolean = false;
	// FIXME: type 👇
	@Input() public skeletonType: 'default' | 'suffle-image' = 'default';
	@Input() public size: '32' | '50' | '64' | '80' | '100' | '128' | '150' | '200' = '64';
	@Input() public borderWidth: '1' | '2' | '3' | '4' | '5' = '1';
	@Input() public shape: 'circle' | 'rounded-square' = 'rounded-square';
	@Input() public boxShadowColor: 'primary' | 'secondary' | 'success' | 'danger' | 'info' | 'warning' = 'primary';
	public shuffleIndex: number = 0;

	public suffleImageSubscription: Subscription = Subscription.EMPTY;
	public randomAvatars = [
		'assets/images/avatars/avatar-01.jpg',
		'assets/images/avatars/avatar-02.jpg',
		'assets/images/avatars/avatar-03.jpg',
		'assets/images/avatars/avatar-04.jpg',
		'assets/images/avatars/avatar-05.jpg',
		'assets/images/avatars/avatar-06.jpg',
		'assets/images/avatars/avatar-07.jpg',
		'assets/images/avatars/avatar-08.jpg',
		'assets/images/avatars/avatar-09.jpg',
		'assets/images/avatars/avatar-10.jpg',
		'assets/images/avatars/avatar-11.jpg',
		'assets/images/avatars/avatar-12.jpg',
	];

	public skeletonAvatar: string = String('assets/images/skeleton-avatar.jpg');
	public imageSource: string = String('');

	public constructor() {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes['skeleton'] && !changes['skeleton'].currentValue) {
			this.imageSource = this.imageUrl;
			this.skeletonType = 'default';
			this.suffleImageSubscription.unsubscribe();
		}
		if (changes['imageUrl']) {
			this.imageSource = changes['imageUrl'].currentValue;
		}
	}

	public ngOnInit(): void {
		if (this.shape === 'rounded-square') {
			this.rotateBorder = false;
		}
		if (this.skeleton) {
			if (this.skeletonType === 'suffle-image') {
				this.shuffleIndex = 0;
				this.suffleImageSubscription = interval(100).subscribe((val) => {
					if (this.shuffleIndex === this.randomAvatars.length - 1) {
						this.shuffleIndex = 0;
					}

					// this.imageSource = this.randomAvatars[shuffleIndex];
					this.shuffleIndex++;
				});
			} else {
				this.imageSource = this.skeletonAvatar;
			}
		} else {
			this.imageSource = this.imageUrl;
		}
	}
}
