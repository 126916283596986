import { DepositTypeEnum, GameStatusEnum, GameTypeEnum, KlineIntervalEnum } from '../enums/game.enum';
import { TournamentResultEnum, TournamentStatusEnum } from '../enums/tournament.enum';
import { BattleParticipantModel, BattleStartedBattleDto, TickerModel } from './engine-signal.model';
import { UserInfoModel } from './user.model';

export class TournamentListParamsModel {
	// status?: TournamentStatusEnum;
	search?: string = String('');
	loadOnlyMyTournaments?: boolean = false;
	loadUpcomingEvents?: boolean = false;
	pageNumber: number = 1;
	pageSize: number = 20;
}

export class TournamentModel {
	battles?: Array<TournamentBattleModel> = new Array<TournamentBattleModel>();
	title: string = String('');
	shortDescription: string = String('');
	fullDescription: string = String('');
	gapBetweenIterations: string = String('');
	gapBetweenIterationsInSeconds: number = 0;
	iconUrl: string = String('');
	coverUrl: string = String('');
	metaData: string = String('');
	order: number = 0;
	theme: string = String('');
	tickerId: string = String('');
	ticker: TickerModel = new TickerModel();
	interval: KlineIntervalEnum = KlineIntervalEnum.OneMinute;
	entranceAmount: number = 0;
	entranceTokenType: DepositTypeEnum = DepositTypeEnum.USDT;
	numberOfIterations: number = 0;
	currentIteration: number = 0;
	iterationDuration: string = String('');
	subscriptionPeriod: string = String('');
	subscriptionPeriodStartedAt: string = String('');
	startsAt: string = String('');
	minProfileLevel: number = 0;
	maxRanking: number = 0;
	minRankTitle: number = 0;
	minRankTitleEnum: number = 0;
	minNumberOfParticipants: number = 0;
	maxNumberOfParticipants: number = 0;
	status: TournamentStatusEnum = TournamentStatusEnum.Created;
	subscribers: Array<TournamentSubscriberModel> = new Array<TournamentSubscriberModel>();
	numberOfSubscribers: number = 0;
	isUserSubscribed: boolean = false;
	tournamentResult: TournamentResultEnum = TournamentResultEnum.Waiting;
	userHasActiveBattle: boolean = false;
	id: string = String('');
}

export class GroupedTournomentModel {
	date: string = String('');
	tournaments: Array<TournamentModel> = new Array<TournamentModel>();
}

export class TournamentBattleModel {
	tournamentId: string = String('');
	tournament: TournamentModel = new TournamentModel();
	battleDuration: number = 0;
	battleEndsAt: string = String('');
	createdAt: string = String('');
	depositAmount: number = 0;
	depositType: DepositTypeEnum = DepositTypeEnum.USDT;
	endPrice: number = 0;
	firstCancellationPeriodDuration: number = 0;
	firstCancellationPeriodEndsAt: string = String('');
	id: string = String('');
	interval: KlineIntervalEnum = KlineIntervalEnum.OneMinute;
	lastModifiedAt: string = String('');
	participants: Array<BattleParticipantModel> = new Array<BattleParticipantModel>();
	predictionEndsAt: string = String('');
	predictionPeriodDuration: number = 0;
	remainingExpirationTime: number = 0;
	remainingPredictionTime: number = 0;
	remainingFirstCancellationTime: number = 0;
	remainingSecondCancellationTime: number = 0;
	secondCancellationPeriodDuration: number = 0;
	secondCancellationPeriodEndsAt: string = String('');
	startPrice: number = 0;
	startedAt: string = String('');
	status: GameStatusEnum = GameStatusEnum.Running;
	ticker: TickerModel = new TickerModel();
	tickerId: string = String('');
	type: GameTypeEnum = GameTypeEnum.Duel;
}

export class TournamentSignalDto {
	tournament: TournamentModel = new TournamentModel();
	battle: BattleStartedBattleDto = new BattleStartedBattleDto();
}

export class TournamentSubscriptionsUpdatedModel {
	id: string = String('');
	subscriptions: number = 0;
}

export class TournamentSubscriberModel {
	userId: string = String('');
	user: UserInfoModel = new UserInfoModel();
	notified: boolean = false;
	result: TournamentResultEnum = TournamentResultEnum.Waiting;
	latestParticipatedIteration: number = 0;
	created: string = String('');
	createdBy: number | null = null;
	lastModified: string = String('');
	lastModifiedBy: number | null = null;
	id: string = String('');
}
