import {
	GameTypeEnum,
	DepositTypeEnum,
	KlineIntervalEnum,
	SelectedChoiceEnum,
	BattleResultEnum,
	GameStatusEnum,
	TickerSymbolEnum,
	TickerSymbolNameEnum,
	TickerTypeEnum,
	BattleTypeEnum,
} from '../enums/game.enum';
import { GameDepositRange } from './range-value.model';
import { TournamentModel } from './tournament.model';

export class LobbiesStartSearchingDto {
	battleType: GameTypeEnum = GameTypeEnum.Duel;
	createdAt: string = String('');
	depositAmoutRange: GameDepositRange = new GameDepositRange();
	depositType: DepositTypeEnum = DepositTypeEnum.USDT;
	expiredAt: string = String('');
	id: string = String('');
	isActive: boolean = false;
	remainingExpirationTime: number = 0;
	userId: string = String('');
}

export class FriendBattleInvitationWasAcceptedDto {
	invitation: BattleInvitationReceivedDto = new BattleInvitationReceivedDto();
	lobby: LobbyWasCreatedDto = new LobbyWasCreatedDto();
}

export class LobbyWasCreatedDto {
	battleRewardCommissionPercentage: number = 0;
	battleType: GameTypeEnum = GameTypeEnum.Duel;
	createdAt: string = String('');
	depositAmount: number = 0;
	depositType: DepositTypeEnum = DepositTypeEnum.USDT;
	expiredAt: string = String('');
	id: string = String('');
	isActive: boolean = false;
	opponents: Array<LobbiesStartSearchingDto> = new Array<LobbiesStartSearchingDto>();
	readiness: Array<boolean> = new Array<boolean>(false);
	remainingExpirationTime: number = 0;
}

export class BattleInvitationReceivedResponseDto {
	invitation: BattleInvitationReceivedDto = new BattleInvitationReceivedDto();
	sender: BattleParticipantUserModel = new BattleParticipantUserModel();
}

export class BattleInvitationReceivedDto {
	battleType: GameTypeEnum = GameTypeEnum.Duel;
	createdAt: string = String('');
	depositAmoutRange: GameDepositRange = new GameDepositRange();
	depositType: DepositTypeEnum = DepositTypeEnum.USDT;
	expiredAt: string = String('');
	friendUserId: string = String('');
	id: string = String('');
	isAccepted: boolean = false;
	isActive: boolean = false;
	remainingExpirationTime: number = 0;
	userId: string = String('');
	sender: BattleParticipantUserModel = new BattleParticipantUserModel();
}

export class BattleStartedResponseDto {
	lobby: LobbyWasCreatedDto = new LobbyWasCreatedDto();
	battle: BattleStartedBattleDto = new BattleStartedBattleDto();
}

export class BattleStartedBattleDto {
	battleDuration: number = 0;
	battleEndsAt: string = String('');
	createdAt: string = String('');
	depositAmount: number = 0;
	depositType: DepositTypeEnum = DepositTypeEnum.USDT;
	endPrice: number = 0;
	firstCancellationPeriodDuration: number = 0;
	firstCancellationPeriodEndsAt: string = String('');
	id: string = String('');
	interval: KlineIntervalEnum = KlineIntervalEnum.OneMinute;
	lastModifiedAt: string = String('');
	participants: Array<BattleParticipantModel> = new Array<BattleParticipantModel>();
	predictionEndsAt: string = String('');
	predictionPeriodDuration: number = 0;
	remainingExpirationTime: number = 0;
	remainingPredictionTime: number = 0;
	remainingFirstCancellationTime: number = 0;
	remainingSecondCancellationTime: number = 0;
	secondCancellationPeriodDuration: number = 0;
	secondCancellationPeriodEndsAt: string = String('');
	startPrice: number = 0;
	startedAt: string = String('');
	status: GameStatusEnum = GameStatusEnum.Running;
	ticker: TickerModel = new TickerModel();
	tickerId: string = String('');
	tournament: TournamentModel | null = null;
	tournamentId: string | null = null;
	type: BattleTypeEnum = BattleTypeEnum.Duel;
}

export interface ILobbyWasCreatedDto {
	battleType: GameTypeEnum;
	createdAt: Date;
	depositAmount: number;
	depositType: DepositTypeEnum;
	expiredAt: Date;
	id: string;
	isActive: boolean;
	opponents: '';
}

export interface ILobbiesStartSearchingRequest {
	minDepositAmount: number;
	maxDepositAmount: number;
	depositType: DepositTypeEnum;
}

export class SetChoiceOptionModel {
	option: SelectedChoiceEnum = SelectedChoiceEnum.None;
}

export class BattleParticipantModel {
	battleId: string = String('');
	userId: string = String('');
	user: BattleParticipantUserModel = new BattleParticipantUserModel();
	battleResult: BattleResultEnum = BattleResultEnum.None;
	selectedChoice: SelectedChoiceEnum = SelectedChoiceEnum.None;
	id: string = String('');
}

export class TickerModel {
	symbol: string = String('');
	name: string = String('');
	description: string = String('');
	tickerType: TickerTypeEnum = TickerTypeEnum.Crypto;
	imageUrl: string = String('');
	id: string = String('');
}

export class BattleParticipantUserModel {
	userName: string = String('');
	avatarUrl: string = String('');
	id: string = String('');
}

export class BattlePredictionChoiceWasChangedModel {
	userId: string = String('');
	battleId: string = String('');
	selectedChoice: SelectedChoiceEnum = SelectedChoiceEnum.None;
}
