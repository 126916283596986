import { BehaviorSubject, catchError, concatMap, delay, Observable, of, retryWhen, Subscription, take, throwError } from 'rxjs';
import * as SignalR from '@microsoft/signalr';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '../../api/auth/auth.service';
import { environment } from 'src/environments/environment';
import { IChatGroupItem, IFriendData } from 'src/app/core/models/chat.model';
import { ChatService } from '../../api/chat-service/chat.service';
import { Platform } from '@ionic/angular';
import { ToastService } from '../../app/toast-service/toast.service';
import { LayoutService } from '../../app/layout-service/layout.service';
import { VisibilityService } from '../../app/visibility-service/visibility.service';
import { logError } from 'src/app/core/utils/functions.utils';
import { FullScreenLoadingTypeEnum } from 'src/app/core/enums/app-full-screen-loading.enum';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class ChatSignalService implements OnDestroy {
	public myId: string = '';
	protected hubConnection: SignalR.HubConnection | null = null;
	private readonly maxRetries = 5;
	private reconnectDelayFactor = 1000; // Initial retry delay (1 second)
	private reconnectDelay = 1000; // 1 second delay
	private readonly signalUrl: string = environment.CHAT_HUB;
	private visibilitySubscription: Subscription;
	private connectionStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public typing: EventEmitter<any> = new EventEmitter<any>();
	public receivedMessage: EventEmitter<any> = new EventEmitter<any>();
	public friends: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
	public connectionStatus: EventEmitter<string> = new EventEmitter<string>();
	public seenMessages: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
	public friendIsOnline: EventEmitter<IFriendIsOnline> = new EventEmitter<IFriendIsOnline>();
	public friendIsOffline: EventEmitter<IFriendIsOnline> = new EventEmitter<IFriendIsOnline>();

	public unreadMessages: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	get connected(): boolean {
		return this.hubConnection?.state === SignalR.HubConnectionState.Connected;
	}

	public constructor(
		private _router: Router,
		private _platform: Platform,
		private _authService: AuthService,
		private _chatService: ChatService,
		private _toastService: ToastService,
		protected _layoutService: LayoutService,
		private _visibilityService: VisibilityService,
	) {
		this._authService.userData$.subscribe((userData) => {
			this.myId = userData.sub;
		});

		this.receivedMessage.subscribe((value) => {
			this.getMessagesToCheckUnreads();
		});

		// Visibility Handler
		this.visibilitySubscription = this._visibilityService.visibility$.subscribe((isVisible) => {
			if (isVisible) {
				try {
					const connection = this.getHubConnection();
					console.log('🟥🟥 connection.state', connection.state);
					if (
						connection.state !== SignalR.HubConnectionState.Connected &&
						connection.state !== SignalR.HubConnectionState.Connecting
					) {
						// Rebuild and start connection if not already connected
						this.rebuildAndStartConnection().catch((err) =>
							logError(err, 'Error rebuilding connection on visibility change'),
						);
					}
				} catch (err) {
					logError(err, 'Error checking connection state');
				}
			}
		});

		// #region Platform Pause and Resume Handlers
		this._platform.pause.subscribe(() => {
			// Pause or disconnect SignalR when app is minimized
			this._toastService.info('Platform Paused!', '');
		});

		this._platform.resume.subscribe(async () => {
			// Reconnect when the app is resumed
			this._toastService.info('Platform Resumed!', '');
		});
		// #endregion
	}

	ngOnDestroy(): void {
		if (this.hubConnection) {
			this.killConnection();
		}
		this.visibilitySubscription.unsubscribe();
	}

	private getHubConnection(): SignalR.HubConnection {
		if (!this.hubConnection) {
			throw new Error('Chat SignalR hub connection is not initialized.');
		}
		return this.hubConnection;
	}

	// Build the SignalR connection
	private async buildConnection(): Promise<void> {
		const accessToken = await this._authService.getAccessToken();

		this._layoutService.signalLoading.next({
			isShow: true,
			title: 'Building the Application Service...',
			type: FullScreenLoadingTypeEnum.Signal,
			isShowTryAgainButton: false,
		});

		if (!accessToken) {
			console.warn('🔴 No access token available. Retrying connection in 1 second.');
			setTimeout(() => this.rebuildAndStartConnection(), 1000);
			return;
		}

		if (this.hubConnection) {
			this.killConnection();
		}

		this.hubConnection = new SignalR.HubConnectionBuilder()
			.withUrl(this.signalUrl, {
				accessTokenFactory: () => accessToken,
				logger: SignalR.LogLevel.Debug,
				skipNegotiation: true,
				transport: SignalR.HttpTransportType.WebSockets,
			})
			.build();

		this.hubConnection.onclose(() => {
			console.warn('✖ Chat Signal Closed.');
			this.updateConnectionStatus(false);
			// this.reconnectWithRetries();
		});

		this.fireChatEvents();
		this.getMessagesToCheckUnreads();

		console.log('SignalR connection built');
	}

	// Start the SignalR connection
	private async startConnection(): Promise<void> {
		try {
			const connection = this.getHubConnection();
			await connection.start();
			if (connection.state === SignalR.HubConnectionState.Connected) {
				console.log('✔ Connected to Chat SignalR.');
				this.updateConnectionStatus(true);
			}
		} catch (err) {
			logError(err, 'Error starting SignalR connection');
			this.updateConnectionStatus(false);
			// this.reconnectWithRetries();
		}
	}

	/**
	 * rebuildAndStartConnection
	 */
	public async rebuildAndStartConnection(): Promise<void> {
		try {
			await this.buildConnection();
			await this.startConnection();
		} catch (err) {
			logError(err, 'Rebuild and start connection failed');
		}
	}

	// Retry the connection with exponential backoff
	// private retryConnection(): void {
	// 	let retries = 0;

	// 	this.updateConnectionStatus(false);

	// 	const retryObservable = new Observable<void>((observer) => {
	// 		const intervalId = setInterval(() => {
	// 			if (retries < this.maxRetries) {
	// 				retries++;
	// 				this.retryLogic(observer, retries, intervalId);
	// 			} else {
	// 				clearInterval(intervalId);
	// 				observer.error('Max retries reached. Could not reconnect to SignalR.');
	// 				this.updateConnectionStatus(false);
	// 			}
	// 		}, this.reconnectDelay);
	// 	});

	// 	retryObservable
	// 		.pipe(
	// 			retryWhen((errors) =>
	// 				errors.pipe(
	// 					take(this.maxRetries), // Limit the number of retries
	// 					concatMap((error, count) => {
	// 						// Use concatMap to create a delay between retries
	// 						const delayTime = Math.pow(2, count) * 1000; // Exponential backoff
	// 						console.log(`Retrying SignalR connection in ${delayTime}ms...`);
	// 						return of(error).pipe(delay(delayTime)); // Apply the delay
	// 					}),
	// 				),
	// 			),
	// 			catchError((err) => {
	// 				logError(err, 'SignalR reconnection failed');
	// 				this.updateConnectionStatus(false);
	// 				return throwError(() => new Error('SignalR reconnection failed')); // Use the new error throwing pattern
	// 			}),
	// 		)
	// 		.subscribe();
	// }

	// Retry logic helper
	// private retryLogic(observer: any, retries: number, intervalId: ReturnType<typeof setInterval>): void {
	// 	try {
	// 		const connection = this.getHubConnection();
	// 		connection
	// 			.start()
	// 			.then(() => {
	// 				clearInterval(intervalId); // Clear the interval if connection is successful
	// 				this.updateConnectionStatus(true);
	// 				observer.next(); // Signal success
	// 				observer.complete(); // Complete the observable
	// 			})
	// 			.catch(() => {
	// 				this.updateConnectionStatus(false);
	// 			});
	// 	} catch (err) {
	// 		console.error(`SignalR retry ${retries} failed, retrying...`);
	// 		this.updateConnectionStatus(false);
	// 	}
	// }

	// Kill the existing connection
	private killConnection(): void {
		if (this.hubConnection) {
			this.hubConnection.stop().catch((err) => {
				logError(err, 'Error stopping SignalR connection');
			});
			this.hubConnection = null;
		}
	}

	// Update connection status based on the current state
	private updateConnectionStatus(isConnected: boolean): void {
		this.connectionStatusSubject.next(isConnected);
	}

	// Method to get the current connection status
	public getConnectionStatus(): Observable<boolean> {
		return this.connectionStatusSubject.asObservable();
	}

	public getMessagesToCheckUnreads(): void {
		this._chatService.getChatItems().subscribe({
			next: (response: any) => {
				let unreads: number = 0;
				response.value.forEach((chat: IChatGroupItem) => {
					unreads = unreads + chat.unseenMessagesCount;
				});
				this.unreadMessages.next(unreads);
			},
		});
	}

	//#region Invoke Events
	public sendMessage(message: string, targetUserId: string): void {
		this.hubConnection?.invoke('sendMessage', message, targetUserId);
	}

	public sendIsTyping(groupId: string): void {
		this.hubConnection?.invoke('isTyping', groupId);
	}

	public markAsSeen(groupId: string): void {
		this.hubConnection?.invoke('markAsSeen', groupId);
	}
	//#endregion

	//#region On Events
	public receiveMessage(): void {
		this.hubConnection?.on('ReceiveMessage', (result: any) => {
			this.receivedMessage.emit(result);
			this.showToast(result);
		});
	}

	public getSeenMessages(): void {
		this.hubConnection?.on('SeenMessages', (messagesIds: Array<any>) => {
			this.seenMessages.emit(messagesIds);
		});
	}

	public friendOnline(): void {
		this.hubConnection?.on('FriendIsOnline', (user: any) => {
			this.friendIsOnline.emit(user);
		});
	}

	public friendOffline(): void {
		this.hubConnection?.on('FriendIsOffline', (user: any) => {
			this.friendIsOffline.emit(user);
		});
	}

	public getFriendsList(): void {
		this.hubConnection?.on('GetFriendsList', (friends: any) => {
			this.friends.emit(friends);
		});
	}

	public isTyping(): void {
		this.hubConnection?.on('IsTyping', (response: any) => {
			this.typing.emit(response);
		});
	}
	//#endregion

	public fireChatEvents(): void {
		this.receiveMessage();
		this.getSeenMessages();
		this.friendOnline();
		this.friendOffline();
		this.getFriendsList();
		this.isTyping();
	}

	private showToast(message: any): void {
		if (this._router.url.includes('chat')) {
			return;
		} else {
			this._toastService.success('<b>' + message.author.userInfo.userName + '</b> sent you a message', 'chatbox-ellipses', 3000, {
				buttonText: 'OPEN',
				handler: (router: Router) => {
					router.navigate(['/chat/dialog/' + message.groupId], {
						state: {
							data: {
								avatarUrl: message.author.userInfo.avatarUrl,
								userId: message.author.userInfo.id,
								userName: message.author.userInfo.userName,
							} as IFriendData,
						},
					});
				},
			});
		}
	}
}

export interface IFriendIsOnline {
	avatarUrl: string;
	id: string;
	isOnline: boolean;
	userName: string;
}
export interface IUnreadMessage {
	count: number;
	display: string;
}
