import { state } from '@angular/animations';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAppHeader } from 'src/app/core/models/app-header.model';
import { TraderBattleGameStateType } from '../types/trader-battle-game-state.type';
import { TraderBattleGameStateEnum } from '../enums/trader-battle-game-state.enum';
import { LobbyWasCreatedDto } from 'src/app/core/models/engine-signal.model';
import { SelectedChoiceEnum } from 'src/app/core/enums/game.enum';

@Injectable({
	providedIn: 'root',
})
export class TraderBattleGameService {
	public currentState: BehaviorSubject<TraderBattleGameStateType> = new BehaviorSubject<TraderBattleGameStateType>(TraderBattleGameStateEnum.lobbyMaking);
	// public createdLobby: BehaviorSubject<LobbyWasCreatedDto> = new BehaviorSubject<LobbyWasCreatedDto>(new LobbyWasCreatedDto());
	public selectedChoice: BehaviorSubject<SelectedChoiceEnum> = new BehaviorSubject<SelectedChoiceEnum>(SelectedChoiceEnum.None);
	public isCalculatingResult: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public iAmReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public showMinimizeGame: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	constructor() {}
}
