import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-neon-button',
	standalone: true,
	templateUrl: './neon-button.component.html',
	styleUrls: ['./neon-button.component.scss'],
	imports: [IonicModule, CommonModule],
})
export class NeonButtonComponent implements OnInit {
	@Input() text: string = 'Button';
	@Input() size: 'small' | 'default' | 'large' = 'default';
	@Input() full: boolean = false;
	@Input() iconName: string | undefined;
	@Input() loading: boolean = false;
	@Input() disabled: boolean = false;
	@Input() isActive: boolean = false;
	@Input() iconPosition: 'start' | 'end' = 'start';
	@Input() borderRadius: string = '100px';
	@Input() bottomShadow: boolean = false; // New input for bottom shadow
	@Input() animateOnClick: boolean = false; // New input for click animation
	@Input() boxShadowBlur: string = '1em'; // Default blur value
	@Input() boxShadowSpread: string = '0.5em'; // Default spread value
	@Input() shineAnimation: boolean = false;
	@Input() boxShadowAnimation: boolean = false;
	@Input() iconOnly: boolean = false;

	// FIXME: type 👇
	@Input() color: 'primary' | 'secondary' | 'success' | 'danger' | 'info' | 'warning' = 'primary';

	@ViewChild('neonButton') neonButton!: ElementRef;

	public constructor() {}

	public ngOnInit(): void {}

	handleClick(event: MouseEvent) {
		if (this.animateOnClick) {
			this.neonButton.nativeElement.classList.add('animate-on-click');
			setTimeout(() => {
				this.neonButton.nativeElement.classList.remove('animate-on-click');
			}, 500);
		}
	}

	public getColor(): string {
		switch (this.color) {
			case 'primary':
				return 'var(--app-color-primary)';
			case 'secondary':
				return 'var(--app-color-secondary)';
			case 'success':
				return 'var(--app-color-success)';
			case 'danger':
				return 'var(--app-color-danger)';
			case 'warning':
				return 'var(--app-color-warning)';
			case 'info':
				return 'var(--app-color-info)';
			default:
				return 'var(--app-color-primary)'; // Default to primary color
		}
	}
}
