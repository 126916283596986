export class IProfile {
	id: string = String('');
	userName: string = String('');
	avatarUrl: string = String('');
	isFriend: boolean = false;
	isOnline: boolean = false;
	profile: IProfileData = new IProfileData();
}
export class IProfileData {
	id: string = String('');
	userId: string = String('');
	gameXP: number = 0;
	userLevel: number = 0;
	levelXpRange: IProfileLevelXpRange = new IProfileLevelXpRange();
	winrate: number = 0;
	currentWinStreak: number = 0;
	longestWinStreak: number = 0;
	
	friendsCount: number = 0;
	coinBalance: number = 0;
	usdtBalance: number = 0;
	depositLevelId: string = String('');
	depositLevel: IProfileDataDepositLevel = new IProfileDataDepositLevel();
	rankTitleId: string = String('');
	rankTitle: IProfileDataRankTitle = new IProfileDataRankTitle();
	rankNumber: number = 0;
}
export class IProfileLevelXpRange {
	min: number = 0;
	max: number = 0;
}
export class IProfileDataDepositLevel {
	id: string = String('');
	imageUrl: string = String('');
	keyword: string = String('');
	order: number = 0;
	title: string = String('');
}
export class IProfileDataRankTitle {
	id: string = String('');
	imageUrl: string = String('');
	keyword: string = String('');
	order: number = 0;
	title: string = String('');
}

export interface IReferral {
	avatarUrl: string;
	id: string;
	isFriend: boolean;
	isOnline: boolean;
	userName: string;
}
