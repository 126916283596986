export const AppConfig = {
	title: 'Trading Battle',
	introSeen: 'introSeen',
	duelTermsAndConditionsAccepted: 'duelTermsAndConditionsAccepted',
	disableReceiveInvitation: 'disableReceiveInvitation',
	currentBattleId: 'currentBattleId',

	invitations: 'invitations',
	lobbies: 'lobbies',
	lobbyRequests: 'lobbyRequests',
	inPredictionTimeBattle: 'inPredictionTimeBattle',
	runningBattles: 'runningBattles',

	// Tournaments
	upcomingTournaments: 'upcomingTournaments',
	mineTournaments: 'mineTournaments',
	allTournaments: 'allTournaments',
	currentTournament: 'currentTournament',
	currentTournamentHistoryItem: 'currentTournamentHistoryItem',
};
